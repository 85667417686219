import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { MentionService } from '../../service/MentionService';
import { DomaineService } from '../../service/DomaineService';
import { Dropdown } from 'primereact/dropdown';

export class Mention extends Component {
    constructor(props){
        super(props);

        //Model de l'objet Mention
        let emptyMention = {
            idMention: null,
            domaine: null,
            libelleMention: ''
        }

        this.notify = new Notify();
        this.spinner = new Spinner()

        this.ModelMention = new MentionService();
        this.ModelDomaine = new DomaineService();

        this.state = {
            curentMention: emptyMention,
            domaineList: [],
            mentionList: [],
            redirect : false,
            loading : false,
            globalFilter: null,
            viewCreateMention: false,
            allowSave: false,
            viewDeleteMentionDialog: false,
            deletionId: null
        }
    }

    componentDidMount(){
        //Temoin de chargement
        this.setState({loading : true})
        this.ModelMention.getAll().then(data => {
            this.setState({
                mentionList: data
            })
            this.setState({loading : false})
            }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({loading : false})
        })

        //On recupère la liste des domaine
        this.ModelDomaine.getAll().then(data => {
            this.setState({
                domaineList: data
            })
            }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
        })
        
    }


    
    //Gestion de l'ouverture du formulaire
    openNew = () => {
        this.setState(
            {
                viewCreateMention: true
            }
        )
    }

    hideDialog = () => {

        //Si on decide de fermer le formulaire, on doit s'assurer le reinitialiser le mention
        let emptyMention = {
            idMention: null,
            idDomaine: null,
            libelleMention: ''
        }

        this.setState({
            curentMention: emptyMention,
            viewCreateMention: false
        })
    }

    onIdDomaineChange = (event) => {
        let curentMention = {
            idMention: this.state.curentMention.idMention,
            domaine: event.target.value,
            libelleMention: this.state.curentMention.libelleMention
        }

        let allowSave = (curentMention.domaine!==undefined && (curentMention.libelleMention.trim()).length>5);
        this.setState({
            curentMention: curentMention,
            allowSave: allowSave
        })
 
    }

    onLibelleMentionChange = (event) => {
        let curentMention = {
            idMention: this.state.curentMention.idMention,
            domaine: this.state.curentMention.domaine,
            libelleMention: event.target.value
        }

        let allowSave = ((curentMention.domaine!==undefined && curentMention.domaine!==null) && (curentMention.libelleMention.trim()).length>5);
        this.setState({
            curentMention: curentMention,
            allowSave: allowSave
        })
        
    }

    saveMention = () => {
        
        let newMention = {
            idMention: parseInt(this.state.curentMention.idMention),
            idDomaine: parseInt(this.state.curentMention.domaine.idDomaine),
            libelleMention: this.state.curentMention.libelleMention
        }

        //On peut proceder à la mise à jour ou à l'ajout de la spécialité
        if(newMention.idMention){
            //console.log(newMention)

            this.ModelMention.update(newMention.idMention, newMention).then(data => {
                this.notify.success()
                console.log(data);
                this.setState({
                    viewCreateMention: false,
                    redirect: true
                })
                this.ModelMention.getAll().then(data => {
                    this.setState({
                        mentionList: data,
                        loading : false
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({loading : false})
                })
                
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })

        }else{

            this.ModelMention.create(newMention).then(data => {
                this.notify.success()
                console.log(data)
                this.setState({ 
                    redirect: true,
                    viewCreateMention: false,
                })
                this.ModelMention.getAll().then(data => {
                    this.setState({
                        mentionList: data,
                        loading : false
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({loading : false})
                })
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })
        }

    }

    editMention = (e) => {
        let allowSave = ((e.rowData.domaine!==null && e.rowData.domaine!==undefined) && (e.rowData.libelleMention.trim()).length>5);
        //console.log(e.rowData.domaine!==null);
        console.log("Ligne :",e);
        this.setState({
            curentMention: e.rowData,
            viewCreateMention: true,
            allowSave: allowSave
        })
    }

    confirmDeleteMention = (e) => {
        this.setState(
            {
                deletionId: e.rowData.idMention,
                viewDeleteMentionDialog: true
            }
        )

    }

    deleteMention = (idMention) => {

        //console.log(idMention)
        this.ModelMention.delete(parseInt(idMention)).then(data =>{
            this.notify.success()
            //console.log(data)
            this.setState({
                viewDeleteMentionDialog: false,
                redirect: true,
                deletionId: null
            })
            this.ModelMention.getAll().then(data => {
                this.setState({
                    mentionList: data,
                    loading : false
                })
            })
            .catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({loading : false})
            })
        }).catch(error=>{
            this.notify.echec(error.response.data.message)
            console.log(error)
        })
    }

    hideDeleteMentionDialog = () => {
        this.setState(
            {
                viewDeleteMentionDialog: false,
                deletionId: null
            }
        )
    }

    actionBodyTemplate = (event, rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editMention(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteMention(rowData)} />
            </React.Fragment>
        );
    }

    
    render() {

        //console.log(this.state)
        if(this.state.redirect) {
            //window.location.reload();
        }

        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

       const deleteDomaineDialogFooter = (
        <React.Fragment>
            <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteMentionDialog} />
            <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteMention(this.state.deletionId) } />
        </React.Fragment>
        )

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        let allowSave = (this.state.allowSave)? 
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveMention} />):
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled />);
        const niveauDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                {allowSave}
            </React.Fragment>
        );
   
        return (
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                            :
                            <DataTable  ref={(el) => this.dt = el} value={this.state.mentionList} selectionMode="single" header={`Liste des mentions (${this.state.mentionList.length})`} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="libelleMention" header="Libellé mention"  sortable={true} filter={true} filterPlaceholder="rechercher mention"/>
                                <Column field="libelleDomaine" header="Domaine REESAO"  sortable={true} filter={true} filterPlaceholder="rechercher domaine"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                            }
                        </div>
                    </div>

                <Dialog visible={this.state.viewCreateMention} style={{ width: '450px' }} header="Informations de la mention" modal className="p-fluid" footer={niveauDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleDomaine">Domaine REESAO</label>
                        <Dropdown optionLabel="libelleDomaine" optionValue="idDomaine" value={this.state.curentMention.domaine} options={this.state.domaineList} onChange={(e) => {this.onIdDomaineChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un domaine"/>
                        {this.state.submitted && !this.state.curentMention.domaine && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleMention">Mention</label>
                        <InputText id="libelleMention" value={this.state.curentMention.libelleMention}  onChange={(e) => this.onLibelleMentionChange(e)} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.curentMention.libelleMention && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.viewDeleteMentionDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteDomaineDialogFooter} onHide={this.hideDeleteMentionDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.deletionId && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )

}

}

export default Mention