import axios from 'axios';
import { AdresseIP } from '../components/AdresseIP';


export class UserService {

    constructor(){
        this.adresse = new AdresseIP()
    }
   getUser(ine) {
       
       const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-auth/register?codeIne="+ine
       return axios.get(url).then(res => res.data)
   }
   creerCompte(newUser) {
    const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-auth/register"
    return axios.post(url, newUser).then(res => res.data)
   }

   creeCompteClient(newClient) {
    const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-auth/register-account"
    return axios.post(url, newClient).then(res => res.data)
   }

   getUserEns(matricule) {
       
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-auth/stagiaire-ens?matricule="+matricule
    return axios.get(url).then(res => res.data)
    }
    
    creerCompteEns(newUser) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-auth/register-ens"
        return axios.post(url, newUser).then(res => res.data)
    }

}