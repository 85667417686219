import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InfrastructureServices } from '../../service/InfrastructureServices';
import { EquipementServices } from '../../service/EquipementServices';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { PickList } from 'primereact/picklist';
import TypeInfrastructureService from '../../service/TypeInfrastructureService';
import { DepartementService } from '../../../lmd/service/DepartementService';
import TypeStructureService from '../../service/TypeStructureService';
import { useForm,Controller } from 'react-hook-form';
import ProgrammationService from '../../service/ProgrammationService';
import { CohorteServices } from '../../service/CohorteServices';
import XLSX from 'xlsx'
import { ExportSheet } from 'react-xlsx-sheet'
import {jsPDF} from 'jspdf'


export default function Infrastructures(){
    
    const tokenDecoded = localStorage.getItem('tokenDecoded');
    const token = JSON.parse(tokenDecoded);

    let campusDepartement = localStorage.getItem('campusDepartement');
    let campusDdip = localStorage.getItem('campusDdip');
    let campusVpEip = localStorage.getItem('campusVpEip');
    const isDepartement = parseInt(JSON.parse(campusDepartement));
    const isDdip = parseInt(JSON.parse(campusDdip));
    const isVpEip = parseInt(JSON.parse(campusVpEip));


    let emptyEntite = {
        idInfrastructure: "",
        departement: "",
        libelleInfrastructure: "",
        sigleInfrastructure: "",
        nombrePlace: "",
        etatInfrastructure: "",
        typeInfrastructure: "",
        localite: "",
        latitude: "",
        longitude: "",
        utiliteInfrastructure:"",
        structure:'',
        typeStructure:''
    };

    let defaultValues = {
        idInfrastructure: "",
        libelleInfrastructure: "",
        sigleInfrastructure: "",
        nombrePlace: "",
        etatInfrastructure: "",
        typeInfrastructure: "",
        localite: "",
        latitude: "",
        longitude: "",
        utiliteInfrastructure:"",
        structure:'',
        typeStructure:'',
        infrastructureMere:'',
        idTypeStructure:''
    };

    let cols = [
        { field: 'libelleInfrastructure', header: 'Infrastructure' },
        { field: 'sigleInfrastructure', header: 'sigle' },
        { field: 'nombrePlace', header: 'Capacité' },
        { field: 'libelleEtatInfrastructure', header: 'Etat' },
        { field: 'libelleTypeInfrastructure', header: 'Type' },
        { field: 'localite', header: 'Localité' },
        { field: 'libelleTypeStructureRattachement', header: 'Structure' },
         
    ];
    let exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

    // const [infrastructures, setInfrastructure] = useState(null);
    //const [listOfInfrastructures, setListOfInfrastructures] = useState([]);
    const [idUniversite, setIdUniversite] = useState(null)
    const [idIesr, setIdIesr] = useState(null)
    const [listeUtilites, setListeUtilites] = useState([]);
    const [listeUtilitesInfrastructure, setListeUtilitesInfrastructure] = useState([]);
    const [listeUtilitesChoix, setListeUtilitesChoix] = useState([]);
    const [utilitesDisponible, setUtilitesDisponible] = useState(null);
    const [listeEquipements, setListeEquipements] = useState([]);
    const [listeEquipementsInfrastructure, setListeEquipementsInfrastructure] = useState([]);
    const [listeEquipementsChoix, setListeEquipementsChoix] = useState([]);
    const [equipementsDisponible, setEquipementsDisponible] = useState([]);
    const [etatInfrastructures, setEtatInfrastructures] = useState([]);
    const [typesInfrastructures, setTypesInfrastructures] = useState([]);
    const [detailsDialog, setDetailsDialog] = useState(false);
    const [utiliteDialog, setUtiliteDialog] = useState(false);
    const [equipementDialog, setEquipementDialog] = useState(false);
    const [departementUniversite, setDepartementUniversite] = useState(null);
    const [departementsUniversite, setDepartementsUniversite] = useState([]);
    const [entiteDialog, setEntiteDialog] = useState(false);
    const [deleteEntiteDialog, setDeleteEntiteDialog] = useState(false);
    const [infrastructure, setInfrastructure] = useState(emptyEntite);
    const [listInfrastructure,setListInfrastructure] = useState([])
    const [selectedEntites, setSelectedEntites] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingUtilite, setLoadingUtilite] = useState(false);
    const [loadingEquipement, setLoadingEquipement] = useState(false);
    const [isAvailable, setIsAvailable] = useState(false)
    const [spin,setSpin] = useState(false);
    const [listTypeStructure,setlistTypeStructure] = useState([]);
    const [listStructureAppartenance,setListStructureAppartenance] = useState([]);
    const {control,setValue,getValues,formState:{errors},handleSubmit,reset} = useForm({defaultValues})


    const dt = useRef(null);
    const utiliteInfrastructureService = new InfrastructureServices();
    const equipementServices = new EquipementServices();
    const notify = new Notify();
    const spinner = new Spinner();
    const typeInfrastructureService = new TypeInfrastructureService();
    const typeStructureService = new TypeStructureService();
    const departementService = new DepartementService();
    const infrastructureService = new InfrastructureServices();
    const programmationService = new ProgrammationService();
    const cohorteService = new CohorteServices()
    

    useEffect(() => {
            
            /*
            setLoading(true)
            utiliteInfrastructureService.getListeInfrastructures().then(data => {
                setListInfrastructure(data);
                //console.log(data);
            })
            .catch(error => {
                console.log(error);
                notify.echec(error.response.data.message);
            })
            .finally(()=>{
                setLoading(false);
            })*/
            
            setLoading(true)
            if(isDepartement){

                utiliteInfrastructureService.getListeDepartementUniv(token.code_departement).then(data => {

            
                //console.log(data)

                
                const dept = data.departementDtos[0]
                const idUniv = dept.idIes
                //console.log(idUniv)



                setIdIesr(idUniv)


                let idTypeStructure = 2
                utiliteInfrastructureService.getListeInfrastructuresByTypeStructureIdStructure(idTypeStructure, idUniv).then(data => {
                    setListInfrastructure(data);
                    console.log(data);
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
                .finally(()=>{
                    setLoading(false);
                })



                setIdUniversite(idUniv)
                setDepartementsUniversite(data.departementDtos)
                setDepartementUniversite(dept)
                /*utiliteInfrastructureService.getListeDepartementUniversite(idUniv).then(data => {
                    setDepartementsUniversite(data)
                }).catch(error => {
                    console.log(error);
                })*/
            })
            .catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false);
            })
        }
        else if(isDdip || isVpEip){
            let idTypeStructure = 2
                utiliteInfrastructureService.getListeInfrastructuresByTypeStructureIdStructure(idTypeStructure, token.code_iesr).then(data => {
                    setListInfrastructure(data);
                    console.log(data);
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
                .finally(()=>{
                    setLoading(false);
                })
        }

            //A revoir apres pour recuperer la bonne liste d
            /*let idIesr=undefined;
            if(token.hasOwnProperty("code_iesr")){
                idIesr = token.code_iesr
            }else{
                idIesr = 52
            }*/

            
           

            utiliteInfrastructureService.getListeEtatInfrastructure().then(data => {
                setEtatInfrastructures(data)
            }).catch(error => {
                console.log(error);
            })

            typeInfrastructureService.getAll().then(data => {
                setTypesInfrastructures(data)
            }).catch(error => {
                console.log(error);
            })

            //Liste des utilités possible pour une infrastructure
            utiliteInfrastructureService.getListeUtiliteInfrastructure().then(data => {
                setListeUtilites(data);
            }).catch(error => {
                console.log(error);
            })

            //La liste des équipements possibles pour une infrastructure
            equipementServices.getListeEquipements().then(data => {
                setListeEquipements(data)
            }).catch(error => {
                console.log(error);
            });
            typeStructureService.getAll().then(response=>setlistTypeStructure(response));

        }, []
    );

    const exportExcel =()=> {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listInfrastructure);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, "Liste des infrastructures");
        });
    }
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }
    const exportPdf = () => {
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default("l");
                const image = new Image();
                //let logo = departementUniversite
                //image.src = '/assets/layout/images/logos/logo_'+logo;
                //image.src = '/assets/layout/images/logos/logo_'+logo.toLowerCase()+'.png';
                doc.setFontSize(12);
                doc.text("Ministère de l'Enseignement Supérieur,", 10, 10)
                doc.text("de la Recherche et de l'Innovation", 10, 15)
                doc.text("----------------------------------", 10, 20)
                doc.text("Secrétariat Général", 10, 25)
                doc.text("----------------------------------", 10, 30)
                doc.text(departementUniversite.nomIes, 10, 35)
                doc.text("----------------------------------", 10, 40)
                doc.text(departementUniversite.nomEtablissement, 10, 45)
                doc.text("----------------------------------", 10, 50)
                doc.text(departementUniversite.lbDepartementEtab, 10, 55)
                //doc.addImage(image, 'PNG', 130, 10, 50, 56);
                
                doc.text("BURKINA - FASO", 230, 10)
                doc.setFontSize(10);
                doc.text("Unité - Progrès - Justice", 232, 16)
                doc.setFontSize(14);
                doc.text("Liste des infrastructures", 10, 80)
                //const nbr = listInfrastructure.length
                doc.autoTable(exportColumns, listInfrastructure, {
                    
                    startY: doc.autoTable({margin : {top : 82}}),
    
                    didDrawPage: function (data) {
    
                        // Header
                        doc.setFontSize(10);
                        doc.setTextColor(40);
                    
                        // Footer
                        const str = "Page " + doc.internal.getNumberOfPages();
                        doc.setFontSize(10);
                      }
                });
                paginationPage(doc)
                doc.save("Liste des infrastructures"+'.pdf');
                
            })
        })
    }
    const paginationPage = (doc) => {
        const pageCount = doc.getNumberOfPages()
        for(let i = 1; i<=pageCount; i++){
            doc.setPage(i)
            doc.text('' + String(i) + '/' + String(pageCount), 290,200,null,null,"right")
        }
    
    }

    const openGestionEquipements = () => {

        setLoadingEquipement(true)
        let idInfrastructure = infrastructure.idInfrastructure
        equipementServices.getListeEquipementDeInfrastructure(idInfrastructure).then(data => {

            setListeEquipementsChoix(data)

            //On parcours la liste pour recueillir les id equipements dans un tableau pour le filtrage des equipement deja renseignés
            let listeIdEquipementInfrastructure = data.map((equipement) => {
                return equipement.idEquipement
            })

            //On peut maintenant filtrer pour determiner les équipement a presenter en option
            let ListeEquipementsDisponible = listeEquipements.filter((equipement) => {
                return (listeIdEquipementInfrastructure.indexOf(equipement.idEquipement)===-1)
            })

            setEquipementsDisponible(ListeEquipementsDisponible)
            setEquipementDialog(true)
            setLoadingEquipement(false)

        }).catch(error => {
            console.log(error)
            setLoadingEquipement(false)
            notify.echec(error.response.data.message);
        });

    }

    const openGestionUtilites = () => {

        setLoadingUtilite(true)
        let idInfrastructure = infrastructure.idInfrastructure
        utiliteInfrastructureService.getListeUtiliteDeInfrastructure(idInfrastructure).then(data => {
            setListeUtilitesChoix(data)
            //On fait un maps pour recupérer les id utilité de l'infrastructure
            let listeIdUtiliteInfrastructure = data.map((utiliteInfrastructure) => {
                return utiliteInfrastructure.idUtiliteInfrastructure
            })
            
            //On peut maintenant extraire de la liste des utilités, les utilités qui concernent déjà l'infrastructure
            let listeUtilitesDisponible = listeUtilites.filter((utilite) => {
                return (listeIdUtiliteInfrastructure.indexOf(utilite.idUtiliteInfrastructure)===-1)
            })
            
            setUtilitesDisponible(listeUtilitesDisponible)
            setUtiliteDialog(true)
            setLoadingUtilite(false)
        })
        .catch(error => {
            console.log(error);
            setLoadingUtilite(false);
            notify.echec(error.response.data.message);
        })
    }


    const hideUtiliteDialog = () => {
        setUtiliteDialog(false)
    }

    

    const hideEquipementDialog = () => {
        setEquipementDialog(false)
    }

    const saveEquipement = () => {
        //On s'assure que au moins un equipement a été choisi
        if(listeEquipementsChoix.length>0){

            //On parcours les choix pour constituer la liste d'objet à envoyer au bac
            let newEquipementInfrastructure = listeEquipementsChoix.map((choix) => {
                return {
                    idInfrastructure: infrastructure.idInfrastructure,
                    idEquipement: choix.idEquipement
                }
            })
            
            //Enregistrement des équipements de l'infrastructure
            equipementServices.enregistrerEquipementsInfrastructure(newEquipementInfrastructure).then(data => {
                setListeEquipementsInfrastructure(listeEquipementsChoix)
                notify.success()
                setEquipementDialog(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })
        }
    }

    const saveUtilite = () => {

        //On s'assure qu'au moins une utilité a été choisie
        if(listeUtilitesChoix.length>0){

            let newUtiliteInfrastructure = listeUtilitesChoix.map((utilite) => {
                return {
                    idInfrastructure: infrastructure.idInfrastructure,
                    idUtiliteInfrastructure: utilite.idUtiliteInfrastructure
                }
            })

            //On procède à l'enregistrement des utilités
            utiliteInfrastructureService.enregistrerUtilitesInfrastructure(newUtiliteInfrastructure).then(data => {
                setListeUtilitesInfrastructure(listeUtilitesChoix)
                notify.success()
                setUtiliteDialog(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })

        }
    }

    const onUtiliteChange = (event) => {
        
        setUtilitesDisponible(event.source)
        setListeUtilitesChoix(event.target)
    }

    const onEquipementChange = (event) => {
        setEquipementsDisponible(event.source)
        setListeEquipementsChoix(event.target)
    }
   
    const hideDialog = () => {
        setSubmitted(false);
        reset();
        setEntiteDialog(false);
        setSpin(false)
        
    }
    
    const hideDeleteEntiteDialog = () => {
        setDeleteEntiteDialog(false);
    }

    const hideDetailsDialog = () => {
        setInfrastructure(emptyEntite)
        setDetailsDialog(false);
    }
    
    const itemTemplate = (item) => {
        return (
            <div className="">
                <div className="">
                    <h6 className="p-mb-2">{item.libelleUtiliteInfrastructure}</h6>
                </div>
            </div>
        );
    }

    const equipementItemTemplate = (item) => {
        return (
            <div className="">
                <div className="">
                    <h6 className="p-mb-2">{item.libelleEquipement}</h6>
                </div>
            </div>
        );
    }
    
    const editEntite = (infrastructureModification) => {

        //console.log(infrastructureModification)
        setValue("idInfrastructure",infrastructureModification.idInfrastructure);
        setValue("libelleInfrastructure",infrastructureModification.libelleInfrastructure);
        setValue("sigleInfrastructure",infrastructureModification.sigleInfrastructure);
        setValue("nombrePlace",infrastructureModification.nombrePlace);
        setValue("localite",infrastructureModification.localite);
        let etat = etatInfrastructures.find(data=>data.idEtatInfrastructure === infrastructureModification.idEtatInfrastructure);
        setValue("etatInfrastructure",etat);
        let utilite = listeUtilites.find(data=>data.idUtiliteInfrastructure === infrastructureModification.idUtiliteInfrastructure)
        setValue("utiliteInfrastructure",utilite);
        let typeInfrastructure = typesInfrastructures.find(type=>type.idTypeInfrastructure === infrastructureModification.idTypeInfrastructure)
        setValue("typeInfrastructure",typeInfrastructure);
        let infrastructureParent = listInfrastructure.find(infrast=>infrast.idInfrastructure === infrastructureModification.idInfrastructureParent)
        setValue("infrastructureMere",infrastructureParent);
        let {typeStructure} = infrastructureModification;
        setValue("typeStructure",infrastructureModification.typeStructure);

        typeStructureService.getTypeStructure(typeStructure.idTypeStructure,idUniversite)
        .then(response=>{
            setListStructureAppartenance(response);
            let structureAppartenance = response.find(struc=>struc.idTypeStructureRattachement === infrastructureModification.idTypeStructureRattachement)
            setValue("structure",structureAppartenance)
        })
        .catch(errors=>console.log(errors));
        setInfrastructure(infrastructure);
        setEntiteDialog(true);
    }

    const detailInfrastructure = (infrastructure) => {
        setInfrastructure(infrastructure)
        setDetailsDialog(true)

        setLoadingUtilite(true)

        //On recupère la liste des utilites de l'infrastructure
        utiliteInfrastructureService.getListeUtiliteDeInfrastructure(infrastructure.idInfrastructure).then(data =>{
            setListeUtilitesInfrastructure(data)
            setLoadingUtilite(false)
        }).catch(error => {
            console.log(error)
            setLoadingUtilite(false)
        })

        //On recupère la liste des équipements de l'infrastructure
        setLoadingEquipement(true)
        equipementServices.getListeEquipementDeInfrastructure(infrastructure.idInfrastructure).then(data => {
            setListeEquipementsInfrastructure(data)
            setLoadingEquipement(false)
        }).catch(error => {
            console.log(error)
            setLoadingEquipement(false)
        })
    }
    
    const confirmDeleteEntite = (Infrastructure) => {
        setInfrastructure(Infrastructure);
        setDeleteEntiteDialog(true);
    }
    
    const deleteEntite = () => {
        utiliteInfrastructureService.deleteInfrastructure(infrastructure.idInfrastructure).then(data => {
            setInfrastructure(emptyEntite);
            notify.success();
            setLoading(true)



            let idTypeStructure = 2
            utiliteInfrastructureService.getListeInfrastructuresByTypeStructureIdStructure(idTypeStructure, idUniversite).then(data => {
                setListInfrastructure(data);
            })
            .catch(error => {
                console.log(error);
                notify.echec(error.response.data.message);
            })
            .finally(()=>{
                setLoading(false);
            })

            
        })
        .catch(error => {
            notify.echec(error.response.data.message);
            setLoading(false)
        })
        setDeleteEntiteDialog(false);
    }

    const handleTypeStructure = (typeStructure)=>{
        
        setValue("idTypeStructure","");
        setValue("structure","");

        //console.log(typeStructure)
       if(typeStructure.idTypeStructure === 2){
            setIsAvailable(true)
       }
       else setIsAvailable(false)
        setListStructureAppartenance([])
        let idUniv = null
        //console.log(typeStructure)
        switch(typeStructure.idTypeStructure){
            
            case 1:
                idUniv = idUniversite
                typeStructureService.getTypeStructure(typeStructure.idTypeStructure,idUniv).then(response=>{
                    setListStructureAppartenance(response)
                })

                break
            case 2:
                break
            case 3:
                idUniv = idUniversite
                 cohorteService.getListeEtabUniv(idUniv).then(data => {
                    let structureRatachement = []
                    data.forEach(element => {
                        let etab = {
                            idTypeStructureRattachement : element.idEtablissement,
                            libelleTypeStructureRatachement : element.nom
                        }
                        structureRatachement.push(etab)
                    });
                    setListStructureAppartenance(structureRatachement)
                 })
                break
            default:
        }
    }

    const handleSave = dataForm=>{
        setSpin(true);
        let idInfrastructure = getValues("idInfrastructure");
        
        let finalDTO = {
            idInfrastructure,
            libelleInfrastructure: dataForm.libelleInfrastructure,
            sigleInfrastructure: dataForm.sigleInfrastructure,
            nombrePlace: dataForm.nombrePlace,
            idEtatInfrastructure: dataForm.etatInfrastructure.idEtatInfrastructure,
            idTypeInfrastructure: dataForm.typeInfrastructure.idTypeInfrastructure,
            localite: dataForm.localite,
            latitude: dataForm.latitude,
            longitude: dataForm.longitude,
            idUtiliteInfrastructure:dataForm.utiliteInfrastructure.idUtiliteInfrastructure,
            idInfrastructureParent:dataForm.infrastructureMere?dataForm.infrastructureMere.idInfrastructure:"",
            idTypeStructure:dataForm.typeStructure.idTypeStructure,
            typeStructure:dataForm.typeStructure,
            idTypeStructureRattachement: getValues("idTypeStructure") || dataForm.structure.idTypeStructureRattachement || idUniversite,
        };
        //console.log(finalDTO)
        if(idInfrastructure){ 

            
            infrastructureService.updateInfrastructure(finalDTO.idInfrastructure, finalDTO)
            .then(()=>{
                setLoading(true)
                setEntiteDialog(false);
                notify.success();
                reset();

                let idTypeStructure = 2
                utiliteInfrastructureService.getListeInfrastructuresByTypeStructureIdStructure(idTypeStructure, idUniversite).then(data => {
                    setListInfrastructure(data);
                    //console.log(data)
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                    setLoading(false);
                })


                
            })
            .catch(errors=>{notify.echec(errors.response.data.message);
                console.log(errors)})
            .finally(()=>{
                setLoading(false);
                setSpin(false);
            })
            
        }else
        {
            //console.log(finalDTO)

            
            infrastructureService.createInfrastructure(finalDTO).then(()=>{
                
                setEntiteDialog(false);
                notify.success();
                reset();

                setLoading(true);

                let idTypeStructure = 2
                utiliteInfrastructureService.getListeInfrastructuresByTypeStructureIdStructure(idTypeStructure, idUniversite).then(data => {
                    setListInfrastructure(data);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                    setLoading(false);
                })

                
                
            }).catch(errors=>{
                notify.echec(errors.response.data.message);
                console.log(errors)
            })
            .finally(()=>{
                setLoading(false);
                setSpin(false);
            })
            
        }
        
    }
    

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-info" className="p-button-warning p-mr-2" title="Détails" style={{marginRight: '.5em'}} onClick={() => detailInfrastructure(rowData)} />
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" title="Edition" style={{marginRight: '.5em'}} onClick={() => editEntite(rowData)} />
                <Button icon="pi pi-trash" className="p-button-danger" title="Suppression" onClick={() => confirmDeleteEntite(rowData)} style={{marginLeft : 5}} />
            </React.Fragment>
        );
    }


    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>setEntiteDialog(true)} />
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
    );


    const headerSousListe = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
            </span>
        </div>
    );

    const detailsDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailsDialog} />
        </React.Fragment>
    );

    const utiliteDialogFooter = (
        <React.Fragment>
            <Button label="Quitter"  icon="pi pi-times" className="p-button-text" onClick={hideUtiliteDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveUtilite} />
        </React.Fragment>
    );

    const equipementDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideEquipementDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveEquipement} />
        </React.Fragment>
    );

    const deleteEntiteDialogFooter = (
        <React.Fragment>
            <Button label="Non" 
                disabled={spin?true:false}  icon={spin?"pi pi-spin pi-spinner":"pi pi-save"}
                className="p-button-success" onClick={hideDeleteEntiteDialog} />
            <Button label="Oui" disabled={spin?true:false} icon="pi pi-check" className="p-button-danger" onClick={deleteEntite} />
        </React.Fragment>
    );
    
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">*{errors[name].message}</small>
    };

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <>
                    <div className="p-d-flex p-ai-center export-buttons" style={{marginLeft : 10}}>
                        <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
                        <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} style={{marginLeft : 10}} className="p-button-warning p-mr-2" data-pr-tooltip="PDF"/>
                    </div>
                    <div style={{marginTop : 10}}>
                        <DataTable ref={dt} value={listInfrastructure} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                            dataKey="id" paginator rows={10} 
                            globalFilter={globalFilter}
                            header={`Liste des infrastructures (${listInfrastructure.length} infrastructure(s))`}>
                            <Column field="sigleInfrastructure" header="Sigle" filterMatchMode="contains" sortable={true} filter={true}></Column>
                            <Column field="libelleInfrastructure" header="Libellé" filterMatchMode="contains" sortable={true} filter={true}></Column>
                            <Column field="nombrePlace" header="Capacité" filterMatchMode="contains" sortable={true} filter={true}></Column>
                            <Column field="localite" header="Localité" filterMatchMode="contains" sortable={true} filter={true}></Column>
                            <Column field="libelleEtatInfrastructure" header="Etat" filterMatchMode="contains" sortable={true} filter={true}></Column>
                            <Column field="libelleTypeInfrastructure" header="type" filterMatchMode="contains" sortable={true} filter={true}></Column>
                            <Column field="libelleTypeStructureRattachement" header="structure de rattachement" filterMatchMode="contains" sortable={true} filter={true}></Column>
                            <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                        </DataTable>
                    </div>
                </>
                }
            </div>

            {/* Ajout d'une utilité  */}
            <Dialog visible={entiteDialog} style={{ width: '70%',height:"80vh" }} header="Edition d'une infrastructure" modal className="p-fluid" onHide={hideDialog}>
                <form onSubmit={handleSubmit(handleSave)} >
                    <div className="p-grid">
                        <hr />
                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="infrastructureMere">Infrastructure mére <span style={{color:"red"}}>(option)</span> </label>{getFormErrorMessage("infrastructureMere")}
                                <Controller name='infrastructureMere'
                                    control={control}
                                    render={({field})=>(
                                        <Dropdown name={field.name} id={field.name} value={field.value} optionLabel="libelleInfrastructure" options={listInfrastructure} autoFocus
                                            onChange={e=>{field.onChange(e.value);handleTypeStructure(e.value)}}
                                        />
                                    )}
                                />
                                
                            </div>
                        </div>
                        <div className="p-col-6" >
                            <div className="p-field">
                                <label htmlFor="libelleInfrastructure">Libellé</label>{getFormErrorMessage("libelleInfrastructure")}
                                <Controller name='libelleInfrastructure'
                                    control={control} rules={{ required: ' champ obligatoire.'}}
                                    render={({field})=>(
                                        <InputText name={field.name} id={field.name} value={field.value} {...field} required autoFocus className={classNames({ 'p-invalid': submitted && !infrastructure.libelleInfrastructure })} />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="sigleInfrastructure">Code/Sigle</label>{getFormErrorMessage("sigleInfrastructure")}
                                <Controller name='sigleInfrastructure'
                                    control={control} rules={{ required: ' champ obligatoire.'}}
                                    render={({field})=>(
                                        <InputText name={field.name}  id={field.name} value={field.value} {...field} required autoFocus />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="nombrePlace">Capacité en nombre de place</label>{getFormErrorMessage("nombrePlace")}
                                <Controller name='nombrePlace'
                                    control={control} rules={{ required: ' champ obligatoire.'}}
                                    render={({field})=>(
                                        <InputText name={field.name}  id={field.name} value={field.value} {...field} required autoFocus />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="localite">Localité</label>{getFormErrorMessage("localite")}
                                <Controller name='localite'
                                    control={control} rules={{ required: ' champ obligatoire.'}}
                                    render={({field})=>(
                                        <InputText name={field.name}  id={field.name} value={field.value} {...field} required autoFocus />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="typeStructure">type structure</label>{getFormErrorMessage("typeStructure")}
                                <Controller name='typeStructure'
                                    control={control}
                                    render={({field})=>(
                                        <Dropdown name={field.name} id={field.name} value={field.value} optionLabel="libelleTypeStructure" options={listTypeStructure} autoFocus
                                            onChange={e=>{field.onChange(e.value);handleTypeStructure(e.value)}}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="structure">structure d'appartenance</label>{getFormErrorMessage("structure")}
                                <Controller name='structure'
                                    control={control} /*rules={{ required: ' champ obligatoire.'}} */
                                    render={({field})=>(
                                        <Dropdown disabled={isAvailable?true:false } optionLabel="libelleTypeStructureRatachement" optionValue="idTypeStructureRattachement" value={field.value} options={listStructureAppartenance}
                                            {...field}
                                            onChange={e=>field.onChange(e.value)}
                                            autoFocus/>
                                    )}
                                />
                                
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="etatInfrastructure">état de l'infrastructure</label>{getFormErrorMessage("etatInfrastructure")}
                                <Controller name='etatInfrastructure'
                                    control={control} rules={{ required: ' champ obligatoire.'}}
                                    render={({field})=>(
                                        <Dropdown optionLabel="libelleEtatInfrastructure" optionValue="libelleEtatInfrastructure" value={field.value} options={etatInfrastructures}
                                        {...field}
                                         onchange={e=>field.onChange(e.value)}required autoFocus/>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="typeInfrastructure">Type d'infrastructure</label>{getFormErrorMessage("typeInfrastructure")}
                                <Controller name='typeInfrastructure'
                                    control={control} rules={{ required: ' champ obligatoire.'}}
                                    render={({field})=>(
                                    <Dropdown optionLabel="libelleTypeInfrastructure" optionValue="idTypeInfrastructure" value={field.value} options={typesInfrastructures} 
                                        onChange={e=>field.onChange(e.value)}
                                        required autoFocus
                                    />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="utiliteInfrastructure">utilité infrastructure</label>{getFormErrorMessage("utiliteInfrastructure")}
                                <Controller name='utiliteInfrastructure'
                                    control={control} rules={{ required: ' champ obligatoire.'}}
                                    render={({field})=>(
                                        <Dropdown optionLabel="libelleUtiliteInfrastructure" optionValue="idDepartement" value={field.value} options={listeUtilites} {...field} onChange={(e) => field.onChange(e.value)} required autoFocus/>
                                    )}
                                />
                            </div>
                        </div>

                    </div>

                    <div  style={{width:"200px",display:"flex"}} className="mt-3">
                        <Button label='Enregistrer' disabled={spin} icon={spin?"pi pi-spin pi-spinner":'pi pi-save'} type='submit' className='p-button-success mr-2' />
                        <Button label="Quitter" disabled={spin} icon="pi pi-times" className="p-button-danger" type='button' onClick={hideDialog} />
                    </div>
                </form>
            </Dialog>

            <Dialog visible={deleteEntiteDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteEntiteDialogFooter} onHide={hideDeleteEntiteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {infrastructure && <span>Etes vous sûr de supprimer cet élément ? <b>{infrastructure.libelleInfrastructure}</b>?</span>}
                </div>
            </Dialog>


            <Dialog visible={detailsDialog} style={{ width: '95%' }} header="Détails infrastructure" modal className="p-fluid" footer={detailsDialogFooter} onHide={hideUtiliteDialog}>
                

                <div className="p-grid">
                    <div className="p-col-5">
                        <div className="card">
                            <div className="card-head">
                                Détails infrastructure
                                <hr/>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped table-bordered table-hover">
                                    <tbody>
                                        <tr>
                                            <td>Sigle infrastructure:</td>
                                            <td>{infrastructure.sigleInfrastructure}</td>
                                        </tr>
                                        <tr>
                                            <td>Designation:</td>
                                            <td>{infrastructure.libelleInfrastructure}</td>
                                        </tr>
                                        <tr>
                                            <td>Nombre de places:</td>
                                            <td>{infrastructure.nombrePlace}</td>
                                        </tr>
                                        <tr>
                                            <td>Etat de l'infrastructure:</td>
                                            <td>{infrastructure.libelleEtatInfrastructure && infrastructure.libelleEtatInfrastructure}</td>
                                        </tr>
                                        <tr>
                                            <td>Type de l'infrastructure:</td>
                                            <td>{infrastructure && infrastructure.libelleTypeInfrastructure}</td>
                                        </tr>
                                        <tr>
                                            <td>Localité :</td>
                                            <td>{infrastructure.localite}</td>
                                        </tr>
                                        <tr>
                                            <td>Coordonnées géographiques :</td>
                                            <td>Longitude: {infrastructure.longitude}; Latitude: {infrastructure.latitude}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td>Structure d'appartenance :</td>
                                            
                                            <td> {infrastructure.libelleEtatInfrastructure} </td>
                                        </tr>
                                        
                                    </tbody>
                                    <tfoot>
                                        
                                    </tfoot>
                                    
                                </table>
                                <Button label="Editer" icon="pi pi-pencil" className="p-button-success p-mr-2" onClick={() => editEntite(infrastructure)} />
                            </div>
                        </div>
                    </div>

                    <div className="p-col-7">
                        <div className="p-col-12">
                            <div className="card">
                                <div className="card-head">
                                    Ensemble des utilisations
                                    <hr/>
                                </div>
                                <div className="card-body">
                                        {headerSousListe}
                                        {loadingUtilite ?
                                        <div className="p-grid">
                                            <div className="p-col-6" ></div>
                                            <div className="p-col-6" >
                                                {spinner.loadingSpinner("spin", "black")}
                                            </div>
                                        </div>
                                        :
                                        <React.Fragment>
                                            <DataTable ref={dt} value={listeUtilitesInfrastructure} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)} dataKey="id" paginator rows={10} globalFilter={globalFilter}>
                                                <Column field="libelleUtiliteInfrastructure" header="Utilités de l'infrastructure" filterMatchMode="contains" sortable={true} filter={false}></Column>
                                            </DataTable>
                                            <Button label="Gérer utilisations" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openGestionUtilites} />
                                        </React.Fragment>
                                        }
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="card">
                                <div className="card-head">
                                    Ensemble des équipements
                                    <hr/>
                                </div>
                                <div className="card-body">
                               
                                        {headerSousListe}
                                        {loadingEquipement ?
                                        <div className="p-grid">
                                            <div className="p-col-6" ></div>
                                            <div className="p-col-6" >
                                                {spinner.loadingSpinner("spin", "black")}
                                            </div>
                                        </div>
                                        :
                                        <React.Fragment>
                                            <DataTable ref={dt} value={listeEquipementsInfrastructure} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)} dataKey="id" paginator rows={10} globalFilter={globalFilter}>
                                                <Column field="libelleEquipement" header="Equipements de l'infrastructure" filterMatchMode="contains" sortable={true} filter={false}></Column>
                                            </DataTable>
                                            <Button label="Gérer équipements" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openGestionEquipements} />
                                        </React.Fragment>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>


            <Dialog visible={utiliteDialog} style={{ width: '85%' }} header="Définition des utilités de l'infrastructure" modal className="p-fluid" footer={utiliteDialogFooter} onHide={hideUtiliteDialog}>
                
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card">
                            <div className="card-head">
                                Détails sur l'infrastructure
                                <hr/>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped table-bordered table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Sigle</th>
                                            <th>Désignation</th>
                                            <th>Nombre de place</th>
                                            <th>Type</th>
                                            <th>Localité</th>
                                            <th>Etat</th>
                                            <th>Département</th>
                                        </tr>
                                        <tr>
                                            <td>{infrastructure.sigleInfrastructure}</td>
                                            <td>{infrastructure.libelleInfrastructure}</td>
                                            <td>{infrastructure.nombrePlace}</td>
                                            <td>{infrastructure && infrastructure.libelleTypeInfrastructure}</td>
                                            <td>{infrastructure.localite}</td>
                                            <td>{infrastructure && infrastructure.libelleEtatInfrastructure}</td>
                                            <td>{infrastructure.libelleTypeStructureRattachement}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <div className="card">
                            <div className="card-head">
                                Renseigment des utilités de l'infrastructure
                                <hr/>
                            </div>
                            <div className="card-body">
                                <div className="p-field">
                                    <PickList source={utilitesDisponible} target={listeUtilitesChoix} itemTemplate={itemTemplate}
                                        sourceHeader="Utilités disponibles" targetHeader="Utilités de l'infrastructure"
                                        sourceStyle={{ height: '250px', width : '250px' }} targetStyle={{ width : '250px' ,height: '250px' }}
                                        onChange={onUtiliteChange}>
                                    </PickList>
                                    {listeUtilitesChoix && listeUtilitesChoix.length===0 && <small className="p-invalid" style={{color : "red"}}>Vous devez choisir au moins une utilité !.</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>

            <Dialog visible={equipementDialog} style={{ width: '85%' }} header="Precision des équipements de l'infrasctructure" modal className="p-fluid" footer={equipementDialogFooter} onHide={hideEquipementDialog}>
                
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card">
                            <div className="card-head">
                                Détails sur l'infrastructure
                                <hr/>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped table-bordered table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Sigle</th>
                                            <th>Désignation</th>
                                            <th>Nombre de place</th>
                                            <th>Type</th>
                                            <th>Localité</th>
                                            <th>Etat</th>
                                        </tr>
                                        <tr>
                                            <td>{infrastructure.sigleInfrastructure}</td>
                                            <td>{infrastructure.libelleInfrastructure}</td>
                                            <td>{infrastructure.nombrePlace}</td>
                                            <td>{infrastructure.typeInfrastructure && infrastructure.typeInfrastructure.libelleTypeInfrastructure}</td>
                                            <td>{infrastructure.localite}</td>
                                            <td>{infrastructure.etatInfrastructure && infrastructure.etatInfrastructure.libelleEtatInfrastructure}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <div className="card">
                            <div className="card-head">
                                Renseigment des équipements de l'infrastructure
                                <hr/>
                            </div>
                            <div className="card-body">
                                <div className="p-field">
                                    <PickList source={equipementsDisponible} target={listeEquipementsChoix} itemTemplate={equipementItemTemplate}
                                        sourceHeader="Equipements disponible" targetHeader="Equipements de l'infrastructure"
                                        sourceStyle={{ height: '250px', width : '250px' }} targetStyle={{ width : '250px' ,height: '250px' }}
                                        onChange={onEquipementChange}>
                                    </PickList>
                                    {listeEquipementsChoix && listeEquipementsChoix.length===0 && <small className="p-invalid" style={{color : "red"}}>Vous devez choisir au moins un équipement !.</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>

        </div>
    )

}