import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../components/Notify'
import { Spinner } from '../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { FiliereService } from '../service/FiliereService'
import { ParcoursService } from '../service/ParcoursService'
import { SemestreService } from '../service/SemestreService'
import { AnneeService } from '../service/AnneeService'
import { SessionExamenService } from '../service/SessionExamenService'
import { ParcoursFiliereService } from '../service/ParcoursFiliereService'
import { NiveauService } from '../service/NiveauService'

import { Dropdown } from 'primereact/dropdown'


export class ProgrammationAdministrative extends Component {

    //Model de l'objet Notation
    emptySession = {
        idSession: null,
        dateOuverture: null,
        dateFermeture: null,
        typeSession: null,
        anneeSemestre: null,
        parcoursFiliere: null,
    }


    constructor(props){
        super(props);
        this.notify = new Notify()
        this.spinner = new Spinner()

        this.ModelDepartementFiliere = new FiliereService()
        this.ModelParcours = new ParcoursService()
        this.ModelSemestre = new SemestreService()
        this.ModelAnnee = new AnneeService()
        this.ModelSession = new SessionExamenService()
        this.ModelParcoursFiliere = new ParcoursFiliereService()
        this.ModelNiveau = new NiveauService()


        this.state = {
            curentSession: this.emptySession,
            token : null,
            idDepartement : 0,
            sessionList: [],
            departementList: [],
            parcoursList: [],
            semestreList: [],
            anneeList: [],
            redirect : false,
            loading : false,
            globalFilter: null,
            viewCreateSessionDialog: false,
            viewUpdateSessionDialog: false,
            listeInscritsDialog: false,
            allowSave: false,
            currentAnnee: null,
            currentParcours: null,
            currentSemestre: null,
            curentNiveau: null,
            currentTypeSession: null,
            currentDepartement: null,
            anneeSelected: false,
            deleteSessionDialog : false,
            idDepartementFiliere: null,
            listeNiveau: [],
            infoSession: {
                dateOuverture: '',
                dateFermeture: '',
                idTypeInscription: 1,
                idAnneeAcademique: '',
                idParcours: '',
                idDepartement: '',
                idNiveau: ''
            },
            listeEtudiantInscrits:[],
           
        }
    }

    componentDidMount(){
        
        const tokenDecoded = localStorage.getItem('tokenDecoded')
		const token = JSON.parse(tokenDecoded)


        this.setState({
            idEtablissement : token.code_etablissement,
            currentDepartement: null
        })
    
        console.log(token.code_etablissement)

        this.setState({loading : true})
        this.ModelSession.getSessionInscriptionAdministrativeEtablissement(token.code_etablissement).then(data => {
            
            //console.log(data.data)
            this.setState({
                    sessionList: data.data
                })
                this.setState({loading : false})
            }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)  
            console.log(error)
            this.setState({loading : false})
        })

    }


    
    //Gestion de l'ouverture du formulaire
    openNew = () => {

        this.ModelParcoursFiliere.getListeParcoursEtablissement(this.state.idEtablissement).then(data => {
           
            console.log(data)

            this.setState({parcoursList: data})
            
            })
            .catch(error => {
                console.log(error)
            })

        this.setState(
            {
                curentSession: this.emptySession,
                viewCreateSessionDialog: true,
                anneeSelected: false
            }
        )

    }

    hideDialog = () => {

        this.setState({
            curentSession: this.emptySession,
            viewCreateSessionDialog: false
        })

    }

    hideUpdateDialog = () => {

        this.setState({
            curentSession: this.emptySession,
            viewUpdateSessionDialog: false
        })

    }

    hideDeleteSessionDialog = () => {
        this.setState({ deleteSessionDialog: false });
    }

    deleteSession = (idSession) => {


        this.ModelSession.deleteSessionInscription(parseInt(idSession)).then(data => {
            this.notify.success()
            this.setState({
                deleteSessionDialog: false,
                curentSession: this.emptySession,
                redirect : true
            });
            this.setState({loading : true})


            this.ModelSession.getSessionInscriptionAdministrativeEtablissement(this.state.idEtablissement).then(data => {
                
                
                //console.log(data.data)
                this.setState({
                    sessionList: data.data
                })
                this.setState({loading : false})
            }).catch(error => {
            console.log(error)
            this.setState({loading : false})
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    onDepartementChange = (event) => {

        let departement = event.target.value
        this.setState({
            currentDepartement: departement
        })

        //On recupère la liste des parcours pour positionner dans la selection des parcours
        this.ModelParcoursFiliere.getListeParcoursDepartement(parseInt(departement.idDepartementFiliere)).then(data => {
            //On reinitialise les champs de selections suivant
            this.setState({
                parcoursList: data,
                currentParcours: null,
                currentSemestre: null,
                currentTypeSession: null,
                curentSession: this.emptySession
            })
        }).catch(error => {
            console.log(error)
        })
    }

    onAnneeChange = (event) => {

        console.log("Annee courant :",event.target.value)
        this.setState({
            currentAnnee: event.target.value
        })
        
    }

    onParcoursChange = (event) => {

        //On met à jour la valeur du parcours selectionné
        let parcours = event.target.value
        console.log(parcours)

        this.setState({
            currentParcours: parcours,
            idDepartementFiliere: parcours.idDepartementFiliere,
            curentNiveau: {}
        })

        this.ModelNiveau.getListeNiveauByIdParcours(parcours.idParcours).then(response => {
            this.setState({ listeNiveau: response,
             });
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    onNiveauChange = (event) => {
        let niveau = event.target.value
        this.setState({
            curentNiveau: niveau
        })

        //On selectionne la liste des années pour disponibiliser à la selection 
        this.ModelAnnee.getAll().then(data => {
            this.setState({
                anneeList: data,
                currentAnnee: null,
                curentSession: {}
            })
            }
        )
        .catch(error => {
            console.log(error)
        })
    }

    onInputChange = (event, field) => {
        
        let curentSession = this.state.curentSession
        curentSession[`${field}`] = event.target.value

        this.setState({
            curentSession,
            allowSave : this.validationOk(curentSession)
        })
    }

    onDateChange = (event, field) => {
        
        let curentSession = this.state.curentSession

        //console.log(this.state.currentParcours)

        curentSession[`${field}`] = event.target.value

        this.setState({
            curentSession: curentSession,
            allowSave : this.validationOk(curentSession)
        })
    }

    saveSession = () => {

        let sessionInscriptionAcademique = {
            dateOuverture: this.state.curentSession.dateOuverture,
            dateFermeture: this.state.curentSession.dateFermeture,
            idTypeInscription: 1,
            idAnneeAcademique: this.state.currentAnnee.idAnneeAcademique,
            idParcours: this.state.currentParcours.idParcours,
            idDepartement: this.state.currentParcours.idDepartementFiliere,
            idNiveau: this.state.curentNiveau.idNiveau
        }

        console.log(this.state.currentParcours)
        console.log("Donnes cours",sessionInscriptionAcademique)

        this.ModelSession.createInscription(sessionInscriptionAcademique).then(data => {

            this.setState({
                viewCreateSessionDialog: false,
                curentSession: {},
                currentAnnee: {},
                currentDepartement: {},
                currentParcours: {},
            })

            //On recharge la liste des sessions
            this.setState({loading : true})
            
            this.ModelSession.getSessionInscriptionAdministrativeEtablissement(this.state.idEtablissement).then(data => {
                    this.setState({
                        sessionList: data.data
                    })
                    this.setState({loading : false})
                }
            ).catch(error => {
                console.log(error)
                this.setState({loading : false})
            })
            this.notify.success()

        }).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
        })
        
    }

   
    afficheModifSession = (e) => {

        console.log("Ligne :",e)
        this.setState({
            infoSession: {
                dateOuverture: e.dateOuverture,
                dateFermeture: e.dateFermeture,
                idTypeInscription: 1,
                idAnneeAcademique: e.idAnneeAcademique,
                idParcours: e.idParcours,
                idDepartement: e.idDepartement,
                idNiveau: e.idNiveau
            },
            //viewUpdateSessionDialog :true
        })
    
        this.ModelParcoursFiliere.getListeParcoursEtablissement(this.state.idEtablissement).then(data => {           
            console.log("Parcours :",data)
            this.setState(
                {
                    parcoursList: data,
                } 
            )
            })
            .catch(error => {
                console.log(error)
            })

            this.setState(
                {
                    curentSession: this.emptySession,
                    viewUpdateSessionDialog: true,
                    anneeSelected: false
                }
            )

            console.log("Session update :", this.state.infoSession)
         
    }


    updateSession(){

        // let sessionUdpateInscriptionAcademique = {
        //     dateOuverture: this.state.curentSession.dateOuverture,
        //     //dateOuverture: e.dateOuverture,
        //     dateFermeture: this.state.curentSession.dateFermeture,
        //     //dateFermeture: e.dateFermeture,
        //     idTypeInscription: 1,
        //     idAnneeAcademique: this.state.currentAnnee.idAnneeAcademique,
        //     //idAnneeAcademique: e.idAnneeAcademique,
        //     idParcours: this.state.currentParcours.idParcours,
        //     //idParcours: e.idParcours,
        //     idDepartement: this.state.currentParcours.idDepartementFiliere,
        //     //idDepartement: e.idDepartement,
        //     idNiveau: this.state.curentNiveau.idNiveau
        //     //idNiveau: e.idNiveau
        // }

        let sessionInscriptionAcademique = {
            dateOuverture: this.state.curentSession.dateOuverture,
            dateFermeture: this.state.curentSession.dateFermeture,
            idTypeInscription: 1,
            idAnneeAcademique: this.state.currentAnnee.idAnneeAcademique,
            idParcours: this.state.currentParcours.idParcours,
            idDepartement: this.state.currentParcours.idDepartementFiliere,
            idNiveau: this.state.curentNiveau.idNiveau
        }

         this.ModelSession.updateInscription(sessionInscriptionAcademique).then(data => {

            console.log("Data :",data)
                this.setState({
                    viewUpdateSessionDialog: false,
                    curentSession: {},
                    currentAnnee: {},
                    currentDepartement: {},
                    currentParcours: {},
                })
                //On recharge la liste des sessions
                this.setState({loading : true})
                this.ModelSession.getSessionInscriptionAdministrativeEtablissement(this.state.idEtablissement).then(data => {
                        this.setState({
                            sessionList: data.data
                        })
                        this.setState({loading : false})
                    }
                ).catch(error => {
                    console.log(error)
                    this.setState({loading : false})
                })
                this.notify.success()
    
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })

    }
    
    
    validationOk = (session) =>{
        return (
                    session.dateOuverture
                    && session.dateFermeture
                    && this.state.currentAnnee
                    && this.state.currentParcours
                    && session.dateOuverture < session.dateFermeture
        ) ? true:false
    }

    /*
    listeExamenPage = (rowData) => {

        let idSession = rowData.idSession
        this.props.history.push('/lmd/parametres/examen/'+idSession)
    }*/


    confirmDeleteSession = (session) => {
        this.setState({
            curentSession : session,
            deleteSessionDialog: true
        });
    }


    onTypeSessionChange = (e) => {

        let curentSession = this.state.curentSession
        curentSession.typeSession = e.target.value
        this.setState({
            curentSession
        })
    }

    hideListeInscritsDialog = () =>{
        this.setState({
            listeInscritsDialog:false,
        })
    }

   

    InscritsSession = (session) => {

        console.log("Session :",session, session.idSessionInscription)
        this.setState({
            
            listeInscritsDialog:true,
            loading:true,
        })
        this.ModelSession.getListeInscritsSession(session.idSessionInscription).then(data => {
            //console.log("Etudiant Inscrits :", data);
            
            this.setState({
                loading:true,
                listeEtudiantInscrits: data,
            })
            this.setState({
                loading:false,
            })
            //console.log("Donnees :",this.state.listeEtudiantInscrits)
            
        })
    }
    
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-trash" className="p-button-danger" style={{marginRight: '.5em'}} title='Supprimer' onClick={ () => {this.confirmDeleteSession(rowData)} } />
                {/* <Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.5em'}} title='Supprimer' onClick={ () => this.afficheModifSession(rowData)} /> */}
                <Button type="button" icon="pi pi-eye" className="p-button-success" style={{marginRight: '.5em'}} title='Liste des inscrits' onClick={ () => this.InscritsSession(rowData)} />
                {/* <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editMention(rowData)} style={{marginRight: '.5em'}}/> */}
            </React.Fragment>
        );
    }

    
    render() {

        if(this.state.redirect) {
            //window.location.reload();
        }
        
        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const deleteSessionDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteSessionDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteSession(this.state.curentSession.idSessionInscription)} />
            </React.Fragment>
        );

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        let allowSave = (this.state.allowSave)? 
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveSession} />):
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled />);
        const sessionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                {allowSave}
            </React.Fragment>
        );

        let allowUpdate = (this.state.allowSave)? 
        (<Button label="Modifier" icon="pi pi-check" className="p-button-text" onClick={this.updateSession} />):
        (<Button label="Modifier" icon="pi pi-check" className="p-button-text" disabled />);
        
        
        const sessionUpdateDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideUpdateDialog} />
                {allowUpdate}
            </React.Fragment>
        );

        const listeInscritsDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideListeInscritsDialog}
                />
            </React.Fragment>
        );
   
        return (
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                            :
                            <DataTable  ref={(el) => this.dt = el} value={this.state.sessionList} selectionMode="single" header="Liste des sessions d'inscription administrative" paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="libelleParcours" header="Parcours" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un parcours"/>
                                <Column field="libelleAnneeAcademique" header="Année académique" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une année"/>
                                <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher un niveau"/>
                                <Column field="dateOuvertureFormated" header="Date ouverture"  sortable={true} filter={true} filterPlaceholder="date ouverture"/>
                                <Column field="dateFermetureFormated" header="Date fermeture"  sortable={true} filter={true} filterPlaceholder="date fermeture"/>
                                <Column field="etatSession" header="Etat de la session" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher un état"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '12em'}}/>
                            </DataTable>
                            }
                        </div>
                    </div>

                <Dialog visible={this.state.viewCreateSessionDialog} style={{ width: '80%' }} header="Informations de la session d'inscription administraive" modal className="p-fluid" footer={sessionDialogFooter} onHide={this.hideDialog}>
                    <hr/>
                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className="p-field">
                                <label htmlFor="annee">Selectionner le parcours</label>
                                <Dropdown optionLabel="libelleParcours" optionValue={this.state.currentParcours} value={this.state.currentParcours}  options={this.state.parcoursList} onChange={(e) => {this.onParcoursChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le parcours"/>
                                {this.state.currentDepartement && !this.state.currentParcours && <small className="p-invalid" style={{color : "red"}}>Selectionner le parcours.</small>}
                            </div>
                        </div>
                    </div>
                
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="annee">Selectionner le niveau</label> 
                                <Dropdown optionLabel="libelleNiveau" optionValue="idNiveau"  options={this.state.listeNiveau} value={this.state.curentNiveau} onChange={(e) => {this.onNiveauChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un niveau"/>
                                {this.state.currentParcours && !this.state.curentNiveau && <small className="p-invalid" style={{color : "red"}}>Selectionner un semestre.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="annee">Annee académique</label>
                                <Dropdown optionLabel="libelleAnnee" optionValue="idAnneeAcademique" value={this.state.currentAnnee}  options={this.state.anneeList} onChange={(e) => {this.onAnneeChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'année académique"/>
                                {this.state.currentSemestre && !this.state.currentAnnee && <small className="p-invalid" style={{color : "red"}}>Selectionner l'année.</small>}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <label htmlFor="dateOuvertureSession">Date debut de la session</label>
                            <input type='date'  value={this.state.curentSession.dateOuvertureSession} onChange={(e) => this.onDateChange(e, 'dateOuverture')} readOnlyInput  placeholder='Entrer la date ouverture'  className='p-inputtext p-component'/>
                            {this.state.currentSemestre && this.state.currentAnnee && !this.state.curentSession.dateOuverture && <small className="p-invalid" style={{color : "red"}}>Entrer la date ouverture session.</small>}
                        </div>

                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <label htmlFor="dateFermetureSession">Date fermeture </label>
                            <input type='date'  value={this.state.curentSession.dateFermetureSession} onChange={(e) => this.onDateChange(e, 'dateFermeture')} readOnlyInput  placeholder='Entrer la date fermetutre'  className='p-inputtext p-component'/>
                            {this.state.currentSemestre && this.state.currentAnnee && (!this.state.curentSession.dateFermeture || this.state.curentSession.dateFermeture<this.state.curentSession.dateOuverture ) && <small className="p-invalid" style={{color : "red"}}>Entrer la date ouverture session (doit être supérieur à la date de début).</small>}
                        </div>
                    </div>
                </Dialog>

                {/* Information sur la modification */}
                <Dialog visible={this.state.viewUpdateSessionDialog} style={{ width: '80%' }} header="Informations de la session d'inscription administraive à modifier" modal className="p-fluid" footer={sessionUpdateDialogFooter} onHide={this.hideUpdateDialog}>
                    <hr/>
                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className="p-field">
                                <label htmlFor="annee">Selectionner le parcours</label>
                                <Dropdown optionLabel="libelleParcours" optionValue={this.state.currentParcours} value={this.state.currentParcours}  options={this.state.parcoursList} onChange={(e) => {this.onParcoursChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le parcours"/>
                                {this.state.currentDepartement && !this.state.currentParcours && <small className="p-invalid" style={{color : "red"}}>Selectionner le parcours.</small>}
                            </div>
                        </div>
                    </div>
                
                            
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="annee">Selectionner le niveau</label>
                                <Dropdown optionLabel="libelleNiveau" optionValue="idNiveau"  options={this.state.listeNiveau} value={this.state.curentNiveau} onChange={(e) => {this.onNiveauChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un niveau"/>
                                {this.state.currentParcours && !this.state.curentNiveau && <small className="p-invalid" style={{color : "red"}}>Selectionner un semestre.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="annee">Annee académique</label>
                                <Dropdown optionLabel="libelleAnnee" optionValue="idAnneeAcademique" value={this.state.currentAnnee}  options={this.state.anneeList} onChange={(e) => {this.onAnneeChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'année académique"/>
                                {this.state.currentSemestre && !this.state.currentAnnee && <small className="p-invalid" style={{color : "red"}}>Selectionner l'année.</small>}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <label htmlFor="dateOuvertureSession">Date debut de la session</label>
                            <input type='date'  value={this.state.curentSession.dateOuverture} onChange={(e) => this.onDateChange(e, 'dateOuverture')} readOnlyInput  placeholder='Entrer la date ouverture'  className='p-inputtext p-component'/>
                            {this.state.currentSemestre && this.state.currentAnnee && !this.state.curentSession.dateOuverture && <small className="p-invalid" style={{color : "red"}}>Entrer la date ouverture session.</small>}
                        </div>

                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <label htmlFor="dateFermetureSession">Date fermeture </label>
                            <input type='date'  value={this.state.curentSession.dateFermetureSession} onChange={(e) => this.onDateChange(e, 'dateFermeture')} readOnlyInput  placeholder='Entrer la date fermetutre'  className='p-inputtext p-component'/>
                            {this.state.currentSemestre && this.state.currentAnnee && (!this.state.curentSession.dateFermeture || this.state.curentSession.dateFermeture<this.state.curentSession.dateOuverture ) && <small className="p-invalid" style={{color : "red"}}>Entrer la date ouverture session (doit être supérieur à la date de début).</small>}
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteSessionDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteSessionDialogFooter} onHide={this.hideDeleteSessionDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.curentSession && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.listeInscritsDialog} style={{ width: '900px' }} header="" modal footer={listeInscritsDialogFooter} onHide={this.hideListeInscritsDialog}>
                    <div className="confirmation-content">
                        <DataTable
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                rows={5}
                                selectionMode="single"
                                filterMatchMode="contains"
                                paginator
                                responsive
                                value={this.state.listeEtudiantInscrits}
                                header={
                                    "Liste des étudiants inscrits à la session (" +
                                    this.state.listeEtudiantInscrits.length +
                                    ")"
                                }
                                >
                                <Column
                                    field="ine"
                                    header="INE"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="nom"
                                    header="Nom"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="prenom"
                                    header="Prenom"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />

                                <Column
                                    field="sexe"
                                    header="Sexe"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="dateNaiss"
                                    header="Date de naissance"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="lieuNaissance"
                                    header="Lieu de naissance"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                
                                
                            </DataTable>
                        
                    </div>
                </Dialog>
            </div> 
        )

}

}

export default ProgrammationAdministrative
