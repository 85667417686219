import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../../components/Spinner";
import { Notify } from "../../../components/Notify";
import { CohorteServices } from "../../../volumeHoraire/service/CohorteServices";
import { ParcoursFiliereService } from "../../service/ParcoursFiliereService";
import { AnneeService } from "../../service/AnneeService";
import { SemestreService } from "../../service/SemestreService";
import { NiveauService } from "../../service/NiveauService";
import { ImportInscruptionService } from "../../service/ImportInscriptionService";

export class ImportInscription extends Component {
    constructor(props) {
        super(props);
        this.notify = new Notify();
        this.spinner = new Spinner();

        this.cohorteServices = new CohorteServices();
        this.parcoursDepartementService = new ParcoursFiliereService();
        this.anneeService = new AnneeService();
        this.semestreService = new SemestreService();
        this.niveauService = new NiveauService();
        this.importInscriptionService = new ImportInscruptionService();

        const campusScolarite = localStorage.getItem("campusScolarite");
        const campusDepartement = localStorage.getItem("campusDepartement");
        this.isScolarite = parseInt(JSON.parse(campusScolarite));
        this.isDepartement = parseInt(JSON.parse(campusDepartement));

        this.state = {
            departement: {},
            parcours: {},
            annee: {},
            niveau: {},
            listeDepartement: [],
            listeParcours: [],
            listeAnnee: [],
            listeNiveau: [],
            nomFichier: "",
            fichier: "",
            username: "",
            dateFichier: "",
            token: null,
            loadingChargement: false,
            bool: false,
            submitted: false,
            verifier: false,
        };
    }

    componentDidMount = () => {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        this.setState({ token: token });
        if (this.isDepartement) {
            this.parcoursDepartementService
                .getListeParcoursDepartement(token.code_departement)
                .then((data) => {
                    this.setState({ listeParcours: data });
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                });
        } else if (this.isScolarite) {
            this.cohorteServices
                .getListeDepartementEtab(token.code_etablissement)
                .then((data) => {
                    this.setState({
                        listeDepartement: data.departementEtabDtos,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                });
        }
        this.anneeService
            .getAll()
            .then((data) => {
                this.setState({ listeAnnee: data });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
            });
    };

    onFileChange = (event) => {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.split(".").pop();
            let ext = extension.toUpperCase();
            if (ext === "CSV") {
                this.setState({ nomFichier: file.name });
                const reader = new FileReader();
                reader.onload = (readerEvt) => {
                    let binaryString = readerEvt.target.result;
                    let fichier = btoa(binaryString);
                    this.setState({ fichier: fichier });
                    this.setState({ username: "osaidoupro@gmail.com" });
                    this.setState({ dateFichier: "21-03-2021" });
                };
                reader.readAsBinaryString(file);
            }
        }
    };
    saveImportFichier = () => {
        let idDepartement;
        if (this.isDepartement)
            idDepartement = this.state.token.code_departement;
        else if (this.isScolarite)
            idDepartement = this.state.departement.idDepartementEtab;
        let idParcours = this.state.parcours.idParcours;
        let idAnneeAcademique = this.state.annee.idAnneeAcademique;
        let idNiveau = this.state.niveau.idNiveau;
        let nomFichier = this.state.nomFichier;
        let fichier = this.state.fichier;
        let username = this.state.username;
        let dateFichier = this.state.dateFichier;
        let verifier = this.state.verifier;
        //this.state.departement ?idDepartement = this.state.departement.idDepartementEtab : idDepartement = this.state.token.code_departement
        let donneeFichier = {
            nomFichier: nomFichier,
            fichier: fichier,
            idDepartement: idDepartement,
            idParcours: idParcours,
            idAnneeAcademique: idAnneeAcademique,
            idNiveau: idNiveau,
            username: username,
            dateFichier: dateFichier,
            verifier: verifier,
        };
        //console.log(donneeFichier)
        this.setState({ loadingChargement: true });
        this.importInscriptionService
            .chargementFichier(donneeFichier)
            .then((data) => {
                this.setState({
                    loadingChargement: false,
                    bool: true,
                    submitted: true,
                });
            })
            .catch((error) => {
                this.notify.echec(error.response.data.message);
                console.log(error);
                this.setState({
                    loadingChargement: false,
                    bool: false,
                    submitted: true,
                });
            });
    };

    /*convertToCSV = (fichier) => {
        let buff = new Buffer(fichier, 'base64');
        let base64ToStringNew = buff.toString('ascii');
        return base64ToStringNew
    }

    exportCSVFile = (file, fileTitle) => {
        
        let jsonObject = JSON.stringify(file)
        let csv = this.convertToCSV(jsonObject)
        let exportedFilename = fileTitle || 'export.csv'
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilename)
        } else {
            let link = document.createElement('a')
          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', exportedFilename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      }*/

    onChampsChange = (e, field) => {
        const val = (e.target && e.target.value) || "";

        if (field === "departement") {
            this.setState({ departement: val });
            this.parcoursDepartementService
                .getListeParcoursDepartement(val.idDepartementEtab)
                .then((data) => {
                    this.setState({ listeParcours: data });
                })
                .catch((error) => console.log(error));
        }

        if (field === "parcours") {
            this.setState({ parcours: val });
            
            this.niveauService.getListeNiveauByIdParcours(val.idParcours).then(response => {

                console.log(response)
                this.setState({ listeNiveau: response,
                    niveau: {}
                 });
                
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })
            
            
            
            /*
            let niveau = [];
            this.semestreService.get(val.idParcours).then((data) => {
                


                let semestre = data;
                this.niveauService.getAll().then((response) => {
                    response.map((niv) => {
                        semestre.map((se) => {
                            if (niv.idNiveau === se.idNiveau) niveau.push(niv);
                        });
                    });
                    let collectionIdNiveau = [];
                    niveau.map((nive) => {
                        collectionIdNiveau.push(nive.idNiveau);
                    });
                    let listeNiveau = [];
                    niveau.map((nivo, index) => {
                        for (
                            let i = index + 1;
                            i < collectionIdNiveau.length;
                            i++
                        ) {
                            if (nivo.idNiveau === collectionIdNiveau[i]) {
                                listeNiveau.push(nivo);
                            }
                        }
                    });
                    //console.log(listeNiveau)
                    this.setState({ listeNiveau: listeNiveau });
                });
                



            });*/
        } else if (field === "annee") {
            this.setState({ annee: val });

            console.log({ annee: val })
        }
        else if (field === "niveau") {
            this.setState({ niveau: val });

            console.log({ niveau: val })
    }
        else;
    };

    render() {
        return (
            <div>
                {/*<div className="card p-lg-10" style={{marginLeft : "10%"}}>
                        {
                            <h6 style={{textAlign : "center"}}> Page d'import des inscriptions </h6>
                                
                        }
                    </div>*/}
                {/*<div className="p-lg-10" style={{marginLeft : "10%", textAlign : "center"}}>Chargement par fichier CSV</div>*/}
                <div
                    className="card p-lg-10"
                    style={{ marginLeft: "10%", marginTop: "5%" }}
                >
                    <div className="p-grid p-lg-12" style={{ marginTop: "3%" }}>
                        <div className="p-lg-12">
                            <h3
                                style={{
                                    textDecoration: "underline",
                                    textAlign: "center",
                                }}
                            >
                                <a
                                    href="/assets/layout/fichiers/caneva_incription_administrative.csv"
                                    target="_blank"
                                >
                                    Cliquer ici pour telecharger le canevas des
                                    inscriptions
                                </a>
                            </h3>
                        </div>
                        {this.isScolarite ? (
                            <>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: "20%" }}
                                >
                                    <Dropdown
                                        optionLabel="lbDepartementEtab"
                                        value={this.state.departement}
                                        options={this.state.listeDepartement}
                                        onChange={(e) => {
                                            this.onChampsChange(
                                                e,
                                                "departement"
                                            );
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un département"
                                    />
                                    {this.state.submitted &&
                                        !this.state.departement && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un département
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleParcours"
                                        optionValue={this.state.parcours}
                                        value={this.state.parcours}
                                        options={this.state.listeParcours}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "parcours");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un parcours"
                                    />
                                    {this.state.submitted &&
                                        !this.state.parcours && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un parcours.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleAnnee"
                                        optionValue={this.state.annee}
                                        value={this.state.annee}
                                        options={this.state.listeAnnee}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "annee");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner une année"
                                    />
                                    {this.state.submitted &&
                                        !this.state.annee && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner une année.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleNiveau"
                                        optionValue={this.state.niveau}
                                        value={this.state.niveau}
                                        options={this.state.listeNiveau}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "niveau");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un niveau"
                                    />
                                    {this.state.submitted &&
                                        !this.state.niveau && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un niveau.
                                            </small>
                                        )}
                                </div>
                            </>
                        ) : this.isDepartement ? (
                            <>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: "20%" }}
                                >
                                    <Dropdown
                                        optionLabel="libelleParcours"
                                        optionValue={this.state.parcours}
                                        value={this.state.parcours}
                                        options={this.state.listeParcours}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "parcours");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un parcours"
                                    />
                                    {this.state.submitted &&
                                        !this.state.parcours && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un parcours.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleAnnee"
                                        optionValue={this.state.annee}
                                        value={this.state.annee}
                                        options={this.state.listeAnnee}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "annee");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner une année"
                                    />
                                    {this.state.submitted &&
                                        !this.state.annee && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner une année.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleNiveau"
                                        optionValue={this.state.niveau}
                                        value={this.state.niveau}
                                        options={this.state.listeNiveau}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "niveau");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un niveau"
                                    />
                                    {this.state.submitted &&
                                        !this.state.niveau && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un niveau.
                                            </small>
                                        )}
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="p-lg-12" style={{ marginTop: 10 }}>
                        <div
                            className="p-lg-12 p-grid"
                            style={{ marginTop: 10, marginLeft: "10%" }}
                        >
                            <div
                                className="p-lg-12 p-grid"
                                style={{ marginLeft: "0%" }}
                            >
                                <div
                                    className="p-lg-4"
                                    style={{ marginTop: -20 }}
                                >
                                    <input
                                        type="file"
                                        style={{ marginLeft: "0%" }}
                                        onChange={(e) => {
                                            this.onFileChange(e);
                                        }}
                                    />
                                </div>
                                {this.state.loadingChargement ? (
                                    <div className="p-lg-2">
                                        {this.spinner.loadingSpinner(
                                            "spin",
                                            "black"
                                        )}
                                    </div>
                                ) : (
                                    <div className="p-lg-2">
                                        {this.state.submitted &&
                                            this.state.bool && (
                                                <span
                                                    style={{ color: "green" }}
                                                >
                                                    Chargement réussi !
                                                </span>
                                            )}
                                        {this.state.submitted &&
                                            !this.state.bool && (
                                                <span style={{ color: "red" }}>
                                                    Echec de chargement !
                                                </span>
                                            )}
                                    </div>
                                )}
                                <div
                                    className="p-lg-4"
                                    style={{ marginTop: -20 }}
                                >
                                    <Button
                                        style={{ marginLeft: "0%" }}
                                        label="Valider le chargement"
                                        onClick={this.saveImportFichier}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportInscription;
