import React, { Component } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../../components/Spinner";
import { Notify } from "../../../components/Notify";
import { DepartementService } from "../../service/DepartementService";
import { CohorteServices } from "../../../volumeHoraire/service/CohorteServices";
import { ParcoursFiliereService } from "../../service/ParcoursFiliereService";
import { AnneeService } from "../../service/AnneeService";
import { SemestreService } from "../../service/SemestreService";
import { NiveauService } from "../../service/NiveauService";
import { InscriptionService } from "../../service/InscriptionService";
import { ImportInscruptionService } from "../../service/ImportInscriptionService";

export class ListeInscriptionENS extends Component {
    emptyEtudiant = {
        idEtudiant: null,
        ine: null,
        nom: null,
        prenom: null,
        sexe: null,
        dateNaissance: null,
        lieuNaissance: null,
        email: null,
        telephone: null,
        typeStagiaire: null,
    };
    constructor(props) {
        super(props);
        this.notify = new Notify();
        this.spinner = new Spinner();

        this.cohorteServices = new CohorteServices();
        this.parcoursDepartementService = new ParcoursFiliereService();
        this.anneeService = new AnneeService();
        this.semestreService = new SemestreService();
        this.niveauService = new NiveauService();
        this.inscriptionService = new InscriptionService();
        this.importInscriptionService = new ImportInscruptionService();

        const campusScolarite = localStorage.getItem("campusScolarite");
        const campusDepartement = localStorage.getItem("campusDepartement");
        this.isScolarite = parseInt(JSON.parse(campusScolarite));
        this.isDepartement = parseInt(JSON.parse(campusDepartement));

        this.state = {
            etudiant: this.emptyEtudiant,
            student: {},
            departement: {},
            parcours: {},
            annee: {},
            niveau: {},
            semestre: {},
            listeEC: [],
            listeECOptionnel: [],
            listeUEOptionnel: [],
            listeDepartement: [],
            listeParcours: [],
            listeAnnee: [],
            listeNiveau: [],
            listeSemestre: [],
            listeEtudiant: [],
            listeSexe: [
                { idSexe: 1, sexe: "M" },
                { idSexe: 2, sexe: "F" },
            ],
            token: null,
            loadingChargement: false,
            spin: false,
            bool: false,
            submitted: false,
            globalFilter: null,
            dialogEtudiant: false,
            dialogDeleteAllStudents: false,
            dialogDeleteThisStudent: false,
            dialogDisplayFileStudent : false,
            dialogUe: false,
            optionUE: false,
            typeStagiaire: {},
            downloadFile : {},
            typeStagiaires : [],
           
        };

        this.cols = [
            { field: "ine", header: "INE étudiant" },
            { field: "nom", header: "Nom étudiant" },
            { field: "prenom", header: "Prénom(s) étudiant" },
            { field: "dateNaissance", header: "Date de naiss." },
            { field: "lieuNaissance", header: "Lieu de naiss." },
            { field: "typeStagiaire", header: "type de stag." },
        ];

        this.exportColumns = this.cols.map((col) => ({
            title: col.header,
            dataKey: col.field,
        }));
    }
    componentDidMount = () => {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        this.setState({ token: token });
        //console.log(this.isDepartement)
        if (this.isDepartement) {
            this.parcoursDepartementService
                .getListeParcoursDepartement(token.code_departement)
                .then((data) => {
                    this.setState({ listeParcours: data });
                    //console.log(data)
                })
                .catch((error) => {
                    console.log(error);
                    // this.notify.echec(error.response.data.message)
                });
        } else if (this.isScolarite) {
            console.log(token)
            this.cohorteServices
                .getListeDepartementEtab(token.code_etablissement)
                .then((data) => {
                    this.setState({
                        listeDepartement: data.departementEtabDtos,
                    });
                })
                .catch((error) => console.log(error));
        }
        this.anneeService
            .getAll()
            .then((data) => {
                this.setState({ listeAnnee: data });
            })
            .catch((error) => {
                console.log(error);
                //this.notify.echec(error.response.data.message)
            });

        this.importInscriptionService  
            .getTypeStagiaire()
            .then((data) => {
                this.setState({typeStagiaires : data})
                //console.log(data);
            })
            .catch((error) => {
                console.log(error)
            })
    };
    listeInscris = () => {
        let idDepartement;
        if (this.isDepartement)
            idDepartement = this.state.token.code_departement;
        else if (this.isScolarite)
            idDepartement = this.state.departement.idDepartementEtab;
        let idParcours = this.state.parcours.idParcours;
        let idAnneAccademique = this.state.annee.idAnneeAcademique;
        let idNiveau = this.state.niveau.idNiveau;
        let idSemestre = this.state.semestre.idSemestre;
        let idTypeStagiaire = this.state.typeStagiaire.idTypeStagiaire;

        this.setState({ loadingChargement: true });

        //console.log(idDepartement, idParcours, idAnneAccademique, idNiveau, idSemestre)
        this.inscriptionService
            .getListeEtudiantInscriptionEcENS(
                idDepartement,
                idParcours,
                idAnneAccademique,
                idNiveau,
                idSemestre,
                idTypeStagiaire
            )
            .then((data) => {
                this.setState({
                    listeEtudiant: data,
                    loadingChargement: false,
                });
                //console.log(data)
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loadingChargement: false });
            });
    };

    saveEtudiant = () => {
        this.setState({ submitted: true });
        let idEtudiant = this.state.etudiant.idEtudiant;
        let ine = this.state.etudiant.ine;
        let nom = this.state.etudiant.nom;
        let prenom = this.state.etudiant.prenom;
        let sexe = this.state.etudiant.sexe;
        let dateNaissance = this.state.etudiant.dateNaissance;
        let lieuNaissance = this.state.etudiant.lieuNaissance;
        let email = this.state.etudiant.email;
        let telephone = this.state.etudiant.telephone;
        let idTypeStagiaire = this.state.typeStagiaire.idTypeStagiaire;

        if (
            idEtudiant &&
            ine &&
            nom &&
            prenom &&
            sexe &&
            dateNaissance &&
            lieuNaissance &&
            idTypeStagiaire
        ) {
            const etudiant = {
                idEtudiant: idEtudiant,
                ine: ine,
                nom: nom,
                prenom: prenom,
                sexe: sexe,
                dateNaiss: dateNaissance,
                lieuNaissance: lieuNaissance,
                email: email,
                telephone: telephone,
                idTypeStagiaire : idTypeStagiaire
            };
            //console.log(etudiant)
            this.inscriptionService
                .updateEtudiant(idEtudiant, etudiant)
                .then((data) => {
                    //console.log(data)
                    this.listeInscris();
                    this.setState({ dialogEtudiant: false });
                    this.notify.success();
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                });
        }
    };
    onValueChange = (e, field) => {
        const val = (e.target && e.target.value) || "";
        let etudiant = this.state.etudiant;
        if (field == "sexe") {
            etudiant[`${field}`] = val.sexe;
        } else {
            etudiant[`${field}`] = val;
        }

        //console.log(val.sexe)
        //console.log(etudiant)
        this.setState({ etudiant });
    };

    onChampsChange = (e, field) => {
        const val = (e.target && e.target.value) || "";

        if (field === "departement") {
            this.setState({ departement: val });

            //console.log({departement: val})
            this.parcoursDepartementService
                .getListeParcoursDepartement(val.idDepartementEtab)
                .then((data) => {
                    this.setState({ listeParcours: data });
                })
                .catch((error) => console.log(error));
        }

        if (field === "parcours") {
            this.setState({ parcours: val });



            this.niveauService.getListeNiveauByIdParcours(val.idParcours).then(response => {

                console.log(response)
                this.setState({ listeNiveau: response,
                    niveau: {}
                 });
                
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })



            //let niveau = [];
 /*
            //console.log({parcours: val})
            this.semestreService.get(val.idParcours).then((data) => {
                let semestre = data;
                this.setState({ listeSemestre: data });

                

               
                this.niveauService.getAll().then((response) => {
                    response.map((niv) => {
                        semestre.map((se) => {
                            if (niv.idNiveau === se.idNiveau) niveau.push(niv);
                        });
                    });
                    let collectionIdNiveau = [];
                    niveau.map((nive) => {
                        collectionIdNiveau.push(nive.idNiveau);
                    });
                    //console.log(collectionIdNiveau)
                    let listeNiveau = [];
                    niveau.map((nivo, index) => {
                        for (
                            let i = index + 1;
                            i < collectionIdNiveau.length;
                            i++
                        ) {
                            if (nivo.idNiveau === collectionIdNiveau[i]) {
                                //console.log(nivo)
                                listeNiveau.push(nivo);
                            }
                        }
                    });
                    //console.log(listeNiveau)
                    this.setState({ listeNiveau: listeNiveau });
                });

            });*/

        } else if (field === "annee") {
            //console.log(val)

            //console.log({annee: val})
            this.setState({ annee: val });
        } else if (field === "niveau") {
            this.setState({ niveau: val });

           // console.log({niveau: val})
            this.semestreService
                .getSemestreNiveau(val.idNiveau)
                .then((data) => {
                    this.setState({ listeSemestre: data.semestreDtoList });
                    //console.log(data)
                });
        } else if (field === "typeStagiaire") {
            this.setState({ typeStagiaire: val });
            //console.log({semestre: val})
        }else if (field === "semestre") {
            this.setState({ semestre: val });
            //console.log({semestre: val})
        }
    };
    modifierEtudiant = (etudiant) => {
        //console.log(etudiant)
        this.setState({
            submitted: false,
            dialogEtudiant: true,
            etudiant: etudiant,
        });
    };
    hideDialogEtudiant = () => {
        this.setState({
            dialogEtudiant: false,
        });
    };

    confirmDeleteAllStudents = () => {
        this.setState({ dialogDeleteAllStudents: true });
    };

    confirmDeleteThisStudents = (rowData) => {
        this.setState({ dialogDeleteThisStudent: true, student: rowData });
    };


    displayFileStudents = (etudiant) => {
        //let idEtudiant =  this.state.etudiant.idEtudiant;
        console.log(etudiant)
        //console.log(etudiant.idEtudiant)

        this.setState({
            submitted: false,
            dialogDownloadFileStudent: true,
            etudiant: etudiant,
        });

        this.inscriptionService
            .getFilesUploadStudent(etudiant.idEtudiant)
            .then((data) => {
                this.setState({downloadFile : data})
                console.log("Les données", data[0].fileName, data[1].fileUrl)
            })

        
    };

    downloadFileStudents = () => {
        
        // this.setState({
        //     submitted: false,
        //     dialogDownloadFileStudent: true,
          
        // });
        this.inscriptionService
            .downloadFileStudent()
            .then((data) => {
                this.setState({downloadFile : data})
            })
    };

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="Modifier"
                    onClick={() => this.modifierEtudiant(rowData)}
                />
                <Button
                    title="Supprimer cet étudiant"
                    type="button"
                    icon="pi pi-trash"
                    onClick={() => this.confirmDeleteThisStudents(rowData)}
                    style={{ marginRight: ".5em" }}
                    className="p-button-danger p-mr-2"
                />

                <Button
                    title="visualiser les fichier"
                    type="button"
                    icon="pi pi-eye"
                    onClick={() => this.displayFileStudents(rowData)}
                    style={{ marginRight: ".5em" }}
                    className="p-button-success p-mr-2"
                />
            </React.Fragment>
        );
    };
    exportExcel = () => {
        const listeEtudiant = this.state.listeEtudiant;
        const title =
            this.state.departement.lbDepartementEtab +
            "_" +
            this.state.parcours.libelleParcours +
            "_" +
            this.state.annee.libelleAnnee +
            "_" +
            this.state.niveau.libelleNiveau +
            "_" +
            this.state.semestre.libelleSemestre;
        let donneeExentiel = listeEtudiant.map((d) => ({
            "Ine étudiant": d.ine,
            Nom: d.nom,
            Prenom: d.prenom,
            Sexe: d.sexe,
            "Date naiss.": d.dateNaissance,
            "lieu naiss.": d.lieuNaissance,
        }));
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(donneeExentiel);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, title);
        });
    };
    saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((module) => {
            let EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            module.default.saveAs(
                data,
                fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
            );
        });
    };
    exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default("l");

                /*doc.text("Ministère de l'Enseignement Supérieur,", 10, 10)
                doc.text("de la Recherche et de l'Innovation", 10, 20)
                doc.text("----------------------------------", 10, 30)
                doc.text("Secrétariat Général", 10, 40)
                doc.text("----------------------------------", 10, 50)
                doc.text("Université Joseph Ky Zerbo", 10, 60)
                doc.text("----------------------------------", 10, 70)
                doc.text("Sciences Exactes et Appliquées", 10, 80)
                doc.text("----------------------------------", 10, 90)
                doc.text("Département de Math/PC", 10, 100)
                
                doc.text("Liste des étudiants", 50, 120)
                
                doc.autoTable(this.exportColumns, this.state.listeEtudiant, {margin : {top : 140}});*/
                const title =
                    this.state.departement.lbDepartementEtab +
                    "_" +
                    this.state.parcours.libelleParcours +
                    "_" +
                    this.state.annee.libelleAnnee +
                    "_" +
                    this.state.niveau.libelleNiveau +
                    "_" +
                    this.state.semestre.libelleSemestre;
                doc.text(
                    "Département : " + this.state.departement.lbDepartementEtab,
                    10,
                    10
                );
                doc.text(
                    "Parcours : " + this.state.parcours.libelleParcours,
                    10,
                    20
                );
                doc.text("Niveau : " + this.state.niveau.libelleNiveau, 10, 30);
                doc.text(
                    "Semestre : " + this.state.semestre.libelleSemestre,
                    10,
                    40
                );
                doc.text("Liste des étudiants", 50, 50);

                doc.autoTable(this.exportColumns, this.state.listeEtudiant, {
                    margin: { top: 60 },
                });
                this.paginationPage(doc);
                doc.save(title + ".pdf");
            });
        });
    };
    paginationPage = (doc) => {
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.text(
                "" + String(i) + "/" + String(pageCount),
                290,
                200,
                null,
                null,
                "right"
            );
        }
    };
    hideDeleteAllStudensDialog = () => {
        this.setState({ dialogDeleteAllStudents: false });
    };
    hideDeleteThisStudensDialog = () => {
        this.setState({ dialogDeleteThisStudent: false });
    };

    hideDialogDownloadFileStudent = () => {
        this.setState({ dialogDownloadFileStudent: false });
    }
    deleteAllStudents = () => {
        const idParcours = this.state.parcours.idParcours;
        const idAnnee = this.state.annee.idAnneeAcademique;
        const idNiveau = this.state.niveau.idNiveau;
        //console.log(idParcours, idAnnee, idNiveau)
        this.setState({ spin: true });
        this.inscriptionService
            .deleteAllStudents(idAnnee, idParcours, idNiveau)
            .then((data) => {
                this.setState({ spin: false, dialogDeleteAllStudents: false });
                this.notify.success();
            })
            .catch((error) => {
                this.notify.echec(error.response.data.message);
                this.setState({ spin: false, dialogDeleteAllStudents: false });
                console.log(error);
            });
    };
    deleteThisStudent = () => {
        // const idParcours = this.state.parcours.idParcours;
        // const idAnnee = this.state.annee.idAnneeAcademique;
        // const idNiveau = this.state.niveau.idNiveau;
        const idEtudiant = this.state.student.idEtudiant;
        //console.log(idEtudiant)
        this.inscriptionService
            .deleteStudentENS(idEtudiant)
            .then((data) => {
                this.setState({ spin: false, dialogDeleteThisStudent: false });
                this.notify.success();
            })
            .catch((error) => {
                this.notify.echec(error.response.data.message);
                this.setState({ spin: false, dialogDeleteThisStudent: false });
                console.log(error);
            });
    };
    render() {
        const etudiantDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideDialogEtudiant}
                />
                <Button
                    label="Enregistrer"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={this.saveEtudiant}
                />
            </React.Fragment>
        );
        const deleteAllStudentsDialogFooter = (
            <React.Fragment>
                <Button
                    label="NON"
                    icon="pi pi-times"
                    disabled={this.state.spin}
                    className="p-button-text"
                    onClick={this.hideDeleteAllStudensDialog}
                />
                <Button
                    label="OUI"
                    icon={
                        this.state.spin
                            ? "pi pi-spin pi-spinner"
                            : "pi pi-check"
                    }
                    disabled={this.state.spin}
                    className="p-button-text"
                    onClick={() => this.deleteAllStudents()}
                />
            </React.Fragment>
        );
        const deleteThisStudentsDialogFooter = (
            <React.Fragment>
                <Button
                    label="NON"
                    icon="pi pi-times"
                    disabled={this.state.spin}
                    className="p-button-text"
                    onClick={this.hideDeleteThisStudensDialog}
                />
                <Button
                    label="OUI"
                    icon={
                        this.state.spin
                            ? "pi pi-spin pi-spinner"
                            : "pi pi-check"
                    }
                    disabled={this.state.spin}
                    className="p-button-text"
                    onClick={() => this.deleteThisStudent()}
                />
            </React.Fragment>
        );
        return (
            <div>
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div
                    className="card p-lg-10"
                    style={{ marginLeft: "10%", marginTop: "5%" }}
                >
                    <div className="p-grid p-lg-12" style={{ marginTop: "3%" }}>
                        {this.isScolarite ? (
                            <>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: "5%" }}
                                >
                                    <Dropdown
                                        optionLabel="lbDepartementEtab"
                                        value={this.state.departement}
                                        options={this.state.listeDepartement}
                                        onChange={(e) => {
                                            this.onChampsChange(
                                                e,
                                                "departement"
                                            );
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un département"
                                    />
                                    {this.state.submitted &&
                                        !this.state.departement && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un département
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleParcours"
                                        optionValue={this.state.parcours}
                                        value={this.state.parcours}
                                        options={this.state.listeParcours}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "parcours");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un parcours"
                                    />
                                    {this.state.submitted &&
                                        !this.state.parcours && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un parcours.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleAnnee"
                                        optionValue={this.state.annee}
                                        value={this.state.annee}
                                        options={this.state.listeAnnee}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "annee");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner une année"
                                    />
                                    {this.state.submitted &&
                                        !this.state.annee && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner une année.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleNiveau"
                                        optionValue={this.state.niveau}
                                        value={this.state.niveau}
                                        options={this.state.listeNiveau}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "niveau");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un niveau"
                                    />
                                    {this.state.submitted &&
                                        !this.state.niveau && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un niveau.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleSemestre"
                                        optionValue={this.state.semestre}
                                        value={this.state.semestre}
                                        options={this.state.listeSemestre}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "semestre");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un semestre"
                                    />
                                    {this.state.submitted &&
                                        !this.state.semestre && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un semestre
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Button
                                        style={{ marginLeft: "0%" }}
                                        label="Afficher"
                                        onClick={this.listeInscris}
                                    />
                                </div>
                            </>
                        ) : this.isDepartement ? (
                            <>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: "20%" }}
                                >
                                    <Dropdown
                                        optionLabel="libelleParcours"
                                        optionValue={this.state.parcours}
                                        value={this.state.parcours}
                                        options={this.state.listeParcours}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "parcours");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un parcours"
                                    />
                                    {this.state.submitted &&
                                        !this.state.parcours && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un parcours.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleAnnee"
                                        optionValue={this.state.annee}
                                        value={this.state.annee}
                                        options={this.state.listeAnnee}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "annee");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner une année"
                                    />
                                    {this.state.submitted &&
                                        !this.state.annee && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner une année.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleNiveau"
                                        optionValue={this.state.niveau}
                                        value={this.state.niveau}
                                        options={this.state.listeNiveau}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "niveau");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un niveau"
                                    />
                                    {this.state.submitted &&
                                        !this.state.niveau && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un niveau.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleTypeStagiaire"
                                        optionValue={this.state.typeStagiaire}
                                        value={this.state.typeStagiaire}
                                        options={this.state.typeStagiaires}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "typeStagiaire");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner type stagiaire"
                                    />
                                    {this.state.submitted &&
                                        !this.state.typeStagiaire && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner type stagiaire
                                            </small>
                                        )}
                                </div>

                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleSemestre"
                                        optionValue={this.state.semestre}
                                        value={this.state.semestre}
                                        options={this.state.listeSemestre}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "semestre");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un semestre"
                                    />
                                    {this.state.submitted &&
                                        !this.state.semestre && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un semestre
                                            </small>
                                        )}
                                </div>

                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Button
                                        style={{ marginLeft: "0%" }}
                                        label="Afficher"
                                        onClick={this.listeInscris}
                                    />
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    {this.state.loadingChargement ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div
                                className="p-d-flex p-ai-center export-buttons"
                                style={{ marginLeft: 10, marginTop: 20 }}
                            >
                                <Button
                                    title="Supprimer tous les étudiants"
                                    type="button"
                                    icon="pi pi-trash"
                                    onClick={this.confirmDeleteAllStudents}
                                    className="p-button-danger p-mr-2"
                                />
                                <Button
                                    title="Export en excel"
                                    type="button"
                                    icon="pi pi-file-excel"
                                    onClick={this.exportExcel}
                                    style={{ marginLeft: 10 }}
                                    className="p-button-success p-mr-2"
                                    data-pr-tooltip="XLS"
                                />
                                <Button
                                    title="Export en pdf"
                                    type="button"
                                    icon="pi pi-file-pdf"
                                    onClick={this.exportPdf}
                                    style={{ marginLeft: 10 }}
                                    className="p-button-warning p-mr-2"
                                    data-pr-tooltip="PDF"
                                />
                            </div>
                            <div className="p-lg-12" style={{ marginTop: 10 }}>
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeEtudiant}
                                    selectionMode="single"
                                    header={
                                        "Liste des étudiants inscrits (" +
                                        this.state.listeEtudiant.length +
                                        ")"
                                    }
                                    paginator={true}
                                    rows={10}
                                    globalFilter={this.state.globalFilter}
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="ine"
                                        header="Matricule"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher un ine"
                                    />
                                    <Column
                                        field="nom"
                                        header="Nom"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un nom"
                                    />
                                    <Column
                                        field="prenom"
                                        header="Prénom"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un prénom"
                                    />
                                    <Column
                                        field="sexe"
                                        header="Sexe"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher par sexe"
                                    />
                                    <Column
                                        field="dateNaissance"
                                        header="date de naiss"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher date de naiss."
                                    />
                                    <Column
                                        field="lieuNaissance"
                                        header="Lieu de naiss."
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher par lieu de naiss."
                                    />
                                    <Column
                                        body={this.actionBodyTemplate}
                                        style={{
                                            textAlign: "center",
                                            width: "13em",
                                        }}
                                    />
                                </DataTable>
                            </div>
                        </>
                    )}
                    <Dialog
                        visible={this.state.dialogEtudiant}
                        style={{ width: "70%" }}
                        header="Données etudiant"
                        modal
                        className="p-fluid"
                        footer={etudiantDialogFooter}
                        onHide={this.hideDialogEtudiant}
                    >
                        <div className="p-grid p-lg-12">
                            <div className="p-lg-4">
                                <label htmlFor="ine">INE</label>
                                <InputText
                                    value={this.state.etudiant.ine}
                                    onChange={(e) => {
                                        this.onValueChange(e, "ine");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                />
                                {this.state.submitted &&
                                    !this.state.etudiant.ine && (
                                        <small
                                            className="p-invalid"
                                            style={{ color: "red" }}
                                        >
                                            Ce champs est obligatoire.
                                        </small>
                                    )}
                            </div>
                            <div className="p-lg-4">
                                <label htmlFor="nom">Nom</label>
                                <InputText
                                    optionLabel="libelleParcours"
                                    value={this.state.etudiant.nom}
                                    onChange={(e) => {
                                        this.onValueChange(e, "nom");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                />
                                {this.state.submitted &&
                                    !this.state.etudiant.nom && (
                                        <small
                                            className="p-invalid"
                                            style={{ color: "red" }}
                                        >
                                            Ce champs est obligatoire.
                                        </small>
                                    )}
                            </div>
                            <div className="p-lg-4">
                                <label htmlFor="prenom">Prénom</label>
                                <InputText
                                    value={this.state.etudiant.prenom}
                                    onChange={(e) => {
                                        this.onValueChange(e, "prenom");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                />
                                {this.state.submitted &&
                                    !this.state.etudiant.prenom && (
                                        <small
                                            className="p-invalid"
                                            style={{ color: "red" }}
                                        >
                                            Ce champs est obligatoire.
                                        </small>
                                    )}
                            </div>
                        </div>
                        <div
                            className="p-grid p-lg-12"
                            style={{ marginTop: -30 }}
                        >
                            <div className="p-lg-4">
                                <label htmlFor="sexe">Sexe</label>
                                <Dropdown
                                    editable={true}
                                    optionLabel="sexe"
                                    optionValue={this.state.etudiant.sexe}
                                    options={this.state.listeSexe}
                                    value={this.state.etudiant.sexe}
                                    onChange={(e) => {
                                        this.onValueChange(e, "sexe");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                />
                                {this.state.submitted &&
                                    !this.state.etudiant.sexe && (
                                        <small
                                            className="p-invalid"
                                            style={{ color: "red" }}
                                        >
                                            Ce champs est obligatoire.
                                        </small>
                                    )}
                            </div>
                            <div className="p-lg-4">
                                <label htmlFor="dateNaisse">
                                    Date de naissance
                                </label>
                                <InputText
                                    value={this.state.etudiant.dateNaissance}
                                    onChange={(e) => {
                                        this.onValueChange(e, "dateNaissance");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                />
                                {this.state.submitted &&
                                    !this.state.etudiant.dateNaissance && (
                                        <small
                                            className="p-invalid"
                                            style={{ color: "red" }}
                                        >
                                            Ce champs est obligatoire.
                                        </small>
                                    )}
                            </div>
                            <div className=" p-lg-4">
                                <label htmlFor="lieuNaiss">
                                    Lieu de naissance
                                </label>
                                <InputText
                                    value={this.state.etudiant.lieuNaissance}
                                    onChange={(e) => {
                                        this.onValueChange(e, "lieuNaissance");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                />
                                {this.state.submitted &&
                                    !this.state.etudiant.lieuNaissance && (
                                        <small
                                            className="p-invalid"
                                            style={{ color: "red" }}
                                        >
                                            Ce champs est obligatoire.
                                        </small>
                                    )}
                            </div>
                        </div>
                        <div
                            className="p-grid p-lg-12"
                            style={{ marginTop: -30 }}
                        >
                            <div className="p-lg-6">
                                <label htmlFor="email">Email</label>
                                <InputText
                                    value={this.state.etudiant.email}
                                    onChange={(e) => {
                                        this.onValueChange(e, "email");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                />
                            </div>
                            <div className="p-lg-6">
                                <label htmlFor="tel">Téléphone</label>
                                <InputText
                                    value={this.state.etudiant.telephone}
                                    onChange={(e) => {
                                        this.onValueChange(e, "telephone");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                />
                            </div>
                        </div>
                    </Dialog>
                    <Dialog
                        visible={this.state.dialogDeleteAllStudents}
                        style={{ width: "450px" }}
                        header="Confirmez"
                        modal
                        footer={deleteAllStudentsDialogFooter}
                        onHide={this.hideDeleteAllStudensDialog}
                    >
                        <div className="confirmation-content">
                            <i
                                className="pi pi-exclamation-triangle p-mr-3"
                                style={{ fontSize: "2rem" }}
                            />
                            {
                                <span>
                                    Etes-vous sûr de supprimer tous les
                                    étudiants ?
                                </span>
                            }
                        </div>
                    </Dialog>
                    <Dialog
                        visible={this.state.dialogDeleteThisStudent}
                        style={{ width: "450px" }}
                        header="Confirmez"
                        modal
                        footer={deleteThisStudentsDialogFooter}
                        onHide={this.hideDeleteThisStudensDialog}
                    >
                        <div className="confirmation-content">
                            <i
                                className="pi pi-exclamation-triangle p-mr-3"
                                style={{ fontSize: "2rem" }}
                            />
                            {
                                <span>
                                    Etes-vous sûr de supprimer ce étudiant ?
                                </span>
                            }
                        </div>
                    </Dialog>

                    <Dialog
                        visible={this.state.dialogDownloadFileStudent}
                        style={{ width: "450px" }}
                        header="Lien des fichier étudiant"
                        modal
                       
                        onHide={this.hideDialogDownloadFileStudent}
                    >
                        <div className="confirmation-content">
                            
                            {
                                <span>
                                    <ul>
                                        <li>
                                            
                                            <Button 
                                                type="button"
                                                icon="pi pi-download"
                                                title="telecharger"
                                                style={{ marginRight: ".5em" }}
                                                className="p-button-success p-mr-2"
                                                onClick={() => this.downloadFileStudents}
                                            />
                                            
                                        </li>
                                            
                                    </ul>
                                </span>
                            }
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}



export default ListeInscriptionENS