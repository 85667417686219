import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { Dropdown } from 'primereact/dropdown'
import { UserService } from '../../service/UserService';
import { RadioButton } from 'primereact/radiobutton';
import { DepartementService } from '../../service/DepartementService';
import { ParcoursFiliereService } from '../../service/ParcoursFiliereService';
import PhoneInput from "react-phone-number-input";
import {PickList} from 'primereact/picklist';
import { use } from 'react';

export class Utilisateurs extends Component {

    constructor() {
        super();
        this.state = {
            code_iesr: null,
            listeUser : [],
            createUserDialog: false,
            deleteUserDialog: false,
            newUser: {
                identifiant: "",
                email: "",
                codeDepartement: null,
                codeEtablissement: null,
                codeIesr: null,
                matricule: null,
                password : "",
                roles : []
            },
            submitted: false,
            loading : false,
            globalFilter: null,
            passwordConfirmation: "",
            typeStructure: "",
            listeEtablissement: [],
            listeEtablissementAccueil: [],
            listeDepartement: [],
            listeDepartementAccueil: [],
            curentEtablissement: null,
            curentDepartement: null,
            typeUtilisateur: null,
            listeRoles: [],
            choiseRoles: [],
            editUserDialog: false,
            typeStructureAccueil: null,
            etablissementAccueil: null,
            departementAccueil: null,
            usernameCherch: "",
            submittedCherch: false,
            updatePasswordDialog: false,
            updateUserDialog: false
        }

        this.userService = new UserService()
        this.departementService = new DepartementService()
        this.parcoursFiliereService = new ParcoursFiliereService()
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    }


    componentDidMount() {

       

        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)

        this.setState({
            code_iesr: token.code_iesr
        })


        this.userService.getListeRoles().then(data => {
            this.setState({
                listeRoles: data
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })


        this.departementService.getListeEtablissementEtab(token.code_iesr).then(data => {
            this.setState({
                listeEtablissement: data.etablissementDtoList,
                listeEtablissementAccueil: data.etablissementDtoList
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

        
    }


    openNew = () => {
        this.setState({
            newUser: {
                identifiant: "",
                email: "",
                codeDepartement: null,
                codeEtablissement: null,
                codeIesr: null,
                matricule: null,
                password : "",
                roles : []
            },
            submitted: false,
            createUserDialog: true
        })
    }



    openEditionUser = (userData) => {

        let typeStructure = ""
        if(userData.codeDepartement){
            typeStructure = "DEPARTEMENT"
        }else if(userData.codeEtablissement){
            typeStructure = "ETABLISSEMENT"
        }else if(userData.codeIesr){
            typeStructure = "IESR"
        }

        let typeUtilisateur = null
        if(userData.matricule){
            typeUtilisateur = "Enseignant"
        }

        let listeIdRoleActuels = userData.roles.map(role => {
            return role.idRole
        })

        let curentRolesDisponible = this.state.listeRoles.filter(role => {
            return listeIdRoleActuels.indexOf(role.idRole)===-1
        })

        this.setState({
            newUser: userData,
            submitted: false,
            createUserDialog: true,
            rolesDisponible: curentRolesDisponible,
            typeStructure: typeStructure,
            typeUtilisateur: typeUtilisateur,
            choiseRoles: userData.roles
        })
        
    }



    hideDialog = () => {
        this.setState({
            submitted: false,
            createUserDialog: false,
            newUser: {
                identifiant: "",
                email: "",
                codeDepartement: null,
                codeEtablissement: null,
                codeIesr: null,
                matricule: null,
                password : "",
                roles : []
            },
            passwordConfirmation: "",
            password: "",
            typeStructure: "",
            choiseRoles: [],
            rolesDisponible: []
        });
    }


    hideChangePasswordDialog = () => {
        this.setState({
            submitted: false,
            updatePasswordDialog: false,
            newUser: {
                identifiant: "",
                email: "",
                codeDepartement: null,
                codeEtablissement: null,
                codeIesr: null,
                matricule: null,
                password : "",
                roles : []
            },
            passwordConfirmation: "",
            typeStructure: "",
            choiseRoles: [],
            rolesDisponible: []
        });
    }



    saveUser = () => {

        this.setState({
            submitted: true
        })

        let user = this.state.newUser

        let valideDataUser = (
            user.identifiant 
            && 
            user.identifiant.length>=4
            &&
            user.nom
            &&
            user.nom.length>=2
            &&
            user.prenom
            &&
            user.prenom.length>=2
            &&
            user.adresseEmail
            &&
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.adresseEmail)
            &&
            user.numeroTelephone
            &&
            user.numeroTelephone.length>=8
            &&
            this.state.choiseRoles
            &&
            this.state.choiseRoles.length>0
        )?true:false


        if(!user.idCompteUser && valideDataUser){
            valideDataUser = (
                user.password
                &&
                user.password.length>=6
                &&
                this.state.passwordConfirmation
                &&
                this.state.passwordConfirmation === user.password
                )?true:false
        }


        let dataSpecifiqueValide = (
            this.state.typeStructure
            &&
            this.state.typeStructure!==""
            &&
            this.state.typeUtilisateur
            &&
            this.state.typeUtilisateur!==""
        )?true:false

        let structureRattachementValide = (
            (this.state.typeStructure && this.state.typeStructure==="ETABLISSEMENT" && this.state.curentEtablissement && this.state.curentEtablissement.idEtablissement)
            ||
            (this.state.typeStructure && this.state.typeStructure==="DEPARTEMENT" && this.state.curentDepartement && this.state.curentDepartement.idDepartementEtab)
            ||
            this.state.typeStructure==="IESR"
        )?true:false

        let valideMatriculeEnseignant = (
            (this.state.typeUtilisateur && this.state.typeUtilisateur==="Enseignant" && user.matricule && user.matricule.length>=3)
            ||
            (this.state.typeUtilisateur && this.state.typeUtilisateur==="NonEnseignant")
        )?true:false

        if(valideDataUser && dataSpecifiqueValide && structureRattachementValide && valideMatriculeEnseignant){
            let newUser = {
                identifiant: user.identifiant,
                nom: user.nom,
                prenom: user.prenom,
                adresseEmail: user.adresseEmail,
                numeroTelephone: user.numeroTelephone,
                password: user.password,
                roles: this.state.choiseRoles
            }


            if(this.state.typeStructure==="ETABLISSEMENT"){
                newUser['codeEtablissement'] = this.state.curentEtablissement.idEtablissement
            }else if(this.state.typeStructure==="DEPARTEMENT") {
                newUser['codeDepartement'] = this.state.curentDepartement.idDepartementEtab
            }else{
                newUser['codeIesr'] = this.state.code_iesr
            }

            if(this.state.typeUtilisateur==="Enseignant"){
                newUser['matricule'] = user.matricule
            }

            
            if(this.state.code_iesr){

            
                if(user.idCompteUser){

                    this.userService.update(user.idCompteUser, newUser).then(response => {
                        this.notify.success()
                        this.hideDialog()

                    }).catch(error => {
                        console.log(error)
                        this.notify.echec(error.response.data.message)
                    })

                }else{

                    this.userService.create(newUser).then(response => {
                        this.notify.success()
                        this.hideDialog()

                    }).catch(error => {
                        console.log(error)
                        this.notify.echec(error.response.data.message)
                    })
                }

            }else{
                this.notify.echec("Votre compte utilisateur ne dispose pas de l'information de l'IESR, impossible donc d'effectuer cette operation !!! ")
            }

        }

    }


    savePasswordChange = () => {

        this.setState({
            submitted: true
        })
        
        let user = this.state.newUser

        let validePassword = ( 
            user.password
            &&
            user.password.length>=6
            &&
            this.state.passwordConfirmation
            &&
            this.state.passwordConfirmation === user.password
        )?true:false


        if(validePassword){
            this.userService.updateUserPassword(user).then(data => {
                this.notify.success()
                this.hideChangePasswordDialog()
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })
        }
    }



    editUser = (user) => {
        this.setState({
            newUser: user,
            editUserDialog: true
        });
    }

    editUserPassword = (userData) => {
        this.setState({
            updatePasswordDialog: true,
            newUser: userData
        })
    }

    hideEditUser = () => {
        this.setState({
            newUser: {
                identifiant: "",
                adresseEmail: "",
                codeDepartement: null,
                codeEtablissement: null,
                codeIesr: null,
                matricule: null,
                password : "",
                roles : []
            },
            editUserDialog: true
        });
    }




    confirmDeleteUser = (user) => {
        this.setState({
            newUser: user,
            deleteUserDialog: true
        });
    }


    hideDeleteUserDialog = () =>{
        this.setState({
            createUserDialog: false,
            newUser: {
                identifiant: "",
                adresseEmail: "",
                codeDepartement: null,
                codeEtablissement: null,
                codeIesr: null,
                matricule: null,
                password : "",
                roles : []
            },
            deleteUserDialog: false,
            passwordConfirmation: "",
            typeStructure: ""
        })
    }



    deleteUser(idUser) {

        this.userService.delete(idUser).then(data => {

            this.notify.success()
            this.setState({
                createUserDialog: false,
                newUser: {
                    identifiant: "",
                    adresseEmail: "",
                    codeDepartement: null,
                    codeEtablissement: null,
                    codeIesr: null,
                    matricule: null,
                    password : "",
                    roles : []
                },
                deleteUserDialog: false,
                typeStructure: ""
            });

            this.setState({loading : true})

            if(this.state.idDepartement){

                this.userService.getAllByDepartement(this.state.idDepartement).then(data => 
                    this.setState({listeUser: data, loading : false})
                ).catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error)
                    this.setState({loading : false})
                })

            }else if(this.state.idEtablissement){

                this.userService.getAllByEtablissement(this.state.idEtablissement).then(data => 
                    this.setState({listeUser: data, loading : false})
                ).catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error)
                    this.setState({loading : false})
                })
            }

        })
    }

    onUserDataChange = (e, fieldName) => {
        const val = (e.target && e.target.value) || '';
        let user = this.state.newUser;
        user[`${fieldName}`] = val;

        this.setState({newUser: user});
    }

    onUsernameCherchChange = (e) => {
        const val = (e.target && e.target.value) || '';
        this.setState({
            usernameCherch: val
        })
    }

    onPhoneChange = (value) => {
        const val = value || '';
        let user = this.state.newUser

        user['numeroTelephone'] = val
        this.setState({
            newUser: user
        })
    }

    onRolesChose = (e) => {
        this.setState({
            choiseRoles: e.target,
            listeRoles: e.source
        })
    }


    onEtablissementChange = (e) => {
        const val = (e.target && e.target.value) || null;

        this.setState({
            curentEtablissement: val
        })

        this.parcoursFiliereService.getListeDepartementEtablissement(val.idEtablissement).then(data => {
            this.setState({
                listeDepartement: data
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

    }


    onEtablissementAccueilChange = (e) => {
        const val = (e.target && e.target.value) || null;

        this.setState({
            etablissementAccueil: val
        })

        this.parcoursFiliereService.getListeDepartementEtablissement(val.idEtablissement).then(data => {
            this.setState({
                listeDepartementAccueil: data
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

        if(this.state.typeStructureAccueil && this.state.typeStructureAccueil === "ETABLISSEMENT"){

            this.setState({
                loading: true
            })
            this.userService.getAllByEtablissement(val.idEtablissement).then(data => {

                this.setState({
                    listeUser: data,
                    loading: false
                })
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({
                    loading: false
                })
            })

        }

    }


    onDepartementChange = (e) => {
        const val = (e.target && e.target.value) || null;
        this.setState({
            curentDepartement: val
        })
    }


    onDepartementAccueilChange = (e) => {
        const val = (e.target && e.target.value) || null;
        this.setState({
            departementAccueil: val
        })


        this.setState({
            loading: true
        })
        this.userService.getAllByDepartement(val.idDepartementEtab).then(data => {

            this.setState({
                listeUser: data,
                loading: false
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
            this.setState({
                loading: false
            })
        })


    }


    onPasswordConfirm = (e) => {
        const val = (e.target && e.target.value) || '';
        this.setState({
            passwordConfirmation: val
        })
    }


    onTypeStructureChange = (value) => {
        this.setState({
            typeStructure: value
        })
    }

    onTypeStructureAccueilChange = (value) => {
        this.setState({
            typeStructureAccueil: value,
            submittedCherch: false
        })


        if(value==="IESR"){

            this.setState({
                loading: true
            })
            this.userService.getAllByIesr(this.state.code_iesr).then(data => {

                this.setState({
                    listeUser: data,
                    loading: false
                })
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
    
                this.setState({
                    loading: false
                })
            })
        }else{

            if(value === "ETABLISSEMENT" && this.state.etablissementAccueil && this.state.etablissementAccueil.idEtablissement){

                this.setState({
                    loading: true,
                    listeUser: []
                })
                this.userService.getAllByDepartement(this.state.etablissementAccueil.idEtablissement).then(data => {
        
                    this.setState({
                        listeUser: data,
                        loading: false
                    })
                }).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({
                        loading: false
                    })
                })
            }else if(value === "DEPARTEMENT" && this.state.departementAccueil && this.state.departementAccueil.idDepartementEtab){

                this.setState({
                    loading: true,
                    listeUser: []
                })
                this.userService.getAllByEtablissement(this.state.departementAccueil.idDepartementEtab).then(data => {

                    this.setState({
                        listeUser: data,
                        loading: false
                    })
                }).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({
                        loading: false
                    })
                })
            }else{
                this.setState({
                    listeUser: []
                })
            }
        }
    }


    onTypeUtilisateurChange = (value) => {
        this.setState({
            typeUtilisateur: value
        })
    }


    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }



    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-user-edit" className="p-button-warning" onClick={() => this.editUserPassword(rowData)} style={{marginRight: '.5em'}}/>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.openEditionUser(rowData)} style={{marginRight: '.5em'}}/>
            </React.Fragment>
        );
    }


    afficherRoles = (rowData) => {
        return (
            (rowData.roles && rowData.roles.length>0)?
            (<React.Fragment>
                {rowData.roles.map(data => {
                    return data.libelleRole + "; " 
                })}
            </React.Fragment>):("")
        );
    }

    itemTemplate = (item) => {
        return <span>{item.libelleRole}</span>
    }

    chercherUserByUsername = () => {
        this.setState({
            submittedCherch: true
        })

        if(this.state.usernameCherch){

            this.setState({
                loading: true
            })

            this.userService.getUserByUsername(this.state.usernameCherch).then(data => {
                this.setState({
                    listeUser: data,
                    loading: false
                })

            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({
                    loading: false
                })
            })
        }

    }



    render() {

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const userDialogFooter = (
            <React.Fragment>
                <hr/>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveUser} />
            </React.Fragment>
        );


        const passwordChangeFooter = (
            <React.Fragment>
                <hr/>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideChangePasswordDialog} />
                <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.savePasswordChange} />
            </React.Fragment>
        );

        const deleteUserDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteUserDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteUser(this.state.newUser.idUser) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">

                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">



                        <div className="p-grid">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="flex align-items-center">
                                    <RadioButton inputId="iesrAccueil" name="typeStructureAccueil" value="IESR" onChange={(e) => this.onTypeStructureAccueilChange(e.value)} checked={this.state.typeStructureAccueil === 'IESR'} />
                                    <label htmlFor="iesrAccueil" className="ml-2">IESR</label>
                                </div>
                                <div className="flex align-items-center">
                                    <RadioButton inputId="etablissementAccueil" name="typeStructureAccueil" value="ETABLISSEMENT" onChange={(e) => this.onTypeStructureAccueilChange(e.value)} checked={this.state.typeStructureAccueil === 'ETABLISSEMENT'} />
                                    <label htmlFor="etablissementAccueil" className="ml-2">ETABLISSEMENT</label>
                                </div>
                                <div className="flex align-items-center">
                                    <RadioButton inputId="departementAccueil" name="typeStructureAccueil" value="DEPARTEMENT" onChange={(e) => this.onTypeStructureAccueilChange(e.value)} checked={this.state.typeStructureAccueil === 'DEPARTEMENT'} />
                                    <label htmlFor="departementAccueil" className="ml-2">DEPARTEMENT</label>
                                </div>

                                <div className="flex align-items-center">
                                    <RadioButton inputId="personnel" name="typeStructureAccueil" value="PSERSONNEL" onChange={(e) => this.onTypeStructureAccueilChange(e.value)} checked={this.state.typeStructureAccueil === 'PSERSONNEL'} />
                                    <label htmlFor="personnel" className="ml-2">PERSONNEL</label>
                                </div>
                            </div>
                        </div>


                        <div className="p-grid">
                            <div className="p-md-12 p-sm-12 p-xs-12 p-lg-12">
                                <hr/>
                            </div>
                        </div>
                    

                    {this.state.typeStructureAccueil && this.state.typeStructureAccueil!=="IESR" &&
                        
                        ((this.state.typeStructureAccueil==="ETABLISSEMENT")? ( 
                            <div className="p-grid">
                                <div className="p-md-12 p-sm-12 p-xs-12 p-lg-12">
                                
                                    <div className="p-field">
                                    <label htmlFor="idEtablissement"><span style={{color:'red'}}>*</span> Choisir l'établissement de rattachement</label>
                                        <br/>
                                        <Dropdown
                                            optionLabel="nom"
                                            optionValue="idEtablissement"
                                            value={this.state.etablissementAccueil}
                                            options={this.state.listeEtablissementAccueil}
                                            onChange={(e) => {
                                                this.onEtablissementAccueilChange(e);
                                            }}
                                            required
                                            autoFocus
                                            className="p-md-12"
                                            placeholder="Selectionner l'établissement"
                                        />
                                        {this.state.submitted && this.state.typeStructure==="DEPARTEMENT" && !this.state.curentEtablissement  && <small className="p-invalid" style={{color : "red"}}>Choisir l'établissement concerné.</small>}
                                    </div>
                                </div>
                            </div>
                        ):

                        ((this.state.typeStructureAccueil === "DEPARTEMENT")?  (

                            <div className="p-grid">
                                <div className="p-md-12 p-sm-12 p-xs-12 p-lg-6">
    
                                    <div className="p-field">
                                        <label htmlFor="idEtablissement"><span style={{color:'red'}}>*</span> Choisir l'établissement de rattachement</label>
                                        <br/>
                                        <Dropdown
                                            optionLabel="nom"
                                            optionValue="idEtablissement"
                                            value={this.state.etablissementAccueil}
                                            options={this.state.listeEtablissementAccueil}
                                            onChange={(e) => {
                                                this.onEtablissementAccueilChange(e);
                                            }}
                                            required
                                            autoFocus
                                           className="p-md-12"
                                            placeholder="Selectionner l'établissement"
                                        />
                                        {this.state.submitted && this.state.typeStructure==="DEPARTEMENT" && !this.state.curentEtablissement  && <small className="p-invalid" style={{color : "red"}}>Choisir l'établissement concerné.</small>}
                                    </div>
                                </div>

                                <div className="p-md-12 p-sm-12 p-xs-12 p-lg-6">
                                
                                    <div className="p-field">
                                    <label htmlFor="idDepartement"><span style={{color:'red'}}>*</span> Choisir le département</label>
                                        <br/>
                                        <Dropdown
                                            optionLabel="lbDepartementEtab"
                                            optionValue="idDepartement"
                                            value={this.state.departementAccueil}
                                            options={this.state.listeDepartementAccueil}
                                            onChange={(e) => {
                                                this.onDepartementAccueilChange(e);
                                            }}
                                            required
                                            autoFocus
                                            className="p-md-12"
                                            placeholder="Selectionner le departement"
                                        />
                                        {this.state.submitted && this.state.typeStructure==="DEPARTEMENT" && !this.state.curentDepartement  && <small className="p-invalid" style={{color : "red"}}>Choisir le departement de rattachement.</small>}
                                    </div>
                                </div>
                            </div>):(
                                <div className="p-grid">
                                    <div className='p-md-12 p-sm-12 p-xs-12 p-lg-8'>
                                        <div className="p-field">
                                            <label htmlFor="usernameCherch"><span style={{color:'red'}}>*</span> Renseigner le nom d'utilisateur</label>
                                            <br/>
                                            <br/>
                                            <InputText id="usernameCherch"  value={this.state.usernameCherch || ""}  onChange={(e) => this.onUsernameCherchChange(e)} required className="p-md-6" />
                                            <br/>
                                            <br/>
                                            {this.state.submittedCherch && !this.state.usernameCherch && <small className="p-invalid" style={{color : "red"}}>Renseigner le nom d'utilisateur à rechercher.</small>}
                                        </div>
                                    </div>

                                    <div className='p-md-12 p-sm-12 p-xs-12 p-lg-4'>
                                        <br/>
                                        <br/>
                                        <Button label="Valider" icon="pi pi-check-square" className="p-button-success p-mr-2" onClick={() => this.chercherUserByUsername()} />
                                    </div>
                                </div>
                            )
                            
                        )
                        
                    )}
                        


                    {this.state.typeStructureAccueil && this.state.typeStructureAccueil!=="IESR" &&
                        (
                            <div className="p-grid">
                                <div className="p-md-12 p-sm-12 p-xs-12 p-lg-12">
                                    <hr/>
                                </div>
                            </div>
                        )
                    }
                    


                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :

                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeUser} selectionMode="single" header={`Liste des utilisateurs (${this.state.listeUser.length} utilisateur(s))`} paginator={true} rows={5}
                            globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="identifiant" header="Nom utilisateur" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher le nom utilisateur"/>

                            <Column field="nom" header="Nom" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher le nom "/>
                            <Column field="prenom" header="Prénom" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher le prénom "/>
                            <Column field="adresseEmail" header="Adresse mail" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher adresse mail"/>
                            <Column field="numeroTelephone" header="Téléphone" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher le téléphone "/>

                            <Column header="Roles" filterMatchMode="contains" sortable={true} filterPlaceholder="rechercher par rôles" body={this.afficherRoles} style={{textAlign:'left'}}/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>


                <Dialog visible={this.state.createUserDialog} style={{ width: '80%' }} header="Informations utilisateur" modal className="p-fluid" footer={userDialogFooter} onHide={this.hideDialog}>
                    <hr/>
                    
                    <div className="p-grid">
                        <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="identifiant"><span style={{color:'red'}}>*</span> Nom d'utilisateur</label>
                                <InputText id="identifiant" value={this.state.newUser.identifiant || ""}  onChange={(e) => this.onUserDataChange(e, 'identifiant')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} disabled={this.state.newUser.idCompteUser}/>
                                {this.state.submitted && (!this.state.newUser.identifiant || this.state.newUser.identifiant.length<4) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit contenir au moin 4 caractères.</small>}
                            </div>
                        </div>
                        <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="adresseEmail"><span style={{color:'red'}}>*</span> Adresse mail</label>
                                <InputText id="adresseEmail" type='email' value={this.state.newUser.adresseEmail || ""}  onChange={(e) => this.onUserDataChange(e, 'adresseEmail')} required className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && (!this.state.newUser.adresseEmail || !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.newUser.adresseEmail))) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit être un mail valide.</small>}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="nom"><span style={{color:'red'}}>*</span> Nom </label>
                                <InputText id="nom" value={this.state.newUser.nom || ""}  onChange={(e) => this.onUserDataChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && (!this.state.newUser.nom || this.state.newUser.nom.length<2) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit contenir au moins 2 caractères.</small>}
                            </div>
                        </div>
                        <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="prenom"><span style={{color:'red'}}>*</span> Prénom(s)</label>
                                <InputText id="prenom" value={this.state.newUser.prenom || ""}  onChange={(e) => this.onUserDataChange(e, 'prenom')} required className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && (!this.state.newUser.prenom || this.state.newUser.prenom.length<2) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit contenir au moins 2 caractères.</small>}
                            </div>
                        </div>
                    </div>

                    {!this.state.newUser.idCompteUser &&
                        <div className="p-grid">
                            <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                                <div className="p-field">
                                    <label htmlFor="password"><span style={{color:'red'}}>*</span> Mot de passe</label>
                                    <InputText id="password" type='password' value={this.state.newUser.password || ""}  onChange={(e) => this.onUserDataChange(e, 'password')} required className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && (!this.state.newUser.password || this.state.newUser.password.length<6) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire doint contenir au moins 6 caractères.</small>}
                                </div>
                            </div>

                            <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                                <div className="p-field">
                                    <label htmlFor="passwordConfirm"><span style={{color:'red'}}>*</span> Confirmer le mot de passe</label>
                                    <InputText id="passwordConfirm" type='password' value={this.state.passwordConfirmation || ""}  onChange={(e) => this.onPasswordConfirm(e)} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && (!this.state.passwordConfirmation || this.state.passwordConfirmation!==this.state.newUser.password) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit etre identique au mot de passe.</small>}
                                </div>
                            </div>
                        </div>
                    }


                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <hr/>
                                <h5>Type de structure de rattachement de l'utilisateur</h5>
                            <hr/>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="flex align-items-center">
                                <RadioButton inputId="iesr" name="typeStructure" value="IESR" onChange={(e) => this.onTypeStructureChange(e.value)} checked={this.state.typeStructure === 'IESR'} />
                                <label htmlFor="iesr" className="ml-2">IESR</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton inputId="etablissement" name="typeStructure" value="ETABLISSEMENT" onChange={(e) => this.onTypeStructureChange(e.value)} checked={this.state.typeStructure === 'ETABLISSEMENT'} />
                                <label htmlFor="etablissement" className="ml-2">ETABLISSEMENT</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton inputId="departement" name="typeStructure" value="DEPARTEMENT" onChange={(e) => this.onTypeStructureChange(e.value)} checked={this.state.typeStructure === 'DEPARTEMENT'} />
                                <label htmlFor="departement" className="ml-2">DEPARTEMENT</label>
                            </div>
                        </div>

                        {this.state.submitted && !this.state.typeStructure && <small className="p-invalid" style={{color : "red"}}>Choisir le type de structure de rattachement.</small>}

                    </div>

                    {(this.state.typeStructure !== "IESR" && this.state.typeStructure!=="") && (
                        <div className="p-grid">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <hr/>
                            </div>
                        </div>
                    ) }

                    {(this.state.typeStructure && this.state.typeStructure !== "IESR" && this.state.typeStructure!=="") && 
                    
                    (
                    (this.state.typeStructure === "ETABLISSEMENT") ?
                    (
                        <div className="p-grid">
                            <div className="p-col-12 p-md-12 p-lg-12">
                            
                                <div className="p-field">
                                <label htmlFor="idEtablissement"><span style={{color:'red'}}>*</span> Choisir l'établissement de rattachement</label>
                                    <br/>
                                    <Dropdown
                                        optionLabel="nom"
                                        optionValue="idEtablissement"
                                        value={this.state.curentEtablissement}
                                        options={this.state.listeEtablissement}
                                        onChange={(e) => {
                                            this.onEtablissementChange(e);
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid":
                                                this.state.submitted,
                                        })}
                                        placeholder="Selectionner l'établissement"
                                    />
                                    {this.state.submitted && this.state.typeStructure==="ETABLISSEMENT" && !this.state.curentEtablissement  && <small className="p-invalid" style={{color : "red"}}>Choisir l'établissement concerné.</small>}
                                </div>
                            </div>
                        </div>
                    )



                    :(
                        
                        <div className="p-grid">
                            <div className="p-md-12 p-sm-12 p-xs-12 p-lg-6">
                            
                                <div className="p-field">
                                <label htmlFor="idEtablissement"><span style={{color:'red'}}>*</span> Choisir l'établissement de rattachement</label>
                                    <br/>
                                    <Dropdown
                                        optionLabel="nom"
                                        optionValue="idEtablissement"
                                        value={this.state.curentEtablissement}
                                        options={this.state.listeEtablissement}
                                        onChange={(e) => {
                                            this.onEtablissementChange(e);
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid":
                                                this.state.submitted,
                                        })}
                                        placeholder="Selectionner l'établissement"
                                    />
                                    {this.state.submitted && this.state.typeStructure==="DEPARTEMENT" && !this.state.curentEtablissement  && <small className="p-invalid" style={{color : "red"}}>Choisir l'établissement concerné.</small>}
                                </div>
                            </div>




                            <div className="p-md-12 p-sm-12 p-xs-12 p-lg-6">
                            
                                <div className="p-field">
                                <label htmlFor="idDepartement"><span style={{color:'red'}}>*</span> Choisir le département</label>
                                    <br/>
                                    <Dropdown
                                        optionLabel="lbDepartementEtab"
                                        optionValue="idDepartement"
                                        value={this.state.curentDepartement}
                                        options={this.state.listeDepartement}
                                        onChange={(e) => {
                                            this.onDepartementChange(e);
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid":
                                                this.state.submitted,
                                        })}
                                        placeholder="Selectionner le departement"
                                    />
                                    {this.state.submitted && this.state.typeStructure==="DEPARTEMENT" && !this.state.curentDepartement  && <small className="p-invalid" style={{color : "red"}}>Choisir le departement de rattachement.</small>}
                                </div>
                            </div>



                        </div>
                        )
                    )
                    
                
                }





               
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <hr/>
                                <h5>Type utilisateur</h5>
                            <hr/>
                        </div>
                    </div>

                    


                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="flex align-items-center">
                                <RadioButton inputId="Enseignant" name="typeUtilisateur" value="Enseignant" onChange={(e) => this.onTypeUtilisateurChange(e.value)} checked={this.state.typeUtilisateur === 'Enseignant'} />
                                <label htmlFor="Enseignant" className="ml-2">Enseignant</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton inputId="NonEnseignant" name="typeUtilisateur" value="NonEnseignant" onChange={(e) => this.onTypeUtilisateurChange(e.value)} checked={this.state.typeUtilisateur === 'NonEnseignant'} />
                                <label htmlFor="NonEnseignant" className="ml-2">Non enseignant</label>
                            </div>
                            {this.state.submitted && !this.state.typeUtilisateur && <small className="p-invalid" style={{color : "red"}}>Choisir le type d'utilisateur.</small>}
                        </div>

                    </div>

                   

                    {(this.state.typeUtilisateur) && (
                        <div className="p-grid">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <hr/>
                            </div>
                        </div>
                    )}
                    


                    {this.state.typeUtilisateur && (
                    
                    
                        (this.state.typeUtilisateur ==="Enseignant")? ( 
                        <div className="p-grid">
                            <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                                <div className="p-field">
                                    <label htmlFor="matricule"><span style={{color:'red'}}>*</span> Matricule</label>
                                    <InputText id="matricule" value={this.state.newUser.matricule || ""}  onChange={(e) => this.onUserDataChange(e, 'matricule')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && (!this.state.newUser.matricule || this.state.newUser.matricule.length<3) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit contenir au moins 3 caractères.</small>}
                                </div>
                            </div>
                            <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>

                                <div className="p-field">
                                    <label htmlFor="numeroTelephone"><span style={{color:'red'}}>*</span> Numéro téléphone</label>
                                    <PhoneInput
                                        id="numeroTelephone"
                                        value={this.state.newUser.numeroTelephone}
                                        defaultCountry="BF"
                                        limitMaxLength="8"
                                        onChange={(value) =>
                                        this.onPhoneChange(
                                            value
                                        )} />

                                {this.state.submitted && (!this.state.newUser.numeroTelephone || this.state.newUser.numeroTelephone.length<8) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit contenir au moins 8 chiffres.</small>}
                                </div>


                            </div>
                        </div>
                        ):(

                            <div className="p-grid">
                                
                                <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>

                                    <div className="p-field">
                                        <label htmlFor="numeroTelephone"><span style={{color:'red'}}>*</span> Numéro téléphone</label>
                                        <PhoneInput
                                            id="numeroTelephone"
                                            value={this.state.newUser.numeroTelephone}
                                            defaultCountry="BF"
                                            limitMaxLength="8"
                                            onChange={(value) =>
                                            this.onPhoneChange(
                                                value,
                                                "telephone"
                                            )} />

                                        {this.state.submitted && (!this.state.newUser.numeroTelephone || this.state.newUser.numeroTelephone.length<8) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit contenir au moins 8 chiffres.</small>}
                                    </div>


                                </div>
                            </div>
                        )
                    )


                    
                
                }


                <div className="p-grid">
                    <div className="p-col-12 p-md-12 p-lg-12">
                        <hr/>
                            <h5>Choix des rôles</h5>
                        <hr/>
                    </div>
                </div>


                <PickList source={this.state.rolesDisponible} target={this.state.choiseRoles} itemTemplate={this.itemTemplate}
                onChange={this.onRolesChose}  sourceHeader="Roles disponible" targetHeader="Roles selectionnes"
                sourceStyle={{ width : '400px' }} targetStyle={{ width : '400px' }}
                />

                {this.state.submitted && this.state.choiseRoles.length<1 && <small className="p-invalid" style={{color : "red"}}>Choisir au moins 1 rôle!</small>}


                        
                </Dialog>

                <Dialog visible={this.state.deleteUserDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteUserDialogFooter} onHide={this.hideDeleteUserDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.newUser && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>



                <Dialog visible={this.state.updatePasswordDialog} style={{ width: '80%' }} header="Changement de mot de passe" modal className="p-fluid" footer={passwordChangeFooter} onHide={this.hideChangePasswordDialog}>
                <hr/>
                    
                    <div className="p-grid">
                        <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="identifiant">Nom d'utilisateur</label>
                                <InputText id="identifiant" value={this.state.newUser.identifiant || ""}  onChange={(e) => this.onUserDataChange(e, 'identifiant')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} disabled />
                            </div>
                        </div>
                        <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="adresseEmail"> Adresse mail</label>
                                <InputText id="adresseEmail" type='email' value={this.state.newUser.adresseEmail || ""}  onChange={(e) => this.onUserDataChange(e, 'adresseEmail')} required className={classNames({ 'p-invalid': this.state.submitted })} disabled/>
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="nom">Nom </label>
                                <InputText id="nom" value={this.state.newUser.nom || ""}  onChange={(e) => this.onUserDataChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} disabled />
                            </div>
                        </div>
                        <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="prenom">Prénom(s)</label>
                                <InputText id="prenom" value={this.state.newUser.prenom || ""}  onChange={(e) => this.onUserDataChange(e, 'prenom')} required className={classNames({ 'p-invalid': this.state.submitted })} disabled/>
                                
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className='p-md-12 p-sm-12 p-xs-12 p-lg-12'>
                            <hr/>
                            Preciser le mot de passe et confirmer puis valider
                            <hr/>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="password"><span style={{color:'red'}}>*</span> Mot de passe</label>
                                <InputText id="password" type='password' value={this.state.newUser.password || ""}  onChange={(e) => this.onUserDataChange(e, 'password')} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Mot de passe' />
                                {this.state.submitted && (!this.state.newUser.password || this.state.newUser.password.length<6) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire doint contenir au moins 6 caractères.</small>}
                            </div>
                        </div>

                        <div className='p-md-12 p-sm-12 p-xs-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="passwordConfirm"><span style={{color:'red'}}>*</span> Confirmer le mot de passe</label>
                                <InputText id="passwordConfirm" type='password' value={this.state.passwordConfirmation || ""}  onChange={(e) => this.onPasswordConfirm(e)} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Confirmation mot de passe' />
                                {this.state.submitted && (!this.state.passwordConfirmation || this.state.passwordConfirmation!==this.state.newUser.password) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit etre identique au mot de passe.</small>}
                            </div>
                        </div>
                    </div>
                </Dialog>






            </div>
        )
    }
}



export default Utilisateurs
