import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../../components/Spinner";
import { Notify } from "../../../components/Notify";
import { CohorteServices } from "../../../volumeHoraire/service/CohorteServices";
import { AnneeService } from "../../service/AnneeService";
import { ParcoursFiliereService } from "../../service/ParcoursFiliereService";
import { SessionExamenService } from "../../service/SessionExamenService";
import { NotationService } from "../../service/NotationService";
import { ImportInscruptionService } from "../../service/ImportInscriptionService";
import { NiveauService } from "../../service/NiveauService";

function ImportInscriptionENS() {

    const spinner = new Spinner();
    const notify = new Notify();
    const parcoursDepartementService = new ParcoursFiliereService();
    const cohorteServices = new CohorteServices();
    const anneeService = new AnneeService();
    const sessionService = new SessionExamenService();
    const notationservice = new NotationService();
    const importInscriptionService = new ImportInscruptionService();
    const niveauService = new NiveauService()

    const campusScolarite = localStorage.getItem("campusScolarite");
    const campusDepartement = localStorage.getItem("campusDepartement");
    const isScolarite = parseInt(JSON.parse(campusScolarite));
    const isDepartement = parseInt(JSON.parse(campusDepartement));



    const [token, setToken] = useState();
    const [annee, setAnnee] = useState();
    const [annees, setAnnees] = useState([]);
    const [parcour, setParcour] = useState();
    const [parcours, setParcours] = useState([]);
    const [niveau, setNiveau] = useState();
    const [listeNiveau, setListeNiveau] = useState([]);
    const [typeStagiaires, setTypeStagiaires] = useState([]);
    const [typeStagiaire, setTypeStagiaire] = useState();
    const [listeParcours, setListeParcours] = useState([])
    const [submitted, setSubmitted] = useState(false);
    const [libelleStage, setLibelleStage] = useState(); 
    const [loadingChargement, setLoadingChargement] = useState(false)
    const [bool, setBool] = useState(false)
    const [typeStage, setTypeStage] = useState();
    const [nameFichier, setNameFichier] = useState();
    const [file, setFile] = useState();
    const [fichierDate, setFicherDate] = useState();
    const [nameUser, setNameUser] = useState();
    const [verified, setVerified] = useState(false)
    const [departement, setDepartement] = useState()
    const [listeDepartement, setListeDepartement] = useState([])
    


    useEffect(() => {

        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        setToken(token);

        if (isDepartement) {
            parcoursDepartementService
                .getListeParcoursDepartement(token.code_departement)
                .then((data) => {
                   // this.setState({ listeParcours: data });
                   setParcours(data);
                })
                .catch((error) => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                });
        } else if (isScolarite) {
            cohorteServices
                .getListeDepartementEtab(token.code_etablissement)
                .then((data) => {
                    //console.log(data)
                    setListeDepartement(data.departementEtabDtos)
                    /*this.setState({
                        listeDepartement: data.departementEtabDtos,
                    });*/
                })
                .catch((error) => {
                    console.log(error);
                    //notify.echec(error.response.data.message);
                });
        }
        /*parcoursDepartementService
            .getListeParcoursDepartement(token.code_departement)
            .then((data) => {
                setParcours(data);
                //console.log(data)
            })*/
           

        anneeService.getAll().then((data) => {
            setAnnees(data)
            //console.log(data)

          })

        //niveauService.


        importInscriptionService
            .getTypeStagiaire()
            .then((data) => {
                setTypeStagiaires(data)
                console.log(data)
            })

        
    }, [])


    const onFileChange = (event) => {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.split(".").pop();
            let ext = extension.toUpperCase();
            if (ext === "CSV") {
                setNameFichier(file.name)
                const reader = new FileReader();
                reader.onload = (readerEvt) => {
                    let binaryString = readerEvt.target.result;
                    let file = btoa(binaryString);
                    setFile(file);
                    setNameUser("osaidoupro@gmail.com")
                    setFicherDate("21-03-2021")
                };
                reader.readAsBinaryString(file);
            }
        }
    };

    const saveImportFichier = () => {


        let idDepartement;
        if (isDepartement)
            idDepartement = token.code_departement;
        else if (isScolarite)
            idDepartement = departement.idDepartementEtab;
        let idParcours = parcour.idParcours;
        let idAnneeAcademique = annee.idAnneeAcademique;
        let idNiveau = niveau.idNiveau;
        let idTypeStagiaire = typeStagiaire.idTypeStagiaire;
        let nomFichier = nameFichier;
        let fichier = file;
        let username = nameUser;
        let dateFichier = fichierDate ;
        let verifier = verified;
        //this.state.departement ?idDepartement = this.state.departement.idDepartementEtab : idDepartement = this.state.token.code_departement
        let donneeFichier = {
            nomFichier: nomFichier,
            fichier: fichier,
            idDepartement: idDepartement,
            idParcours: idParcours,
            idAnneeAcademique: idAnneeAcademique,
            idNiveau: idNiveau,
            idTypeStagiaire : idTypeStagiaire,
            username: username,
            dateFichier: dateFichier,
            verifier: verifier,
        };
        console.log(donneeFichier)
        setLoadingChargement(true);
        importInscriptionService
            .chargementFichier(donneeFichier)
            .then((data) => {
                    setLoadingChargement(false)
                    setBool(true)
                    setSubmitted(true)
            })
            .catch((error) => {
                notify.echec(error.response.data.message);
                console.log(error);
                setLoadingChargement(false)
                setBool(false)
                setSubmitted(true)               
            });
    };

    const onChampsChange = (e, field) => {
        const val = (e.target && e.target.value) || "";

        if (field === "departement") {
            setDepartement(val);
           parcoursDepartementService
                .getListeParcoursDepartement(val.idDepartementEtab)
                .then((data) => {
                    console.log(data)
                    setListeParcours(data);
                })
                .catch((error) => console.log(error));
        }

        if (field === "parcours") {
            setParcour(val);
            
            niveauService.getListeNiveauByIdParcours(val.idParcours).then(response => {

                console.log(response)
                setListeNiveau(response);
                //setNiveau()
                
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })

        }
        
        else if (field === "annee") setAnnee(val);
        else if (field === "niveau") setNiveau(val);
        else if (field === "typeStagiaire") setTypeStagiaire(val);
        else;

        
    };

    

    return(
        <div>
            

                <div
                    className="card p-lg-10"
                    style={{ marginLeft: "10%", marginTop: "5%" }}
                >

              
                    <div className="p-grid p-lg-12" style={{ marginTop: "3%" }}>
                        <div className="p-lg-12">
                            <h3
                                style={{
                                    textDecoration: "underline",
                                    textAlign: "center",
                                }}
                            >
                                <a
                                    href="/assets/layout/fichiers/caneva_incription_administrative.csv"
                                    target="_blank"
                                >
                                    Cliquer ici pour telecharger le canevas des
                                    inscriptions
                                </a>
                            </h3>
                        </div>
                        
                        {isScolarite ? (
                            <>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: "20%" }}
                                >
                                    <Dropdown
                                        optionLabel="lbDepartementEtab"
                                        value={departement}
                                        options={listeDepartement}
                                        onChange={(e) => {
                                            onChampsChange(e, "departement")
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner un département"
                                    />
                                    {submitted &&
                                            !departement && (
                                                <small
                                                    className="p-invalid"
                                                    style={{ color: "red" }}
                                                >
                                                    Selectionner un departement.
                                                </small>
                                            )}
                                    <Dropdown
                                        optionLabel="libelleParcours"
                                        //optionValue={parcours}
                                        value={parcour}
                                        options={listeParcours}
                                        onChange={(e) => {
                                            onChampsChange(e, "parcours");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner un parcours"
                                    />
                                    {submitted &&
                                        !parcours && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un parcours.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleAnnee" 
                                        optionValue={annee}
                                        value={annee}
                                        options={annees}
                                        onChange={(e) => {
                                            onChampsChange(e, "annee");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner une année"
                                    />
                                    {submitted &&
                                        !annee && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner une année.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleNiveau"
                                        optionValue={niveau}
                                        value={niveau}
                                        options={listeNiveau}
                                        onChange={(e) => {
                                            onChampsChange(e, "niveau");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner un niveau"
                                    />
                                    {submitted &&
                                        !niveau && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un niveau.
                                            </small>
                                        )}
                                </div>

                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleTypeStagiaire"
                                        optionValue={typeStagiaire}
                                        value={typeStagiaire}
                                        options={typeStagiaires}
                                        onChange={(e) => {
                                            onChampsChange(e, "typeStagiaire");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner type stagiare"
                                    />
                                    {submitted &&
                                        !niveau && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner type stagiare.
                                            </small>
                                        )}
                                </div>
                            </>
                        )
                        : isDepartement ? (
                        <>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: "20%" }}
                                >
                                    <Dropdown
                                        optionLabel="libelleParcours"
                                        optionValue={parcours}
                                        value={parcour}
                                        options={parcours}
                                        onChange={(e) => {
                                            onChampsChange(e, "parcours");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner un parcours"
                                    />
                                    {submitted &&
                                        !parcours && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un parcours.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleAnnee" 
                                        optionValue={annee}
                                        value={annee}
                                        options={annees}
                                        onChange={(e) => {
                                            onChampsChange(e, "annee");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner une année"
                                    />
                                    {submitted &&
                                        !annee && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner une année.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleNiveau"
                                        optionValue={niveau}
                                        value={niveau}
                                        options={listeNiveau}
                                        onChange={(e) => {
                                            onChampsChange(e, "niveau");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner un niveau"
                                    />
                                    {submitted &&
                                        !niveau && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un niveau.
                                            </small>
                                        )}
                                </div>

                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleTypeStagiaire"
                                        optionValue={typeStagiaire}
                                        value={typeStagiaire}
                                        options={typeStagiaires}
                                        onChange={(e) => {
                                            onChampsChange(e, "typeStagiaire");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner type stagiare"
                                    />
                                    {submitted &&
                                        !niveau && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner type stagiare.
                                            </small>
                                        )}
                                </div>
                            </>
                        ):
                        (
                            ""
                        )}
                            <div className="p-lg-12" style={{ marginTop: 10 }}>
                        <div
                            className="p-lg-12 p-grid"
                            style={{ marginTop: 10, marginLeft: "10%" }}
                        >
                            <div
                                className="p-lg-12 p-grid"
                                style={{ marginLeft: "0%" }}
                            >
                                <div
                                    className="p-lg-4"
                                    style={{ marginTop: -20 }}
                                >
                                    <input
                                        type="file"
                                        style={{ marginLeft: "0%" }}
                                        onChange={(e) => {
                                            onFileChange(e);
                                        }}
                                    />
                                </div>
                                {loadingChargement ? (
                                    <div className="p-lg-2">
                                        {spinner.loadingSpinner(
                                            "spin",
                                            "black"
                                        )}
                                    </div>
                                ) : (
                                    <div className="p-lg-2">
                                        {submitted &&
                                            bool && (
                                                <span
                                                    style={{ color: "green" }}
                                                >
                                                    Chargement réussi !
                                                </span>
                                            )}
                                        {submitted &&
                                            !bool && (
                                                <span style={{ color: "red" }}>
                                                    Echec de chargement !
                                                </span>
                                            )}
                                    </div>
                                )}
                                <div
                                    className="p-lg-4"
                                    style={{ marginTop: -20 }}
                                >
                                    <Button
                                        style={{ marginLeft: "0%" }}
                                        label="Valider le chargement"
                                        onClick={saveImportFichier}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
                </div>
        </div>
    )

}

export default ImportInscriptionENS