import React, { useState, useEffect, useRef } from 'react'
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../components/Spinner";
import { Notify } from "../../components/Notify";
import { DepartementService } from "../service/DepartementService";
import { CohorteServices } from "../../volumeHoraire/service/CohorteServices";
import { ParcoursFiliereService } from "../service/ParcoursFiliereService";
import { AnneeService } from "../service/AnneeService";
import { SemestreService } from "../service/SemestreService";
import { NiveauService } from "../service/NiveauService";
import { DeliberationService } from '../service/DeliberationService';


function ListeAdmis() {

    const emptyEtudiant = {
        idEtudiant: null,
        ine: null,
        nom: null,
        prenom: null,
        sexe: null,
        dateNaissance: null,
        lieuNaissance: null,
        email: null,
        telephone: null,
    };

    const notify = new Notify();
    const spinner = new Spinner();

    const cohorteServices = new CohorteServices();
    const parcoursDepartementService = new ParcoursFiliereService();
    const anneeService = new AnneeService();
    const deliberationService = new DeliberationService();
    const niveauService = new NiveauService();

    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded)
    const campusScolarite = localStorage.getItem("campusScolarite");
    const campusDepartement = localStorage.getItem("campusDepartement");
    const isScolarite = parseInt(JSON.parse(campusScolarite));
    const isDepartement = parseInt(JSON.parse(campusDepartement));

    const [etudiants, setEtudiants] = useState([]);
    const [etudiant, setEtudiant] = useState(emptyEtudiant);
    const [annees, setAnnees] = useState([]);
    const [annee, setAnnee] = useState(null);
    const [departemeents, setDepartements] = useState([]);
    const [departemeent, setDepartement] = useState(null);
    const [parcours, setParcours] = useState([]);
    const [parcour, setParcour] = useState(null);
    const [niveaus, setNiveaus] = useState([]);
    const [niveau, setNiveau] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [loadingChargement, setLoadingChargement] = useState(false);
    const [selectedEtudiants, setSelectedEtudiants] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null)
    
    const dt = useRef(null);

    const cols = [
        { field: "ine", header: "INE étudiant" },
        { field: "nom", header: "Nom étudiant" },
        { field: "prenom", header: "Prénom(s) étudiant" },
        { field: "sexe", header: "Sexe" },
        { field: "dateNaissance", header: "Date de naiss." },
        { field: "lieuNaissance", header: "Lieu de naiss." },
    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));

    useEffect( () => {
        if(isDepartement){
            parcoursDepartementService.getListeParcoursDepartement(token.code_departement).then(data => {
                setParcours(data);
            })
            .catch(error => {
                console.log(error);
                notify.echec(error.response.data.message);
            })
            anneeService.getAll().then(data => {
                //console.log(data)
                setAnnees(data)
            })
            .catch(error => {
                console.log(error);
                notify.echec(error.response.data.message);
            })
        }
    }, []);

    const listeAdmis = () => {
        setLoadingChargement(true)
        if(isDepartement){
            deliberationService.getListeAdmisAnnee(annee.idAnneeAcademique,token.code_departement, parcour.idParcours, niveau.idNiveau, "admis").then(data => {
                console.log(data)
                setEtudiants(data)
                setLoadingChargement(false)
            })
            .catch(error => {
                console.log(error)
                notify.echec("Echec de l'opération")
                setLoadingChargement(false)
            })
        }
    }

    const listeAjournees = () => {
        setLoadingChargement(true)
        if(isDepartement){
            deliberationService.getListeAdmisAnnee(annee.idAnneeAcademique,token.code_departement, parcour.idParcours, niveau.idNiveau, "ajourne").then(data => {
                console.log(data)
                setEtudiants(data)
                setLoadingChargement(false)
            })
            .catch(error => {
                console.log(error)
                notify.echec("Echec de l'opération")
                setLoadingChargement(false)
            })
        }
    }

    const exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default("l");

                /*const title =
                    this.state.departement.lbDepartementEtab +
                    "_" +
                    this.state.parcours.libelleParcours +
                    "_" +
                    this.state.annee.libelleAnnee +
                    "_" +
                    this.state.niveau.libelleNiveau +
                    "_" +
                    this.state.semestre.libelleSemestre;*/
                /*doc.text(
                    "Département : " + this.state.departement.lbDepartementEtab,
                    10,
                    10
                );
                doc.text(
                    "Parcours : " + this.state.parcours.libelleParcours,
                    10,
                    20
                );
                doc.text("Niveau : " + this.state.niveau.libelleNiveau, 10, 30);
                doc.text(
                    "Semestre : " + this.state.semestre.libelleSemestre,
                    10,
                    40
                );
                doc.text("Liste des étudiants", 50, 50);*/

                doc.autoTable(exportColumns, etudiants, {
                    margin: { top: 60 },
                });
                paginationPage(doc);
                doc.save("title" + ".pdf");
            });
        });
    }
    const paginationPage = (doc) => {
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.text(
                "" + String(i) + "/" + String(pageCount),
                290,
                200,
                null,
                null,
                "right"
            );
        }
    };

    const exportExcel = () => {
        //const listeEtudiant = this.state.listeEtudiant;
        /*const title =
            this.state.departement.lbDepartementEtab +
            "_" +
            this.state.parcours.libelleParcours +
            "_" +
            this.state.annee.libelleAnnee +
            "_" +
            this.state.niveau.libelleNiveau +
            "_" +
            this.state.semestre.libelleSemestre;*/
        let donneeExentiel = etudiants.map((d) => ({
            "Ine étudiant": d.ine,
            Nom: d.nom,
            Prenom: d.prenom,
            Sexe: d.sexe,
            "Date naiss.": d.dateNaissance,
            "lieu naiss.": d.lieuNaissance,
        }));
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(donneeExentiel);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "title");
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((module) => {
            let EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            module.default.saveAs(
                data,
                fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
            );
        });
    };

    const onChampsChange = (e, field) => {
        const val = (e.target && e.target.value) || "";

        if (field === "departement") {
            setDepartement(val)
            parcoursDepartementService
                .getListeParcoursDepartement(val.idDepartementEtab)
                .then((data) => {
                    setParcours(data)
                })
                .catch((error) => console.log(error));
        }

        if (field === "parcour") {
            setParcour(val)

        } else if (field === "annee") {
            setAnnee(val)
            niveauService.getListeNiveauByIdParcours(parcour.idParcours).then(response => {
                console.log(response)
                setNiveaus(response)
                
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })
        } else if (field === "niveau") {
            setNiveau(val)
        } 
    };

  return (
    <div>
        <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
        />
         <div
                    className="card p-lg-10"
                    style={{ marginLeft: "10%", marginTop: "5%" }}
        >
        { isDepartement ? (
                            <>
                            <div className='p-col-12 p-grid' style={{marginTop:10}}>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: "20%" }}
                                >
                                    <Dropdown
                                        optionLabel="libelleParcours"
                                        value={parcour}
                                        options={parcours}
                                        onChange={(e) => {
                                            onChampsChange(e, "parcour");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner un parcours"
                                    />
                                    {submitted &&
                                        !parcour && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un parcours.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleAnnee"
                                        value={annee}
                                        options={annees}
                                        onChange={(e) => {
                                            onChampsChange(e, "annee");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner une année"
                                    />
                                    {submitted &&
                                        !annee && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner une année.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleNiveau"
                                        value={niveau}
                                        options={niveaus}
                                        onChange={(e) => {
                                            onChampsChange(e, "niveau");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": submitted,
                                        })}
                                        placeholder="Selectionner un niveau"
                                    />
                                    {submitted &&
                                        !niveau && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un niveau.
                                            </small>
                                        )}
                                </div>
                                
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Button
                                        style={{ marginLeft: "0%" }}
                                        label="Admis"
                                        onClick={listeAdmis}
                                    />
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Button
                                        style={{ marginLeft: "0%" }}
                                        label="Ajournés"
                                        onClick={listeAjournees}
                                    />
                                </div>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                        {loadingChargement ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div
                                className="p-d-flex p-ai-center export-buttons"
                                style={{ marginLeft: 10, marginTop: 20 }}
                            >
                                <Button
                                    title="Export en excel"
                                    type="button"
                                    icon="pi pi-file-excel"
                                    onClick={exportExcel}
                                    style={{ marginLeft: 10 }}
                                    className="p-button-success p-mr-2"
                                    data-pr-tooltip="XLS"
                                />
                                <Button
                                    title="Export en pdf"
                                    type="button"
                                    icon="pi pi-file-pdf"
                                    onClick={exportPdf}
                                    style={{ marginLeft: 10 }}
                                    className="p-button-warning p-mr-2"
                                    data-pr-tooltip="PDF"
                                />
                            </div>
                            <div className="p-lg-12" style={{ marginTop: 10 }}>
                                <DataTable
                                    ref={dt} value={etudiants} selection={selectedEtudiants} onSelectionChange={(e) => setSelectedEtudiants(e.value)}
                                    header={
                                        "Liste des étudiants (" +
                                        etudiants.length +
                                        ")"
                                    }
                                    paginator={true}
                                    rows={10}
                                    globalFilter={globalFilter}
                                    filterMatchMode="contains"
                                    responsive={true}
                                >
                                    <Column
                                        field="ine"
                                        header="INE"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher un ine"
                                    />
                                    <Column
                                        field="nom"
                                        header="Nom"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un nom"
                                    />
                                    <Column
                                        field="prenom"
                                        header="Prénom"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un prénom"
                                    />
                                    <Column
                                        field="sexe"
                                        header="Sexe"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher par sexe"
                                    />
                                    <Column
                                        field="dateNaissance"
                                        header="date de naiss"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher date de naiss."
                                    />
                                    <Column
                                        field="lieuNaissance"
                                        header="Lieu de naiss."
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher par lieu de naiss."
                                    />
                                </DataTable>
                            </div>
                        </>
                    )}
            </div>
    </div>
  )
}

export default ListeAdmis