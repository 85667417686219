import React, { Component } from 'react'
import { RadioButton } from 'primereact/radiobutton';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { UEService } from '../../service/UEService';
import { TypeUEService } from '../../service/TypeUEService';
import { Dropdown } from 'primereact/dropdown';
import { ECService } from '../../service/ECService';


export class UniteEnseignement extends Component {

    listeOptionUE = [
        {
            idOption: 0,
            libelleOption: 'Option sur les EC'
        },
        {
            idOption: 1,
            libelleOption: 'Option sur l\'UE '
        }
    ]

    constructor() {
        super();
        this.state = {
            listeUE : [],
            listeTypeUE: [],
            listeEC: [],
            ueDialog: false,
            searchECDialog: false,
            listeResultEC: [],
            deleteUEDialog: false,
            ue: {
                idUniteEnseignement: null,
                libelleUniteEnseignement: null,
                creditUniteEnseignement : null,
                coefficient : null,
                codeUniteEnseignement : null,
                typeUniteEnseignement: null,
                ordreUniteEnseignement: null,
                nbEcObligatoire: null,
                ueOptionnel: 0
            },
            objetRecherche: {
                codeElementConstitutif: '',
                libelleElementConstitutif: ''
            },
            curentOption: null,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null,
            ueOptionnel: false,
            ecOption: false,
            ordreFils: 0,
            listeValidateur: this.listeValidateur,
            expandedRows: null,
            curentEC: {
                idElementConstitutif: '',
                idUniteEnseignement: '',
                libelleElementConstitutif: '',
                codeElementConstitutif: '',
                creditElementConstitutif: '',
                nbHeureElementConstitutif: '',
                nbHeureTD: '',
                nbHeureTP: '',
                nbHeureCours: '',
                nbHeureTravauxPersonnel: '',
                ordreElementConstitutif: ''
            },
            isEditEC: false,
            rowIndex: null,
            isEditUE: false,
            listeOptionUE: this.listeOptionUE,
            erreurTypeUE: "",
            idDepartement: null,
            isChoixCaluclNote : false
        }

        this.ueService = new UEService()
        this.typeUEService = new TypeUEService()
        this.ecService = new ECService()
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveUE = this.saveUE.bind(this);
        this.editUE = this.editUE.bind(this);
        this.confirmDeleteUE = this.confirmDeleteUE.bind(this);
        this.deleteUE = this.deleteUE.bind(this);
        this.onLibelleUEChange = this.onLibelleUEChange.bind(this);
        this.hideDeleteUEDialog = this.hideDeleteUEDialog.bind(this);
        this.onNbEcChange = this.onNbEcChange.bind(this)
        this.onOrdreUEChange = this.onOrdreUEChange.bind(this)
        this.onChildInputChange = this.onChildInputChange.bind(this)

        this.ecTable = React.createRef()
    }

    componentDidMount() {


        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)

        this.setState({
            idDepartement: token.code_departement
        })

        this.setState({loading : true})
        this.ueService.getAll(token.code_departement).then(data => {
            this.setState({listeUE: data, loading : false})
        })
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({loading : false})
        })

        this.typeUEService.getAll().then(data => this.setState({ listeTypeUE: data})).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
        })
    }

    openNew() {
    
        this.setState({
            ue: {
                idUniteEnseignement: null,
                libelleUniteEnseignement: null,
                creditUniteEnseignement : null,
                codeUniteEnseignement : null,
                typeUniteEnseignement: null,
                ordreUniteEnseignement: null,
                nbEcObligatoire: null,
                isChoixCaluclNote : false
            },
            submitted: false,
            ueDialog: true
        })
    }

    rechercherEC = () => {
        this.setState({
            searchECDialog: true
        })
    }

    hideDialog() {

        this.setState({
            submitted: false,
            ue: {
                idUniteEnseignement: null,
                libelleUniteEnseignement: null,
                creditUniteEnseignement : null,
                coefficient : null,
                codeUniteEnseignement : null,
                typeUniteEnseignement: null,
                ordreUniteEnseignement: null,
                nbEcObligatoire: null,
                isChoixCaluclNote : false
            },
            listeEC: [],
            ueDialog: false,
            isEditEC: false,
            rowIndex: null,
            isEditUE: false,
            curentOption: null,
            ecOption: false,
            ueOptionnel: false,
            curentEC: {
                idElementConstitutif: '',
                idUniteEnseignement: '',
                libelleElementConstitutif: '',
                codeElementConstitutif: '',
                creditElementConstitutif: '',
                nbHeureElementConstitutif: '',
                nbHeureTD: '',
                nbHeureTP: '',
                nbHeureCours: '',
                nbHeureTravauxPersonnel: '',
                ordreElementConstitutif: ''
            }
        });
    }









    hideDeleteUEDialog() {
        this.setState({ deleteUEDialog: false });
    }

    saveUE() {

        this.setState({
            submitted: true
        })

        if (
            this.state.ue.codeUniteEnseignement!==null 
            && this.state.ue.creditUniteEnseignement!==null 
            && this.state.ue.creditUniteEnseignement!==0 
            && this.state.ue.libelleUniteEnseignement 
            && this.state.ue.libelleUniteEnseignement.trim().length>=2 
            && this.state.ue.ordreUniteEnseignement!==null 
            && this.state.ue.typeUniteEnseignement 
            && this.state.ue.typeUniteEnseignement.idTypeUniteEnseignement!==null 
            && this.state.listeEC.length>0 && this.state.erreurTypeUE===""
        ) {

            //Si l'UE est libre ou optionnel et que le nombre de choix n'est pas selectionné, on ne laisse pas passer
            if(
                (this.state.ue.typeUniteEnseignement.idTypeUniteEnseignement===4 && this.state.ue.nbEcObligatoire!==null) 
                || this.state.ue.typeUniteEnseignement.idTypeUniteEnseignement!==4 
                || (this.state.ue.typeUniteEnseignement.idTypeUniteEnseignement===4 
                && this.state.ue.ueOptionnel===1)
            )
            {

                //On constitue la liste des EC enfant de l'UE
                let listeEC = this.state.listeEC
           
                let newUE = {
                    idUniteEnseignement : parseInt(this.state.ue.idUniteEnseignement) ,
                    libelleUniteEnseignement : this.state.ue.libelleUniteEnseignement,
                    codeUniteEnseignement : this.state.ue.codeUniteEnseignement,
                    creditUniteEnseignement : parseFloat(this.state.ue.creditUniteEnseignement),
                    coefficient : this.state.ue.isChoixCaluclNote? parseFloat(this.state.ue.coefficient) : null,
                    typeUniteEnseignement : this.state.ue.typeUniteEnseignement,
                    ordreUniteEnseignement: parseInt(this.state.ue.ordreUniteEnseignement),
                    nbEcObligatoire: parseInt(this.state.ue.nbEcObligatoire),
                    ueOptionnel: this.state.ue.ueOptionnel,
                    listeElementConstitutif: listeEC
                }

                if(this.state.idDepartement){
                    newUE['idDepartement'] = this.state.idDepartement
                }

                if(newUE.idUniteEnseignement){

                    //console.log(newUE.idUniteEnseignement, newUE)
                    this.ueService.update(newUE.idUniteEnseignement, newUE).then(data => {

                        this.notify.success()
    
                        this.setState({loading : true})
                        this.ueService.getAll(this.state.idDepartement).then(data => 
                            this.setState({
                                listeUE: data, 
                                loading : false,
                                submitted: false
                            })
                            )
                            .catch(error => {
                                this.notify.echec(error.response.data.message)
                                console.log(error)
                                this.setState({loading : false})
                            })
                    })
                    .catch(error => {
                        this.notify.echec(error.response.data.message)
                        console.log(error);
                    })

                }else{

                    this.ueService.create(newUE).then(data => {
                        this.notify.success()
    
                        this.setState({loading : true})
                        this.ueService.getAll(this.state.idDepartement).then(data => 
                            this.setState({
                                listeUE: data, 
                                loading : false,
                                submitted: false
                            })
                            )
                            .catch(error => {
                                this.notify.echec(error.response.data.message)
                                console.log(error)
                                this.setState({loading : false})
                            })
                    })
                    .catch(error => {
                        this.notify.echec(error.response.data.message)
                        console.log(error);
                    })

                }
                //Update UE

                this.setState({
                    ueDialog: false,
                    listeEC: [],
                    ue: this.state.ue,
                    isEditEC: false,
                    isEditUE: false,
                    rowIndex: null,
                    curentOption: null,
                    ecOption: false,
                    ueOptionnel: false,
                    curentEC: {
                        idElementConstitutif: '',
                        idUniteEnseignement: '',
                        libelleElementConstitutif: '',
                        codeElementConstitutif: '',
                        creditElementConstitutif: '',
                        nbHeureElementConstitutif: '',
                        nbHeureTD: '',
                        nbHeureTP: '',
                        nbHeureCours: '',
                        nbHeureTravauxPersonnel: '',
                        ordreElementConstitutif: ''
                    }

                });
            }
        }
    }

    editUE(ueData) {
        
        /*
        console.log(ueData)
        let ue = {
            idUniteEnseignement: ueData.idUniteEnseignement,
            libelleUniteEnseignement: ueData.libelleUniteEnseignement,
            codeUniteEnseignement: ueData.codeUniteEnseignement,
            creditUniteEnseignement: ueData.creditUniteEnseignement,
            coefficient : ueData.coefficient,
            isChoixCaluclNote : ueData.coefficient ? true : false,
            typeUniteEnseignement: ueData.typeUniteEnseignement,
            ordreUniteEnseignement: ueData.ordreUniteEnseignement,
            nbEcObligatoire: ueData.nbEcObligatoire
        }
        */

        /*
        //On charge charge la liste des EC avec les EC de l'unité d'enseignement
        this.ecService.getListeECUniteEnseignement(ueData.idUniteEnseignement).then(data => {
            this.setState({
                listeEC: data
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
        */
        
        this.setState({
            ue: ueData,
            ueDialog: true,
            isEditUE: true,
            ueOptionnel: ueData.ueOptionnel,
            listeEC: ueData.listeElementConstitutif
        });

        if(ueData.ueOptionnel){
            this.setState({
                ueOptionnel:true,
                curentOption:{
                    idOption: 1,
                    libelleOption: 'Option sur l\'UE '
                }
            })
        }

        if(ueData.nbEcObligatoire){
            this.setState({
                ueOptionnel: true,
                curentOption: {
                    idOption: 0,
                    libelleOption: 'Option sur les EC'
                },
                ecOption: true

            })
        }

    }


    //////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////


    confirmDeleteUE(ue) {
        this.setState({
            ue,
            deleteUEDialog: true
        });
    }

    deleteUE(idUniteEnseignement) {
        this.ueService.delete(parseInt(idUniteEnseignement)).then(data => {
            this.notify.success()
            this.setState({
                deleteUEDialog: false,
                ue: {
                    idUniteEnseignement: null,
                    libelleUniteEnseignement: null,
                    creditUniteEnseignement : null,
                    coefficient : null,
                    codeUniteEnseignement : null,
                    typeUniteEnseignement: null,
                    ordreUniteEnseignement: null,
                    nbEcObligatoire: null,
                    ueOptionnel: 0
                },
                listeEC: [],
            });

            this.setState({loading : true})
            this.ueService.getAll(this.state.idDepartement).then(data => 
                this.setState({listeUE: data, loading : false})
                )
                .catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({loading : false})
                })
        })
    }

    onLibelleUEChange(e, libelleUniteEnseignement) {
        const val = (e.target && e.target.value) || '';
        let ue = this.state.ue;
        ue[`${libelleUniteEnseignement}`] = val.toUpperCase();

        this.setState({ue });
    }
    onCodeUEChange(e, codeUniteEnseignement) {
        const val = (e.target && e.target.value) || '';
        let ue = this.state.ue;
        ue[`${codeUniteEnseignement}`] = val.toUpperCase();

        this.setState({ ue });
    }
    onCreditUEChange(e, creditUniteEnseignement) {
        let val = (e.target && e.target.value) || '';

        //On ne veut que des valeurs numeriques
        val = (val && parseFloat(val)!==NaN)? parseFloat(val): ''

        let ue = this.state.ue;
        ue[`${creditUniteEnseignement}`] = val;

        this.setState({ ue });
    }

    onCoefUEChange(e, coefficient) {
        let val = (e.target && e.target.value) || '';
        val = (val && parseFloat(val)!==NaN)? parseFloat(val): ''
        
        let ue = this.state.ue
        
        
            ue[`${coefficient}`] = val
            
    

        this.setState({
            ue
        })
    }
    onIschoixCalculChange(e, isChoixCaluclNote) {
        let val = (e.target && e.target.value) || '';

        let ue = this.state.ue

        ue[`${isChoixCaluclNote}`] = val

        this.setState({
            ue
        })
    }
    

    onOrdreUEChange(e, ordreUe){

        let val = (e.target && e.target.value) || '';
        val = (!isNaN(val))? parseInt(val) :''

        let ue = this.state.ue

        ue[`${ordreUe}`] = val

        this.setState({
            ue
        })

    }


    onChoixOption = (e) => {

        let ue = this.state.ue
        ue['ueOptionnel'] = e.target.value.idOption
        this.setState({
            curentOption: e.target.value,
            ue: ue,
            ecOption: (e.target.value.idOption===0)?true:false
        })
    }


    onChildInputChange(e, fieldName){

        //On recupère la ligne concernant l'enregistrement courant
        let curentEC = this.state.curentEC
        //Mise à jours de la valeur concernée
        curentEC[`${fieldName}`] =  e.target.value.toUpperCase()

        //On calcule par la même occasion le nombre d'heures de l'EC si l'élement modifié concerne 
        if(fieldName==='nbHeureTD' || fieldName==='nbHeureTP' || fieldName==='nbHeureCours' || fieldName==='nbHeureTravauxPersonnel'){
            let nbHeuresEC = 0
            let nbHeuresTD = (!isNaN(curentEC.nbHeureTD) && curentEC.nbHeureTD!=="")? curentEC.nbHeureTD : 0
            let nbHeuresTP = (!isNaN(curentEC.nbHeureTP) && curentEC.nbHeureTP!=="")? curentEC.nbHeureTP : 0
            let nbHeuresCours = (!isNaN(curentEC.nbHeureCours) && curentEC.nbHeureCours!=="")? curentEC.nbHeureCours : 0
            let nbHeuresTravauxPersonnels = (!isNaN(curentEC.nbHeureTravauxPersonnel) && curentEC.nbHeureTravauxPersonnel!=="")? curentEC.nbHeureTravauxPersonnel : 0
            nbHeuresEC = parseInt(nbHeuresCours) + parseInt(nbHeuresTD) + parseInt(nbHeuresTP) + parseInt(nbHeuresTravauxPersonnels)
            curentEC.nbHeureElementConstitutif = nbHeuresEC

            let val = (e.target && e.target.value) || 0;
            val = (!isNaN(val))? parseInt(val): 0
            curentEC[`${fieldName}`] = val
        
        }else 
        
        if(e.target && e.target.value && e.target.value!==""){
            //Bloc permettant de passer par defaut les valeurs de heureTD TP et autres a 0
            let nbHeuresEC = 0
            let nbHeuresTD = (!isNaN(curentEC.nbHeureTD) && curentEC.nbHeureTD!=="")? curentEC.nbHeureTD : 0
            let nbHeuresTP = (!isNaN(curentEC.nbHeureTP) && curentEC.nbHeureTP!=="")? curentEC.nbHeureTP : 0
            let nbHeuresCours = (!isNaN(curentEC.nbHeureCours) && curentEC.nbHeureCours!=="")? curentEC.nbHeureCours : 0
            let nbHeuresTravauxPersonnels = (!isNaN(curentEC.nbHeureTravauxPersonnel) && curentEC.nbHeureTravauxPersonnel!=="")? curentEC.nbHeureTravauxPersonnel : 0
            nbHeuresEC = parseInt(nbHeuresCours) + parseInt(nbHeuresTD) + parseInt(nbHeuresTP) + parseInt(nbHeuresTravauxPersonnels)
            curentEC.nbHeureElementConstitutif = nbHeuresEC

            curentEC.nbHeureTD = nbHeuresTD
            curentEC.nbHeureTP = nbHeuresTP
            curentEC.nbHeureCours = nbHeuresCours
            curentEC.nbHeureTravauxPersonnel = nbHeuresTravauxPersonnels
            curentEC.nbHeureElementConstitutif = nbHeuresEC
        }

        //On s'assure que l'on enregistre une valeur numerique pour l'ordre de l'EC
        if(fieldName==='ordreElementConstitutif'){
            let val = (e.target && e.target.value) || 0;
            val = (!isNaN(val))? parseInt(val) : 0
            curentEC[`${fieldName}`] = val 
        }

        //On s'assure que l'on enregistre une valeur numerique ou à virgule pour le crédit 
        if(fieldName==='creditElementConstitutif'){
            let val = (e.target && e.target.value) || 0;
            val = (val && parseFloat(val)!==NaN)? parseFloat(val): 0
            curentEC[`${fieldName}`] = val
        }
        
        this.setState(
            {
                curentEC: curentEC
            }
        )

    }


    onNbEcChange(e, nbEC){

        let val = (e.target && e.target.value) || '';
        val = (!isNaN(val))? val:''
        let ue = this.state.ue

        ue[`${nbEC}`] = val
        let listeEC = this.state.listeEC
        let erreurTypeUE = ""


        if(!isNaN(val) && listeEC.length>0 && ue.typeUniteEnseignement && ue.typeUniteEnseignement.idTypeUniteEnseignement===4){

            //On s'assure que les EC disponibles dans la liste vaux le nombre precisé comme nombre EC obligatoire
            if(this.state.listeEC.length>=parseInt(val)){

                //On s'assure enfin que tous les EC renseignés ont le meme credit
                let identiqueCredit = true
                let creditEC = listeEC[0].creditElementConstitutif
                listeEC.map(ec => {
                    if(parseFloat(creditEC)!==parseFloat(ec.creditElementConstitutif)){
                        identiqueCredit = false
                    }
                })

                if(identiqueCredit){
                    let creditUniteEnseignement = parseInt(val)*parseFloat(creditEC)
                    ue['creditUniteEnseignement'] = creditUniteEnseignement
                }else{
                    erreurTypeUE = "Les EC renseigné n'ont pas les mêmes crédit, bien vouloir harmoniser !"
                }
                
            }else{
                erreurTypeUE = "Le nombre d'EC renseigné est inférieur au nombre d'EC obligatoire, veuillez completer !"
            }
        }


        this.setState({
            ue,
            erreurTypeUE
        })
    }



    ajouterEC = (curentListeEC) => {
        this.setState({
            submitted: true
        })


        if( this.controleValidation(this.state.curentEC)){

            if(this.state.isEditEC){
                curentListeEC[this.state.rowIndex] = this.state.curentEC
            }else{
                //On ajoute l'element dans la liste
                curentListeEC.push(this.state.curentEC)
            }
            let ue = this.state.ue
            let erreurTypeUE = ""

            if(this.state.ue.nbEcObligatoire===null){

                //On parcours donc pour aditionner les credits
                if(this.state.ue.typeUniteEnseignement && this.state.ue.typeUniteEnseignement.idTypeUniteEnseignement===3){

                    //On s'assure enfin que tous les EC renseignés ont le meme credit
                    let identiqueCredit = true
                    let creditEC = curentListeEC[0].creditElementConstitutif
                    curentListeEC.map(ec => {
                        if(parseFloat(creditEC)!==parseFloat(ec.creditElementConstitutif)){
                            identiqueCredit = false
                        }
                    })

                    if(identiqueCredit){
                        ue['creditUniteEnseignement'] = parseFloat(creditEC)
                    }else{
                        erreurTypeUE = "Les EC renseigné n'ont pas les mêmes crédit, bien vouloir harmoniser !"
                    }




                }else{
                    let nbCreditUE = 0
                    curentListeEC.map(ec =>{
                        nbCreditUE = parseFloat(nbCreditUE) + parseFloat(ec.creditElementConstitutif)
                    })
                    ue['creditUniteEnseignement'] = nbCreditUE
                }
                

            }else{

                //On s'assure que les EC disponibles dans la liste vaux le nombre precisé comme nombre EC obligatoire
                if(this.state.listeEC.length>=parseInt(this.state.ue.nbEcObligatoire)){

                    //On s'assure enfin que tous les EC renseignés ont le meme credit
                    let identiqueCredit = true
                    let creditEC = curentListeEC[0].creditElementConstitutif
                    curentListeEC.map(ec => {
                        if(parseFloat(creditEC)!==parseFloat(ec.creditElementConstitutif)){
                            identiqueCredit = false
                        }
                    })

                    if(identiqueCredit){
                        let creditUniteEnseignement = parseInt(this.state.ue.nbEcObligatoire)*parseFloat(creditEC)
                        ue['creditUniteEnseignement'] = creditUniteEnseignement
                    }else{
                        erreurTypeUE = "Les EC renseignés n'ont pas les mêmes crédits, bien vouloir harmoniser !"
                    }
                    
                }else{
                    erreurTypeUE = "Le nombre d'EC renseigné est inférieur au nombre d'EC obligatoire, veuillez completer !"
                }

            }

            if(erreurTypeUE.length<1){
                //console.log('Bad')
                this.setState(
                    {
                        ue: ue,
                        listeEC: curentListeEC,
                        curentEC: {
                            idElementConstitutif: '',
                            idUniteEnseignement: '',
                            libelleElementConstitutif: '',
                            codeElementConstitutif: '',
                            creditElementConstitutif: '',
                            nbHeureElementConstitutif: '',
                            nbHeureTD: '',
                            nbHeureTP: '',
                            nbHeureCours: '',
                            nbHeureTravauxPersonnel: '',
                            ordreElementConstitutif: ''
                        },
                        submitted: false,
                        rowIndex: null,
                        isEditEC: false,
                        erreurTypeUE: ''
                    }
                )
                this.notify.success('Element constitutif ajouté avec succès, vous pouvez consulter la liste des ajout en bas !')

            }else{
                this.notify.echec(erreurTypeUE)

                this.setState({
                    erreurTypeUE: erreurTypeUE,
                    curentEC: {
                        idElementConstitutif: '',
                        idUniteEnseignement: '',
                        libelleElementConstitutif: '',
                        codeElementConstitutif: '',
                        creditElementConstitutif: '',
                        nbHeureElementConstitutif: '',
                        nbHeureTD: '',
                        nbHeureTP: '',
                        nbHeureCours: '',
                        nbHeureTravauxPersonnel: '',
                        ordreElementConstitutif: ''
                    }
                })

            }
        }
    }



    controleValidation = (elementConstitutif) => {

        let test1 = (
            elementConstitutif.libelleElementConstitutif 
            && 
            (elementConstitutif.libelleElementConstitutif.trim()).length>=2
            &&
            elementConstitutif.codeElementConstitutif
            &&
            (elementConstitutif.codeElementConstitutif.trim()).length>=2
            &&
            !isNaN( parseFloat(elementConstitutif.creditElementConstitutif) )
            && 
            elementConstitutif.creditElementConstitutif!==0
            &&
            elementConstitutif.creditElementConstitutif!==null
            &&
            !isNaN( parseInt(elementConstitutif.nbHeureTD) )
            &&
            elementConstitutif.nbHeureTD!==null
            &&
            !isNaN( parseInt(elementConstitutif.nbHeureTP) )
            &&
            elementConstitutif.nbHeureTP!==null
            &&
            !isNaN( parseInt(elementConstitutif.nbHeureCours) )
            &&
            elementConstitutif.nbHeureCours!==null
            &&
            !isNaN( parseInt(elementConstitutif.nbHeureTravauxPersonnel) )
            &&
            elementConstitutif.nbHeureTravauxPersonnel!==null
            &&
            !isNaN( parseInt(elementConstitutif.ordreElementConstitutif) )
            &&
            elementConstitutif.ordreElementConstitutif!==null)

        return test1? true: false
    }



    annulerEC = () => {
        this.setState({
            curentEC: {
                idElementConstitutif: '',
                idUniteEnseignement: '',
                libelleElementConstitutif: '',
                codeElementConstitutif: '',
                creditElementConstitutif: '',
                nbHeureElementConstitutif: '',
                nbHeureTD: '',
                nbHeureTP: '',
                nbHeureCours: '',
                nbHeureTravauxPersonnel: '',
                ordreElementConstitutif: ''
            },
            isEditEC: false,
            rowIndex: null
        })
    }




    ////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////
    //////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    onTypeUniteEnseignementChange(e, typeUniteEnseignement) {

        const val = (e.target && e.target.value) || '';
        let ue = this.state.ue;
        ue[`${typeUniteEnseignement}`] = val;
        let listeEC = this.state.listeEC
        let erreurTypeUE = ""

        //4 = UE Optionnel donc traitement particulier
        if(ue.typeUniteEnseignement.idTypeUniteEnseignement===4){
            this.setState(
                {
                    ueOptionnel: true
                }
            )

            //Si un nombre d'EC obligatoire est designé et que la liste des EC est deja renseigne, alors ce changer impose un recalcule du credit de l'ue
            // ue.typeUniteEnseignement.idTypeUniteEnseignement===4
            if(this.state.ue.nbEcObligatoire  && !isNaN( parseInt(this.state.ue.nbEcObligatoire)) && listeEC.length>0){

                //On s'assure que les EC disponibles dans la liste vaux le nombre precisé comme nombre EC obligatoire
                if(this.state.listeEC.length>=parseInt(this.state.ue.nbEcObligatoire)){

                    //On s'assure enfin que tous les EC renseignés ont le meme credit
                    let identiqueCredit = true
                    let creditEC = listeEC[0].creditElementConstitutif
                    listeEC.map(ec => {
                        if(parseFloat(creditEC)!==parseFloat(ec.creditElementConstitutif)){
                            identiqueCredit = false
                        }
                    })

                    if(identiqueCredit){
                        let creditUniteEnseignement = parseInt(this.state.ue.nbEcObligatoire)*parseFloat(creditEC)
                        ue['creditUniteEnseignement'] = creditUniteEnseignement
                    }else{
                        erreurTypeUE = "Les EC renseigné n'ont pas les mêmes crédit, bien vouloir harmoniser !"
                    }
                    
                }else{
                    erreurTypeUE = "Le nombre d'EC renseigné est inférieur au nombre d'EC obligatoire, veuillez completer !"
                }
            }
            
        }else
        if(ue.typeUniteEnseignement.idTypeUniteEnseignement===3){
            //3 = UE libre on doit donc controler que tous les EC ont la 

            if(listeEC.length>0){
                //On s'assure enfin que tous les EC renseignés ont le meme credit
                let identiqueCredit = true
                let creditEC = listeEC[0].creditElementConstitutif
                listeEC.map(ec => {
                    if(parseFloat(creditEC)!==parseFloat(ec.creditElementConstitutif)){
                        identiqueCredit = false
                    }
                })

                if(identiqueCredit){
                    ue['creditUniteEnseignement'] = parseFloat(creditEC)
                    ue['nbEcObligatoire'] = null
                }else{
                    erreurTypeUE = "Les EC renseigné n'ont pas les mêmes crédit, bien vouloir harmoniser !"
                }
            }

            ue['nbEcObligatoire'] = null
            this.setState(
                {
                    ueOptionnel: false,
                    ecOption: false
                }
            )
        }
        else{
            ue['nbEcObligatoire'] = null

            let creditUE = 0
            listeEC.map(ec => {
                creditUE = parseFloat(creditUE) + parseFloat(ec.creditElementConstitutif)
            })
            ue['creditUniteEnseignement'] = parseFloat(creditUE)
            this.setState(
                {
                    ueOptionnel: false,
                    ecOption: false
                }
            )
        }

        this.setState({
            ue,
            erreurTypeUE
         });
    }


    editionEC = (rowData, index) => {

        this.setState({
            curentEC: rowData,
            rowIndex: index.rowIndex,
            isEditEC: true
        })
    }

    deleteEC = (rowData, index) => {
        //console.log(rowData, index.rowIndex)

        //On retire l'element et on fait la reconstructions des donnees de l'UE
        let listeEC = this.state.listeEC.filter((ec, curentIndex) => {
            return curentIndex!==index.rowIndex
        })

        let nbCreditUE = 0
        if(this.state.ue.nbEcObligatoire===null){
            //On parcours donc pour aditionner les credits
            listeEC.map( ec => {
                nbCreditUE = parseFloat(nbCreditUE) + parseFloat(ec.creditElementConstitutif)
            })
        }else{
            //Y'a un nombre d'EC a choisir donc le credit de l'UE est le nombre de choix multiplie par le credit de chacun
            let nbEcObligatoire = parseInt(this.state.ue.nbEcObligatoire)
            nbCreditUE = nbEcObligatoire * parseFloat(listeEC[0].creditElementConstitutif)
        }

        let ue = this.state.ue
        ue['creditUniteEnseignement'] = nbCreditUE
        this.setState({
            ue: ue,
            listeEC: listeEC
        })
        this.notify.success('Element constitutif retiré avec succès !')

    }
    
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-success" onClick={() => this.editUE(rowData)} style={{marginRight: '.5em'}}/>
                {/*<Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteUE(rowData)} />*/}
            </React.Fragment>
        );
    }

    ecActionBodyTemplate = (rowData, index) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editionEC(rowData, index)} style={{marginRight: '.5em'}} />
                {/*!this.state.isEditUE && <Button icon="pi pi-trash" className="p-button-danger"  onClick={() => this.deleteEC(rowData, index)} /> */}
                <Button icon="pi pi-trash" className="p-button-danger"  onClick={() => this.deleteEC(rowData, index)} />
            </React.Fragment>
        );
    }

    rechercheECActionBodyTemplate = (ec) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editUERechercheEC(ec)} style={{marginRight: '.5em'}} />
            </React.Fragment>
        );
    }

    onObjetRechercheChange = (event, fieldName) => {
        let curentObjetRecherche = this.state.objetRecherche
        curentObjetRecherche[`${fieldName}`] = event.target.value

        this.setState({
            objetRecherche: curentObjetRecherche
        })
    }

    actionRechercherEC = () => {
        let curentObjetRecherche = this.state.objetRecherche

        if(curentObjetRecherche.codeElementConstitutif.trim() || curentObjetRecherche.libelleElementConstitutif.trim()){
            
            this.ueService.rechercherECParCodeLibellle(curentObjetRecherche).then(data => {
                if(data.length===0){
                    this.notify.success('Aucun resultat pour cette recherche')
                }
                this.setState({
                    listeResultEC: data,
                    objetRecherche: {
                        codeElementConstitutif: "",
                        libelleElementConstitutif: ""
                    } 
                })
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })
        }else{
            this.notify.echec('Veillez renseigner d\'abord le formulaire')
        }
    }

    hideSearchDialog = () => {
        this.setState({
            searchECDialog: false,
            objetRecherche: {
                codeElementConstitutif: "",
                libelleElementConstitutif: ""
            },
            listeResultEC: []
        })
    }

    editUERechercheEC = (ec) => {

        this.ueService.getUEById(ec.idUniteEnseignement).then(data => {
            this.editUE(data[0])
            this.hideSearchDialog()
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }


    annulerRechercherEC = () => {
        this.setState({
            objetRecherche: {
                codeElementConstitutif: "",
                libelleElementConstitutif: ""
            } 
        })
    }


    render() {

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

        const ueDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveUE} />
            </React.Fragment>
        );

        const searchECFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSearchDialog} />
            </React.Fragment>
        );

        const deleteUEDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteUEDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteUE(this.state.ue.idUniteEnseignement) } />
            </React.Fragment>
        );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} style={{marginRight: "5px"}}/>
                            <Button label="Rechercher EC" icon="pi pi-search" className="p-button-success p-mr-2" onClick={this.rechercherEC} />
                        </div>
                        <div className="p-col-2">
                            
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeUE} selectionMode="single" header={`Liste des UE (${this.state.listeUE.length})`} paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleUniteEnseignement" header="UE" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher UE"/>
                            <Column field="codeUniteEnseignement" header="Code" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher code UE"/>
                            <Column field="creditUniteEnseignement" header="Credit" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher crédit UE"/>
                            <Column field="ordreUniteEnseignement" header="Ordre" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher ordre"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }

                    </div>
                </div>

                <Dialog visible={this.state.ueDialog} style={{ width: '95%' }} header="Création d'une unité d'enseignement" modal className="p-fluid" footer={ueDialogFooter} onHide={this.hideDialog}>

                        <hr/>
                <div className="card">

                        <div className="card-header">
                            <h3>Informations de l'unité d'enseignement</h3>
                        </div>
                        <div className="card-body">


                        {this.state.erreurTypeUE.length>0 && 
                            <div className="alert alert-danger d-flex align-items-center" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                </svg>
                                <div>
                                    {this.state.erreurTypeUE}
                                </div>
                            </div>
                        }
                        

                    <table style={{width: '100%'}}>
                        <tbody>
                            <tr>

                                <td  style={{width: '20%'}}>

                                    <div className="p-field">
                                        <label htmlFor="codeUE">Code Unité d'Enseignant *</label>
                                        <InputText id="codeUE" value={this.state.ue.codeUniteEnseignement}  onChange={(e) => this.onCodeUEChange(e, 'codeUniteEnseignement')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                        {this.state.submitted && !this.state.ue.codeUniteEnseignement && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                    </div>

                                </td>
                                
                                {!this.state.isEditUE && (
                                    <td  style={{width: '30%'}}>
                                        <div className="p-field">
                                            <label htmlFor="libelleTypeUnite">Type Unité d'Enseignement</label>
                                            <Dropdown optionLabel="libelleTypeUniteEnseignement" optionValue="idTypeUniteEnseignement" value={this.state.ue.typeUniteEnseignement} options={this.state.listeTypeUE} onChange={(e) => {this.onTypeUniteEnseignementChange(e, 'typeUniteEnseignement')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un type d'unité enseignement"/>
                                            {this.state.submitted && !this.state.ue.typeUniteEnseignement && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                        </div>
                                    </td>
                                )}
                                
                                {this.state.isEditUE && this.state.ue.typeUniteEnseignement.idTypeUniteEnseignement && (
                                    <td  style={{width: '30%'}}>
                                        <div className="p-field">
                                            <label htmlFor="libelleTypeUnite">Type Unité d'Enseignement</label>
                                            <Dropdown optionLabel="libelleTypeUniteEnseignement" optionValue="idTypeUniteEnseignement" value={this.state.ue.typeUniteEnseignement} options={this.state.listeTypeUE} onChange={(e) => {this.onTypeUniteEnseignementChange(e, 'typeUniteEnseignement')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un type d'unité enseignement"/>
                                            {this.state.submitted && !this.state.ue.typeUniteEnseignement && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                        </div>
                                    </td>
                                )}

                                <td  style={{width: '10%'}}>
                                    <div className="p-field">
                                        <label htmlFor="ordreUniteEnseignement">Ordre UE *</label>
                                        <InputText id="ordreUniteEnseignement" value={this.state.ue.ordreUniteEnseignement}  onChange={(e) => this.onOrdreUEChange(e, 'ordreUniteEnseignement')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                        {this.state.submitted && !this.state.ue.ordreUniteEnseignement && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                    </div>
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                    
                    <hr />
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            
                            <td  style={{width: '20%'}}>
                                <div className="p-field">
                                    <label htmlFor="creditUE">Credit Unité d'Enseignant (auto-renseigné) </label>
                                    <InputText id="creditUE" value={this.state.ue.creditUniteEnseignement}  onChange={(e) => this.onCreditUEChange(e, 'creditUniteEnseignement')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} readOnly disabled style={{backgroundColor: "rgb(100,100,100)"}}/>
                                    {this.state.submitted && !this.state.ue.creditUniteEnseignement && this.state.ue.creditUniteEnseignement===0 && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </td>

                            <td  style={{width: '30%'}}>
                                <div className="p-field">
                                    <label htmlFor="libelleUE">Libelle Unité d'Enseignant *</label>
                                    <InputText id="libelleUE" value={this.state.ue.libelleUniteEnseignement}  onChange={(e) => this.onLibelleUEChange(e, 'libelleUniteEnseignement')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {((this.state.submitted && !this.state.ue.libelleUniteEnseignement) || (this.state.ue.libelleUniteEnseignement && this.state.ue.libelleUniteEnseignement.trim().length<3)) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit contenir au moins trois (03) caractères.</small>}
                                </div>
                            </td>

                            {this.state.ueOptionnel && (

                                <React.Fragment>
                                    <td  style={{width: '25%'}}>
                                        <div className="p-field">
                                            <label htmlFor="libelleOption">Sur quelle élement porte l'option ? *</label>
                                            <Dropdown optionLabel="libelleOption" optionValue="idOption" value={this.state.curentOption} options={this.state.listeOptionUE} onChange={(e) => {this.onChoixOption(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'element optionnel"/>
                                            {this.state.submitted && !this.state.curentOption && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                        </div>
                                    </td>
                                </React.Fragment>
                                
                            )}

                            {this.state.ecOption && 

                                <td  style={{width: '25%'}}>
                                    <div className="p-field">
                                        <label htmlFor="nbEcObligatoire">Nombre  d'EC obligatoire (numérique) *</label>
                                        <InputText id="nbEcObligatoire" value={this.state.ue.nbEcObligatoire}  onChange={(e) => this.onNbEcChange(e, 'nbEcObligatoire')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                        {this.state.submitted && !this.state.ue.nbEcObligatoire && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                    </div>
                                </td>

                            }
                        </tr>
                        </tbody>
                    </table>

                    <hr />
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr >
                            <td  style={{width: '10%'}}>
                                <div className="p-field">
                                    
                                        <RadioButton inputId="credit" name="choixCalNote" value={false} onChange={(e) => {this.onIschoixCalculChange(e, 'isChoixCaluclNote')}} checked={!this.state.ue.isChoixCaluclNote }  />
                                        <label htmlFor="credit" className="ml-2">Calcul moy. UE par crédit</label>
                                    
                                        <RadioButton style={{marginLeft : 50}} inputId="coef" name="choixCalNote" value={true}  onChange={(e) => {this.onIschoixCalculChange(e, 'isChoixCaluclNote')}} checked={this.state.ue.isChoixCaluclNote}  />
                                        <label htmlFor="coef" className="ml-2">Calcul moy. UE par coef.</label> 
                                    
                                </div>
                            </td>
                            
                            {this.state.ue.isChoixCaluclNote &&(
                                <td  style={{width: '15%'}}>
                                        <div className="p-field">
                                            <label htmlFor="coefficient">Coefficient de l'UE</label>
                                            <input type='Number' step="0.1" min='0.1' className={classNames({'p-inputtext p-component': 'p-inputtext p-component', 'p-invalid': this.state.submitted })} id="coefficient" value={this.state.ue.coefficient}  onChange={(e) => this.onCoefUEChange(e, 'coefficient')} />
                                            {this.state.submitted && !this.state.ue.coefficient && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                        </div>
                                </td>
                                )}
                        </tr>
                        </tbody>
                    </table>
                    
                    </div>

                    </div>
                    <hr />

                    <div className="card">
                        <div className="card-header">
                            <h3>Ajout des élements constitutifs</h3>
                        </div>
                        <div className="card-body">

                        <div className="p-grid">

                            <div className='p-col-3 p-md-3 p-lg-3'>
                                <div className="p-field">
                                    <label htmlFor="ordreElementConstitutif"><span style={{color:'red'}}>*</span> Ordre element constitutif</label>
                                    <input type='Number' step="1" min='1' className="p-inputtext p-component" value={this.state.curentEC.ordreElementConstitutif}  onChange={(e) => this.onChildInputChange(e, 'ordreElementConstitutif')} required autoFocus />
                                    {this.state.submitted && !this.state.curentEC.ordreElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>

                            <div className='p-col-3 p-md-3 p-lg-3'>
                                <div className="p-field">
                                    <label htmlFor="codeElementConstitutif"><span style={{color:'red'}}>*</span> Code element constitutif</label>
                                    <InputText value={this.state.curentEC.codeElementConstitutif}  onChange={(e) => this.onChildInputChange(e, 'codeElementConstitutif')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && !this.state.curentEC.codeElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>

                            <div className='p-col-3 p-md-3 p-lg-3'>
                                <div className="p-field">
                                    <label htmlFor="libelleElementConstitutif"><span style={{color:'red'}}>*</span> Libellé element constitutif</label>
                                    <InputText value={this.state.curentEC.libelleElementConstitutif}  onChange={(e) => this.onChildInputChange(e, 'libelleElementConstitutif')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && !this.state.curentEC.libelleElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>

                            <div className='p-col-3 p-md-3 p-lg-3'>
                                <div className="p-field">
                                    <label htmlFor="creditElementConstitutif"><span style={{color:'red'}}>*</span> Credit element constitutif</label>
                                    <input type='Number' step="0.1" min='0.1' className="p-inputtext p-component" value={this.state.curentEC.creditElementConstitutif}  onChange={(e) => this.onChildInputChange(e, 'creditElementConstitutif')} required autoFocus />
                                    {this.state.submitted  && !this.state.curentEC.creditElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>

                        </div>

                        <div className="p-grid">

                            <div className='p-col-3 p-md-3 p-lg-3'>
                                <div className="p-field">
                                    <label htmlFor="nbHeureCours">NB heures CM</label>
                                    <InputText value={this.state.curentEC.nbHeureCours}  onChange={(e) => this.onChildInputChange(e, 'nbHeureCours')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && !this.state.curentEC.nbHeureCours==="" && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>

                            <div className='p-col-3 p-md-3 p-lg-3'>
                                <div className="p-field">
                                    <label htmlFor="nbHeureTD">NB heures TD</label>
                                    <InputText value={this.state.curentEC.nbHeureTD}  onChange={(e) => this.onChildInputChange(e, 'nbHeureTD')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && this.state.curentEC.nbHeureTD==="" && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>

                            <div className='p-col-3 p-md-3 p-lg-3'>
                                <div className="p-field">
                                    <label htmlFor="nbHeureTP">NB heures TP</label>
                                    <InputText value={this.state.curentEC.nbHeureTP}  onChange={(e) => this.onChildInputChange(e, 'nbHeureTP')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted  && this.state.curentEC.nbHeureTP==="" && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>

                            <div className='p-col-3 p-md-3 p-lg-3'>
                                <div className="p-field">
                                    <label htmlFor="nbHeureTravauxPersonnel">NB heures TPerso</label>
                                    <InputText value={this.state.curentEC.nbHeureTravauxPersonnel}  onChange={(e) => this.onChildInputChange(e, 'nbHeureTravauxPersonnel')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && this.state.curentEC.nbHeureTravauxPersonnel==="" && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>

                        </div>

                        </div>
                        <div className="card-footer">

                            <button className="btn btn-success p-button-text" onClick={() => this.ajouterEC(this.state.listeEC)}> {this.state.isEditEC? "Valider modification":"Ajouter"}</button>
                            <button className="btn btn-warning p-button-text" onClick={() => this.annulerEC()}> Annuler</button>

                        </div>
                    </div>
                    <hr/>

                    <div className="card">
                        <div className="card-header">
                            <h3>Listes des éléments constitutifs de l'unité d'enseignement</h3>
                        </div>

                        <div className="card-body">
                            <DataTable  ref={(el) => this.dt = el} value={this.state.listeEC} selectionMode="none" header={`Liste des élements constitutifs (${this.state.listeEC.length})`} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="ordreElementConstitutif" header="Odre element" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="codeElementConstitutif" header="Code element" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="libelleElementConstitutif" header="Libellé élement" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="creditElementConstitutif" header="Crédit élement" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="nbHeureCours" header="NB heures CM" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="nbHeureTD" header="NB heures TD" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="nbHeureTP" header="NB heures TP" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="nbHeureTravauxPersonnel" header="NB heures TPerso" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column header={actionHeader} body={this.ecActionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                            {this.state.submitted && this.state.listeEC.length===0 && <small className="p-invalid" style={{color : "red"}}>Ajouter des EC avant de passer à l'enregistrement</small>}
                            {this.state.submitted && this.state.erreurTypeUE.length>0 && <small className="p-invalid" style={{color : "red"}}>{this.state.erreurTypeUE}</small>}
                        </div>
                    </div>

                </Dialog>

                <Dialog visible={this.state.searchECDialog} style={{ width: '95%' }} header="Recherche d'élement constitutif" modal className="p-fluid" footer={searchECFooter} onHide={this.hideSearchDialog}>

                    <hr/>

                    <div className="card">
                        <div className="card-header">
                            <h3>Informations de l'EC</h3>
                        </div>
                        <div className="card-body">

                            <div className="p-grid">

                                <div style={{paddingTop: '24px'}} className='p-col-12 p-md-12 p-lg-4' >
                                    <div className="p-field">
                                        <label htmlFor="codeElementConstitutif">Code element constitutif</label>
                                        <InputText value={this.state.objetRecherche.codeElementConstitutif}  onChange={(e) => this.onObjetRechercheChange(e, 'codeElementConstitutif')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    </div>
                                </div>

                                <div style={{paddingTop: '24px'}} className='p-col-12 p-md-8 p-lg-8'>
                                    <div className="p-field">
                                        <label htmlFor="codeElementConstitutif">Libellé élement constitutif</label>
                                        <InputText value={this.state.objetRecherche.libelleElementConstitutif}  onChange={(e) => this.onObjetRechercheChange(e, 'libelleElementConstitutif')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="card-footer">
                            <button className="btn btn-success p-button-text" onClick={() => this.actionRechercherEC()}><i className='pi pi-search'></i> Rechercher élement constitutif</button>
                            <button className="btn btn-warning p-button-text" onClick={() => this.annulerRechercherEC()} style={{marginLeft: '5px'}}><i className='pi pi-times'></i> Annuler</button>
                        </div>
                    </div>
                    <hr/>

                    <div className="card">
                        <div className="card-header">
                            <h3>Résultat de la recherche</h3>
                        </div>

                        <div className="card-body">
                            <DataTable  ref={(el) => this.dt = el} value={this.state.listeResultEC} selectionMode="none" header={`Résultat de la recherche (${this.state.listeResultEC.length})`} paginator={true} rows={10}
                            globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="ordreElementConstitutif" header="Odre element" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="codeElementConstitutif" header="Code element" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="libelleElementConstitutif" header="Libellé élement" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="creditElementConstitutif" header="Crédit élement" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="nbHeureCours" header="NB heures CM" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="nbHeureTD" header="NB heures TD" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="nbHeureTP" header="NB heures TP" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="nbHeureTravauxPersonnel" header="NB heures TPerso" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column header={actionHeader} body={this.rechercheECActionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                        </div>
                    </div>

                </Dialog>

                <Dialog visible={this.state.deleteUEDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteUEDialogFooter} onHide={this.hideDeleteUEDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.ue && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default UniteEnseignement