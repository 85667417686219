import React, { Component } from 'react'
import {Button} from 'primereact/button'
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames'
import { Notify } from '../../components/Notify';
import { Spinner } from '../../components/Spinner';
import { ParcoursFiliereService } from '../../lmd/service/ParcoursFiliereService';
import { AnneeService } from '../../lmd/service/AnneeService';
import { SemestreService } from '../../lmd/service/SemestreService';
import { NiveauService } from '../../lmd/service/NiveauService';
//import { InscriptionService } from '../../lmd/service/InscriptionService';
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import { Dialog } from 'primereact/dialog';
import EmploiDuTempsService from '../service/EmploiDuTempsService';
import { CohorteServices } from '../service/CohorteServices';
import {jsPDF} from 'jspdf'

export class EmploisDuTemps extends Component {
    constructor(props){

        super(props);
        this.notify = new Notify()
        this.spinner = new Spinner()

        this.cohorteService = new CohorteServices()
        this.semestreService = new SemestreService()
        this.niveauService = new NiveauService()
        this.emploiDuTempsService = new EmploiDuTempsService()

        this.cols = [
            { field: 'ine', header: 'Lundi' },
            { field: 'nom', header: 'Mardi' },
            { field: 'prenom', header: 'Mercredi' },
            { field: 'dateNaissance', header: 'Jeudi' },
            { field: 'lieuNaissance', header: 'Vendredi' },
            { field: 'moyenne', header: 'Samedi' },
        ];

        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));


        this.state = {
            cohorte : {},
            semestre : {},
            listeDepartement : [],
            listeEtablissement : [],
            listeCohorte : [],
            listeSemestre : [],
            listePeriode : [],
            departement : {},
            etablissement : {},
            token : null,
            loadingChargement : false,
            bool : false,
            submitted : false,
            globalFilter: null,
            dialogEc : false,
            boolEmploiTemps : false,
            listeProgrammation : {},
            jourSemaine : [],
            emploiDuTemps : [],
            emploiSemaine : [],
            isEtudiant : 0,
            isDepartement: 0,
            isEnseignant : 0,
            isDirecteur : 0,
            isDA : 0,
            isVp : 0
        }
        
    }
    componentDidMount = () => {
        const tokenDecoded = localStorage.getItem('tokenDecoded')
		const token = JSON.parse(tokenDecoded)
        const campusEtudiant = localStorage.getItem('campusEtudiant')
        const campusVpEip = localStorage.getItem('campusVpEip')
        const campusDirecteur = localStorage.getItem('campusDirecteur')
        const campusDa = localStorage.getItem('campusDa')
        const campusDepartement = localStorage.getItem('campusDepartement')
		const isEtudiant = parseInt(JSON.parse(campusEtudiant))
        const isDepartement = parseInt(JSON.parse(campusDepartement))
        const isDirecteur = parseInt(JSON.parse(campusDirecteur))
        const isDA = parseInt(JSON.parse(campusDa))
        const isVp = parseInt(JSON.parse(campusVpEip))
        //const isEnseignant = parseInt(JSON.parse(isEnseignant))
        //console.log(isDepartement)
        this.setState({token : token, isEtudiant : isEtudiant, isDepartement : isDepartement, isDirecteur : isDirecteur, isDA : isDA, isVp : isVp})
        if(isDepartement){
            this.cohorteService.getListeCohorteDepartement(token.code_departement).then(data => {
                this.setState({listeCohorte : data})
                
            })
            .catch(error => {
                console.log(error)
            })
        }
        else if(isDirecteur || isDA){
            this.cohorteService.getListeDepartementEtab(token.code_etablissement).then(data => {
                this.setState({listeDepartement : data.departementEtabDtos})
                //console.log(data)
            })
        }
        else if(isVp){
            this.cohorteService.getListeEtabUniv(token.code_iesr).then(data => {
                this.setState({listeEtablissement : data})
                //console.log(data)
            })
        }
        else if(isEtudiant) {
            let ineEtudiant = token.preferred_username.toUpperCase() || token.code_ine.toUpperCase()
            console.log(ineEtudiant)
            //let ineEtudiant = "20-5775 P"
            //let ineEtudiant = "20-6005 S"
            //console.log(ineEtudiant)
            this.cohorteService.getListeCohorteEtudiant(ineEtudiant).then(data => {
                this.setState({listeCohorte : data})
                console.log(data)
            })
            .catch(error => {
                console.log(error)
            })
        }
        /*else if(!isEnseignant){
            let mleEnseignant = token.preferred_username
            
        }*/
        else {
            
        }
        
    }
    onChampsChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        
        if(field === "cohorte"){
            //console.log(val)
            this.setState({cohorte : val})
            this.emploiDuTempsService.getSemestre(val.idNiveau).then(data => {
                //console.log(data)
                this.setState({listeSemestre : data.semestreDtoList})
            })
            .catch(error => {
                console.log(error)
            })
            
        }
            
        else if(field === "semestre")
            this.setState({semestre : val})
    }

    onDepartementChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        this.setState({departement : val})
        this.cohorteService.getListeCohorteDepartement(val.idDepartementEtab).then(data => {
            this.setState({listeCohorte : data})
            //console.log(data)
        })
    }

    onEtablissementChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        this.setState({etablissement : val})
        this.cohorteService.getListeDepartementEtab(val.idEtablissement).then(data => {
            this.setState({listeDepartement : data.departementEtabDtos})
            //console.log(data)
        })
    }

    listePeriodeProgrammation = () => {
        let idDepartement = null
        let idCohorte = this.state.cohorte.idCohorte
        let idSemestre = this.state.semestre.idSemestre

        if(this.state.isEtudiant){
            idDepartement=this.state.cohorte.idDepartement
            //console.log(this.state.cohorte)
            //console.log(idDepartement)
        }
        else if(this.state.isDepartement){
            idDepartement = this.state.token.code_departement
        }
        else if(this.state.isDirecteur || this.state.isDA || this.state.isVp) {
            idDepartement=this.state.departement.idDepartementEtab
        }
        

        //this.state.isEtudiant? idDepartement=this.state.cohorte.idDepartement : idDepartement=this.state.token.code_departement
        //console.log(idDepartement)
        //console.log(idCohorte)
        //console.log(idSemestre)
        this.emploiDuTempsService.getAllPeriodeProgrammation(idDepartement, idCohorte, idSemestre).then(data => {
            //console.log(data)
            this.setState({listePeriode : data})
        })
        .catch(error => {
            console.log(error)
        })
        /*let listePeriode = [
            {
                idSemaine : 1,
                dateDebutSemaine : "01/02/2022",
                dateFinSemaine : "06/02/2022"
            }
        ]
        this.setState({listePeriode : listePeriode})*/
    }
    afficheEmploiDuTemps = (donnee) => {
        this.setState({emploiSemaine : donnee})
        let idDepartement = null
        //let idDepartement = this.state.token.code_departement
        let idCohorte = this.state.cohorte.idCohorte
        let idSemestre = this.state.semestre.idSemestre
        if(this.state.isEtudiant){
            idDepartement=this.state.cohorte.idDepartement
        }
        else if(this.state.isDepartement){
            idDepartement = this.state.token.code_departement
        }
        else if(this.state.isDirecteur || this.state.isDA || this.state.isVp) {
            idDepartement=this.state.departement.idDepartementEtab
        }
        //this.state.isEtudiant? idDepartement=this.state.cohorte.idDepartement : idDepartement=this.state.token.code_departement
        let idSemaine = donnee.idSemaineProgrammation
       
       this.emploiDuTempsService.genererEmploiDuTemps(idDepartement,idSemestre,idCohorte,idSemaine).then(data => {
           console.log(data)
           let jourSemaine = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
           this.setState({jourSemaine : jourSemaine,listeProgrammation : data,boolEmploiTemps : true})
       })
       
      /*this.setState({boolEmploiTemps : true})
       let jourSemaine = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
       let listeProgrammation = {
        nomIes: "UNIVERSITÉ JOSEPH KI-ZERBO", 
        sigleIes: "UJKZ", 
        departement: "Lettres Modernes (LM)", 
        sigleDepartement:null, 
        parcours:"MATH/PC", 
        niveau:"Licence 1", 
        semestre:"Semestre 1", 
        annee:"2020 - 2021", 
        logoIes:"logo_ujkz.jpeg", 
        nomEtablissement:"UFR/LAC", 
        sigleEtablissement:"UFR/LAC",
        cohort:"Cohorte-3-MATH/PC-Licence 1-2020-2021",
        matinee :[
             
              {
                nomJour : "Lundi",
                listeProgrammation : [
                    {
                        heureDebut:"08:00",
                        heureFin:"12:00", 
                        idElementConstitutif:1111, 
                        libelleElementConstitutif:"Algèbre 2", 
                        idEnseignant:108, 
                        nomEnseignant:"Kalmogo Lucien",
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-21"
                    }
                ]
            },
            {
                nomJour : "Mardi",
                listeProgrammation : [
                    {
                        heureDebut:"08:00", 
                        heureFin:"10:00", 
                        idElementConstitutif:1084, 
                        libelleElementConstitutif:"Informatique", 
                        idEnseignant:121, 
                        nomEnseignant:"Ouedraogo salam", 
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-22"
                    },
                    {
                        heureDebut:"10:30", 
                        heureFin:"13:00", 
                        idElementConstitutif:1111, 
                        libelleElementConstitutif:"Algèbre 2", 
                        idEnseignant:108, 
                        nomEnseignant:"Kalmogo Lucien", 
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-22"
                    },
                ]
            },
            {
                nomJour : "Mercredi",
                listeProgrammation : [
                    {
                        heureDebut:"08:00", 
                        heureFin:"12:00", 
                        idElementConstitutif:1111, 
                        libelleElementConstitutif:"Algèbre 2", 
                        idEnseignant:108, 
                        nomEnseignant:"Kalmogo Lucien", 
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-23"
                    }
                ]
            },
            {
                nomJour : "Jeudi",
                listeProgrammation : [
                    {
                        heureDebut:"08:00", 
                        heureFin:"12:00", 
                        idElementConstitutif:1084, 
                        libelleElementConstitutif:"Informatique", 
                        idEnseignant:121, 
                        nomEnseignant:"Ouedraogo salam",
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-24"
                    }
                ]
            },
            {
                nomJour : "Vendredi",
                listeProgrammation : [
                    {
                        heureDebut:"08:00", 
                        heureFin:"12:00", 
                        idElementConstitutif:1112, 
                        libelleElementConstitutif:"Analyse 1", 
                        idEnseignant:109, 
                        nomEnseignant:"LANKOANDE David", 
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-25"
                    }
                    
                ]
            },
            {
                nomJour : "Samedi",
                listeProgrammation : [
                    {
                        heureDebut:"08:00", 
                        heureFin:"12:00", 
                        idElementConstitutif:1111, 
                        libelleElementConstitutif:"Algèbre 2", 
                        idEnseignant:108, 
                        nomEnseignant:"Kalmogo Lucien", 
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-26"
                    }
                ]
            }
        ],
        soiree : [
            {
                nomJour : "Lundi",
                listeProgrammation : [
                    
                    {
                        heureDebut:"14:00", 
                        heureFin:"18:00", 
                        idElementConstitutif:1112, 
                        libelleElementConstitutif:"Analyse 1", 
                        idEnseignant:109, 
                        nomEnseignant:"LANKOANDE David", 
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-21"
                    },
                ]
            },
            {
                nomJour : "Mardi",
                listeProgrammation : [
                    
                    {
                        heureDebut:"14:00", 
                        heureFin:"18:00", 
                        idElementConstitutif:1111, 
                        libelleElementConstitutif:"Algèbre 2", 
                        idEnseignant:108, 
                        nomEnseignant:"Kalmogo Lucien", 
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-22"
                    },
                ]
            },
            {
                nomJour : "Mercredi",
                listeProgrammation : [
                    
                    {
                        heureDebut:"14:00", 
                        heureFin:"18:00", 
                        idElementConstitutif:1112, 
                        libelleElementConstitutif:"Analyse 1", 
                        idEnseignant:109, 
                        nomEnseignant:"LANKOANDE David", 
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-23"
                    },
                ]
            },
            {
                nomJour : "Jeudi",
                listeProgrammation : [
                    
                    {
                        heureDebut:"14:00",
                        heureFin:"18:00",
                        idElementConstitutif:1084,
                        libelleElementConstitutif:"Informatique",
                        idEnseignant:121, 
                        nomEnseignant:"Ouedraogo salam", 
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-24"
                    },
                ]
            },
            {
                nomJour : "Vendredi",
                listeProgrammation : [
                    
                    {
                        heureDebut:"14:00", 
                        heureFin:"18:00", 
                        idElementConstitutif:1112, 
                        libelleElementConstitutif:"Analyse 1", 
                        idEnseignant:109, 
                        nomEnseignant:"LANKOANDE David", 
                        idInfrastructure:16, 
                        libelleInfrastructure:"Amphi Théatre 250",
                        dateProgrammation:"2022-02-25"
                    },
                ]
            }
            
        ]
    }
    
    this.setState({jourSemaine : jourSemaine,listeProgrammation : listeProgrammation})*/

    }
    contenuEmploi = (listeProgrammation) => {
        //console.log(listeProgrammation)
    }

    genererEmploiDuTemps = () => {
        /*const l = {
            orientation: 'l',
            unit: 'pt',
            format: 'a4',
            compress: true,
            fontSize: 8,
            lineHeight: 1,
            autoSize: false,
            printHeaders: true
        };*/
        const doc = new jsPDF('l','pt','a3')
        //let titre = this.state.listeTitle
        //doc.setFontSize(6);
        doc.html(document.querySelector('#emploi'), {
            callback : function(pdf){
                pdf.save("emploiDuTemps"+".pdf")
            }
        })
        
    }
    
    hideEmploiDuTempsDialog = () => {
        this.setState({boolEmploiTemps : false})
    }
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-eye" className="p-button-success" style={{marginRight: '.5em'}} title="Créer un examen" onClick={ () => this.afficheEmploiDuTemps(rowData) } />
            </React.Fragment>
        )
    }


    
  render() {
      //console.log(this.state.listeProgrammation)
    const emploiDuTempsDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideEmploiDuTempsDialog} />
        </React.Fragment>
    );
    return (
      <div>
           <div className="card p-lg-10" style={{marginLeft : "10%", marginTop : "5%"}}>
                        <div className="p-grid p-lg-12" style={{marginTop : "3%"}}>
                            {this.state.isVp ?
                            <>
                            <div className="p-field" style={{marginLeft : "15%"}}>
                                <Dropdown style={{marginLeft : 10}} onChange={(e) => {this.onEtablissementChange(e, "etablissement")}} optionLabel="sigleEtablissement" value={this.state.etablissement} options={this.state.listeEtablissement}  placeholder="Choisir un établissement"/>
                                {this.state.submitted && !this.state.departement && <small className="p-invalid" style={{color : "red"}}>Selectionner un departement.</small>}
                            </div>
                            <div className="p-field" style={{marginLeft : 10}}>
                                <Dropdown style={{marginLeft : 10}} onChange={(e) => {this.onDepartementChange(e, "departement")}} optionLabel="lbDepartementEtab" value={this.state.departement} options={this.state.listeDepartement}  placeholder="Choisir un departement"/>
                                {this.state.submitted && !this.state.departement && <small className="p-invalid" style={{color : "red"}}>Selectionner un departement.</small>}
                            </div>
                            <div className="p-field" style={{marginLeft : 10}}>
                                <Dropdown optionLabel="libelleCohorte" optionValue={this.state.cohorte} value={this.state.cohorte}  options={this.state.listeCohorte} onChange={(e) => {this.onChampsChange(e, "cohorte")}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un cohorte"/>
                                {this.state.submitted && !this.state.cohorte && <small className="p-invalid" style={{color : "red"}}>Selectionner un cohorte.</small>}
                            </div>
                            <div className="p-field" style={{marginLeft: 10}}>
                                <Dropdown optionLabel="libelleSemestre" optionValue={this.state.semestre} value={this.state.semestre}  options={this.state.listeSemestre} onChange={(e) => {this.onChampsChange(e, "semestre")}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un semestre"/>
                                {this.state.submitted && !this.state.semestre && <small className="p-invalid" style={{color : "red"}}>Selectionner un semestre</small>}
                            </div>
                            </>
                            :
                            this.state.isDirecteur ?
                            <>
                                <div className="p-field" style={{marginLeft : "20%"}}>
                                    <Dropdown style={{marginLeft : 10}} onChange={(e) => {this.onDepartementChange(e, "departement")}} optionLabel="lbDepartementEtab" value={this.state.departement} options={this.state.listeDepartement}  placeholder="Choisir un departement"/>
                                    {this.state.submitted && !this.state.departement && <small className="p-invalid" style={{color : "red"}}>Selectionner un departement.</small>}
                                </div>
                                <div className="p-field" style={{marginLeft : 10}}>
                                    <Dropdown optionLabel="libelleCohorte" optionValue={this.state.cohorte} value={this.state.cohorte}  options={this.state.listeCohorte} onChange={(e) => {this.onChampsChange(e, "cohorte")}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un cohorte"/>
                                    {this.state.submitted && !this.state.cohorte && <small className="p-invalid" style={{color : "red"}}>Selectionner un cohorte.</small>}
                                </div>
                                <div className="p-field" style={{marginLeft: 10}}>
                                    <Dropdown optionLabel="libelleSemestre" optionValue={this.state.semestre} value={this.state.semestre}  options={this.state.listeSemestre} onChange={(e) => {this.onChampsChange(e, "semestre")}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un semestre"/>
                                    {this.state.submitted && !this.state.semestre && <small className="p-invalid" style={{color : "red"}}>Selectionner un semestre</small>}
                                </div>
                            </>
                            :
                            this.state.isDA ?
                            <>
                                <div className="p-field" style={{marginLeft : "20%"}}>
                                    <Dropdown style={{marginLeft : 10}} onChange={(e) => {this.onDepartementChange(e, "departement")}} optionLabel="lbDepartementEtab" value={this.state.departement} options={this.state.listeDepartement}  placeholder="Choisir un departement"/>
                                    {this.state.submitted && !this.state.departement && <small className="p-invalid" style={{color : "red"}}>Selectionner un departement.</small>}
                                </div>
                                <div className="p-field" style={{marginLeft : 10}}>
                                    <Dropdown optionLabel="libelleCohorte" optionValue={this.state.cohorte} value={this.state.cohorte}  options={this.state.listeCohorte} onChange={(e) => {this.onChampsChange(e, "cohorte")}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un cohorte"/>
                                    {this.state.submitted && !this.state.cohorte && <small className="p-invalid" style={{color : "red"}}>Selectionner un cohorte.</small>}
                                </div>
                                <div className="p-field" style={{marginLeft: 10}}>
                                    <Dropdown optionLabel="libelleSemestre" optionValue={this.state.semestre} value={this.state.semestre}  options={this.state.listeSemestre} onChange={(e) => {this.onChampsChange(e, "semestre")}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un semestre"/>
                                    {this.state.submitted && !this.state.semestre && <small className="p-invalid" style={{color : "red"}}>Selectionner un semestre</small>}
                                </div>
                            </>
                            :
                            this.state.isDepartement ?
                            <>
                                <div className="p-field" style={{marginLeft : "26%"}}>
                                    <Dropdown optionLabel="libelleCohorte" optionValue={this.state.cohorte} value={this.state.cohorte}  options={this.state.listeCohorte} onChange={(e) => {this.onChampsChange(e, "cohorte")}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un cohorte"/>
                                    {this.state.submitted && !this.state.cohorte && <small className="p-invalid" style={{color : "red"}}>Selectionner un cohorte.</small>}
                                </div>
                                <div className="p-field" style={{marginLeft: 10}}>
                                    <Dropdown optionLabel="libelleSemestre" optionValue={this.state.semestre} value={this.state.semestre}  options={this.state.listeSemestre} onChange={(e) => {this.onChampsChange(e, "semestre")}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un semestre"/>
                                    {this.state.submitted && !this.state.semestre && <small className="p-invalid" style={{color : "red"}}>Selectionner un semestre</small>}
                                </div>
                            </>
                            :
                            this.state.isEtudiant ?
                            <>
                                <div className="p-field" style={{marginLeft : "26%"}}>
                                    <Dropdown optionLabel="libelleCohorte" optionValue={this.state.cohorte} value={this.state.cohorte}  options={this.state.listeCohorte} onChange={(e) => {this.onChampsChange(e, "cohorte")}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un cohorte"/>
                                    {this.state.submitted && !this.state.cohorte && <small className="p-invalid" style={{color : "red"}}>Selectionner un cohorte.</small>}
                                </div>
                                <div className="p-field" style={{marginLeft: 10}}>
                                    <Dropdown optionLabel="libelleSemestre" optionValue={this.state.semestre} value={this.state.semestre}  options={this.state.listeSemestre} onChange={(e) => {this.onChampsChange(e, "semestre")}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un semestre"/>
                                    {this.state.submitted && !this.state.semestre && <small className="p-invalid" style={{color : "red"}}>Selectionner un semestre</small>}
                                </div>
                            </>
                            :
                            ""
                            }
                            
                            <div className="p-field" style={{marginLeft: 10}}>
                                <Button style={{marginLeft : "0%"}} label="Afficher"
                                    onClick={this.listePeriodeProgrammation}
                                />
                            </div>
                        </div>
                        <div className='p-grid p-lg-12'>
                            <div className="p-lg-2"></div>
                            <div className="p-lg-8" style={{marginTop : 10}}>
                                <DataTable ref={(el) => this.dt = el} value={this.state.listePeriode} selectionMode="single" header="Liste des périodes des programmations" paginator={true} rows={10}
                                    globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                    <Column field="libelleSemaine" header="Semaine" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher debut semaine"/>
                                    {/*<Column field="dateFinSemaine" header="Fin semaine" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher fin semaine"/>*/}
                                    
                                    <Column  body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                                </DataTable> 
                            </div>
                        </div>
                        
                    </div> 
                    <Dialog visible={this.state.boolEmploiTemps} style={{ width: '80%' }} header={`Emploi du temps de la semaine du : ${this.state.emploiSemaine.libelleSemaine} `} modal className="p-fluid" footer={emploiDuTempsDialogFooter} onHide={this.hideEmploiDuTempsDialog}>
                        {this.state.boolEmploiTemps ? 
                        <>
                            <div className='p-col-2'>
                                <Button onClick={this.genererEmploiDuTemps} label="Generer PDF" type="button" icon="pi pi-file-pdf"  className="p-button-warning p-mr-2" data-pr-tooltip="PDF"/>
                            </div>
                            <table id="emploi" className='table' style={{fontSize : 10, marginLeft : 10}}>
                                <thead>
                                    <tr>
                                        <th colSpan={3}>Minitère de l'Enseignement Supérieur de la Recherche et de l'Innovation </th>
                                    </tr>
                                    <tr>
                                        <th colSpan={3}>Secrétariat Général</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={3}>{this.state.listeProgrammation.nomIes}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={3}>{this.state.listeProgrammation.nomEtablissement}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={3}>{this.state.listeProgrammation.departement}</th>
                                    </tr>
                                   
                                    <tr>
                                        <th colSpan={1}>Année : {this.state.listeProgrammation.annee}</th>
                                        <th colSpan={1}>Semestre : {this.state.listeProgrammation.semestre}</th>
                                        <th colSpan={2}>Cohorte : {this.state.listeProgrammation.cohorte}</th>
                                        <th colSpan={1}>Niveau : {this.state.listeProgrammation.niveau}</th>
                                        <th colSpan={2}>Parcours : {this.state.listeProgrammation.parcours}</th>
                                    </tr>
                                    <tr>
                                        <th style={{marginLeft : 30, fontWeight : 'bold',}} colSpan={5}>Emploi du temps de la semaine du {this.state.emploiSemaine.libelleSemaine}</th>
                                    </tr>
                                    <tr style={{border : "1px solid black"}}>
                                        {this.state.jourSemaine.map(jour => {
                                            return(<><th style={{border : "1px solid black", width : '50px'}}>{jour}</th></>)})
                                        }
                                    </tr>
                                        
                                </thead> 

                                <tbody>
                                    <tr> 
                                    {this.state.listeProgrammation.matinee.map(prog => {
                                        return(
                                            <td style={{border : "1px solid black", padding : 5, width : '50px'}}>
                                                {prog.listeProgrammation.map(p=> {
                                                    return(
                                                        <>
                                                            <span>{p.libelleElementConstitutif}</span><br/>
                                                            <span>{p.libelleInfrastructure}</span><br/>
                                                            <span>{p.nomEnseignant}</span><br/>
                                                            <span>{p.heureDebut} - {p.heureFin}</span><br/><br/>
                                                        </>
                                                        
                                                    )
                                                })}
                                            </td>
                                        )

                                    })}
                                    </tr> 
                                    <tr> 
                                    {this.state.listeProgrammation.soiree.map(prog => {
                                        return(
                                            <td style={{border : "1px solid black", padding : 5, width : '50px'}}>
                                                {prog.listeProgrammation.map(p=> {
                                                    return(
                                                        <>
                                                            <span>{p.libelleElementConstitutif}</span><br/>
                                                            <span>{p.libelleInfrastructure}</span><br/>
                                                            <span>{p.nomEnseignant}</span><br/>
                                                            <span>{p.heureDebut} - {p.heureFin}</span><br/><br/>
                                                        </>
                                                        
                                                    )
                                                })}
                                            </td>
                                        )

                                    })}
                                    </tr>         
        
                                </tbody> 
                            </table>
                        </>
                        :
                        ""
                        }
                    </Dialog>
                </div>
    )
  }
}

export default EmploisDuTemps