import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class InscriptionService {

     constructor(){
         this.adresse = new AdresseIP()
     }


     testerExistanceSessionInscription(idEtudiant){
        const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/inscription?idEtudiant="
        return axios.get(url+idEtudiant).then(res => res.data)
     }

     testerExistanceSessionInscriptionAdministrative(idEtudiant){
        const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/etudiant-inscription-administrative?idEtudiant="
        return axios.get(url+idEtudiant).then(res => res.data)
     }


     testerExistanceSessionInscriptionPedagogique(idEtudiant){
        const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/etudiant-inscription-pedagogique?idEtudiant="
        console.log(url+idEtudiant)
        return axios.get(url+idEtudiant).then(res => res.data)
     }


     getInformationEtudiant(idEtudiant){
        const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/etudiant?idEtudiant="
        return axios.get(url+idEtudiant).then(res => res.data)
     }


   getInformationInscriptionPedagogique(idEtudiant, idSession){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/etudiant-inscription-ec?idEtudiant="+idEtudiant+"&idSession="+idSession

      console.log(url)
      return axios.get(url).then(res => res.data)
   }

   getMontantInscription(idParcours, idNiveau, idEtudiant){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/montant-paiement?idParcours="+idParcours+"&idNiveau="+idNiveau+"&idEtudiant="+idEtudiant
      console.log(url)
      return axios.get(url).then(res => res.data)
   }

   inscrireEtudiant(inscriptionPedagogique){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/inscription-pedagogique"
      return axios.post(url, inscriptionPedagogique).then(res => res.data)
   }

   testerInscriptionPedagogiqueEtudiant(idEtudiant){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/inscription-pedagogique?idEtudiant="+idEtudiant
      return axios.get(url).then(res => res.data)
   }


   InscriptionAdministrative(inscriptionAdministrative){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/inscription-administrative/"
      return axios.post(url, inscriptionAdministrative).then(res => res.data)
   }

   paiementInscriptionAdministrative(inscriptionAdministrative){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/paiement-operateur/"
      return axios.post(url, inscriptionAdministrative).then(res => res.data)
   }

   getAttestationInscriptionPedagogique(attestationRequest){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/reporting/"
      return axios.post(url, attestationRequest, {responseType: "arraybuffer"}).then(res => res.data)
   }  

   getCarteStagiareEns(carteRequest){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/reporting/"
      return axios.post(url, carteRequest, {responseType: "arraybuffer"}).then(res => res.data)
   }  

     getListeEtudiantInscriptionEc(idDepartement, idParcours, idAnneeAcademique, idNiveau, idSemestre){
      const url = this.adresse.getAdresseIP() 
      +"/service/api/campusfaso-lmd/campus-faso/inscription-ec?idDepartement="
      +parseInt(idDepartement)
      +"&idParcours="
      +parseInt(idParcours)
      +"&idAnneeAcademique="
      +parseInt(idAnneeAcademique)
      +"&idNiveau="
      +parseInt(idNiveau)
      +"&idSemestre="
      +parseInt(idSemestre)
      

      return axios.get(url)
                  .then(res => res.data);
     }


     getListeEtudiantInscriptionParcours(idDepartement, idParcours, idAnneeAcademique, idNiveau, idSemestre){
      const url = this.adresse.getAdresseIP() 
      +"/service/api/campusfaso-lmd/campus-faso/inscription-parcours?idDepartement="
      +parseInt(idDepartement)
      +"&idParcours="
      +parseInt(idParcours)
      +"&idAnneeAcademique="
      +parseInt(idAnneeAcademique)
      +"&idNiveau="
      +parseInt(idNiveau)
      +"&idSemestre="
      +parseInt(idSemestre)
      
      return axios.get(url)
                  .then(res => res.data);
     }

     getListeInscriptionEc(idDepartement, idParcours, idAnneeAcademique, idNiveau, idSemestre, listeEc){
      const url = this.adresse.getAdresseIP() 
      +"/service/api/campusfaso-lmd/campus-faso/inscription-ec?idDepartement="
      +parseInt(idDepartement)
      +"&idParcours="
      +parseInt(idParcours)
      +"&idAnneeAcademique="
      +parseInt(idAnneeAcademique)
      +"&idNiveau="
      +parseInt(idNiveau)
      +"&idSemestre="
      +parseInt(idSemestre)
      

      return axios.post(url, listeEc)
                  .then(res => res.data);
     }

     updateEtudiant(idEtudiant ,etudiant) {
      const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etudiant/"
      return axios.put(url+idEtudiant, etudiant)
                  .then(res => res.data);
  }

  deleteAllStudents(idAnnee, idParcours, idNiveau){
      const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/inscription-delete-all?idAnnee="+idAnnee+"&idParcours="+idParcours+"&idNiveau="+idNiveau

      console.log(url)
      return axios.patch(url)
               .then(res => res.data);
  }

  deleteStudent(idAnnee, idParcours, idNiveau, idEtudiant){
   const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/inscription-delete-etudiant?idAnnee="+idAnnee+"&idParcours="+idParcours+"&idNiveau="+idNiveau+"&idEtudiant="+idEtudiant
   return axios.patch(url)
            .then(res => res.data);
  }


  getEtudiantByIne(ineEtudiant){
   const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etudiant-ine?ine="+ineEtudiant
   return axios.get(url)
           .then(res => res.data)
}
  getInformationEtudiantByIne(ineEtudiant){
      const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/get-stagiaires-by-ine/"+ineEtudiant
      console.log(url)
      return axios.get(url)
      .then(res => res.data);
  }


  getSessionInscriptionEns(idEtudiant){
   const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etudiant-inscription-pedagogique-ens?idEtudiant="+idEtudiant
      console.log(url)
      return axios.get(url)
      .then(res => res.data);
  }
     
    /*
    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen"
        return axios.get(url).then(res => res.data)
    }
    
    create(newExamen) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen"
        return axios.post(url,newExamen)
                    .then(res => res.data);
    }

    update(idExamen ,newExamen) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen/"
        return axios.put(url+idExamen, newExamen)
                    .then(res => res.data);
    }

    delete(idExamen) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen/"
        return axios.patch(url+idExamen)
                    .then(res => res.data)
    }
    */

    getFilesUploadStudent(idStagiaire) {
      const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/document-upload-stagiaires/"
      return axios.get(url+idStagiaire)
         .then(res => res.data)
    }

   //  downloadFileStudent(){
   //    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/download-file/"
   //    return axios.post(url)
   //       .then(res => res.data)
   //  }

    downloadFileStudent(fileDowload) {
      const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/download-file/"
      return axios.post(url,fileDowload)
                  .then(res => res.data);
  }

   

     deleteStudentENS(code) {
      const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/stagiaire/"
      return axios.patch(url+code)
                  .then(res => res.data)
  }


     getListeEtudiantInscriptionEcENS(idDepartement, idParcours, idAnneeAcademique, idNiveau, idSemestre, idTypeStagiaire){
      const url = this.adresse.getAdresseIP() 
      +"/service/api/campusfaso-lmd/campus-faso/inscription-parcours?idDepartement="
      +parseInt(idDepartement)
      +"&idParcours="
      +parseInt(idParcours)
      +"&idAnneeAcademique="
      +parseInt(idAnneeAcademique)
      +"&idNiveau="
      +parseInt(idNiveau)
      +"&idSemestre="
      +parseInt(idSemestre)
      +"&idTypeStagiaire="
      +parseInt(idTypeStagiaire)
      
      

      return axios.get(url)
                  .then(res => res.data);
     }


     getListePays(){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/liste-pays"
      return axios.get(url).then(res => res.data)
     }

     getLIsteDocument(idTypeStagiaire){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/document-stagiaires/"+idTypeStagiaire
      return axios.get(url).then(res => res.data)
     }

     completionDataEns(dataInscription, idEtudiant){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/stagiaire/"+idEtudiant
      return axios.put(url,dataInscription)
                  .then(res => res.data);

                  ///etudiant/{code}
     }

     
     /*getAttestationInscriptionEns(idEtudiant){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/attestation-inscription-ens?idEtudiant="+idEtudiant
      return axios.get(url, {responseType: "arraybuffer"}).then(res => res.data)
   }  */

   getAttestationEtudiantEns(requestAttestationEns){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/reporting/"
      return axios.post(url, requestAttestationEns, {responseType: "arraybuffer"}).then(res => res.data)
   }  


   initierPaiementLigdicash(structureData, headerData){
      return axios.post('https://app.ligdicash.com/pay/v01/redirect/checkout-invoice/create',structureData, headerData).then(res => res.data)
   }


   getParametresLigdicash(idSession){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/ligdi-cash/parametre/"+idSession
      return axios.get(url).then(res => res.data)
   }


   initierPaiementLigdicashBackend(dtoInitiationPaiement){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/ligdi-cash/init-transation"
      return axios.post(url, dtoInitiationPaiement).then(res => res.data)
   }


   testerTransactionAttenteLigdiCash(idEtudiant){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/ligdi-cash/check-transation-en-attente/"+idEtudiant
      return axios.get(url).then(res => res.data)
   }

   getInformationInscriptionAdministratif(idEtudiant){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/etudiant-last-inscription-administrative/"+idEtudiant
      console.log(url)
      return axios.get(url).then(res => res.data)
   }

   getNationaliteEtudiant() {
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/nationalites/"
      return axios.get(url).then(res => res.data)
   }

   saveNationaliteEtudiant(idEtudiant, idNationalite){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/nationalites?idEtudiant="+idEtudiant+"&idNationalite="+idNationalite
      return axios.post(url).then(res => res.data)
   }




}