import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class UserService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    
    getAll() {
        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/users"
        return axios.get(url).then(res => res.data) 
    }

    getListeRoles(){
        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/roles"
        return axios.get(url).then(res => res.data) 
    }


    getAllByDepartement(idDepartement) {
        
        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/users?idDepartement="+idDepartement
        return axios.get(url).then(res => res.data) 
    }

    getAllByEtablissement(idEtablissement) {

        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/users?idEtablissement="+idEtablissement
        return axios.get(url).then(res => res.data) 
    }

    getUserByUsername(username){
        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/users?identifiant="+username
        return axios.get(url).then(res => res.data)
    }

    updateUserPassword(userData){
        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/users-mdp"
        return axios.post(url, userData).then(res => res.data)
    }

    getAllByIesr(idIesr) {

        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/users?idIesr="+idIesr
        return axios.get(url).then(res => res.data) 
    }
    
    create(newUser) {
        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/users"
        return axios.post(url,newUser)
                    .then(res => res.data);
    }

    update(idUser ,newUserData) {
        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/users?idCompteUser="
        return axios.put(url+idUser, newUserData)
                    .then(res => res.data);
    }

    delete(idUser) {
        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/users"
        return axios.patch(url+idUser)
                    .then(res => res.data)
    }

}