import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';



export class DepartementService {
     constructor(){
         this.adresse = new AdresseIP()
     }


     getListeDepartement(idIesr) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/departement-lmd?idIesr="
        return axios.get(url+idIesr).then(res => res.data) 
    }


    getAll(){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/departement-lmd"
        return axios.get(url).then(res => res.data) 
    }


    getListeEtablissementEtab(idIesr) {
        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/ine-liste-etablissement?codeIes="
        return axios.get(url+idIesr).then(res => res.data)
    }

    
    
}