import React, { useState, useEffect } from 'react'
import { Menubar } from 'primereact/menubar';
import logoCampusFaso from '../images/campusfaso_logo_wb.png'
import { InputText } from 'primereact/inputtext';
import { Route } from 'react-router-dom';



import Structure from '../depotEnLigne/components/parametres/Structure';
import Dashbord from '../depotPrixExcellence/components/Dashbord'
import DashbordClient from '../depotPrixExcellence/components/DashbordClient'
import Domaine from '../depotPrixExcellence/components/parametres/Domaine'
import AppelCandidature from '../depotPrixExcellence/components/parametres/AppelCandidature'
import Prix from '../depotPrixExcellence/components/parametres/Prix'
import TypeDocument from '../depotPrixExcellence/components/parametres/TypeDocument'
import Session from '../depotPrixExcellence/components/Session'
import Membre from '../depotPrixExcellence/components/parametres/Membre'
import Equipe from '../depotPrixExcellence/components/parametres/Equipe'
import PieceJointe from '../depotPrixExcellence/components/parametres/PieceJointe'
import DepotPrix from '../depotPrixExcellence/components/DepotPrix'

import { StructureService } from '../depotEnLigne/service/StructureService';



const DepotPrixExcellence = (keycloak) => {

    const tokenDecoded = localStorage.getItem('tokenDecoded')
	const token = JSON.parse(tokenDecoded)
    const [structure, setStructure] = useState({});
    const structureService = new StructureService();
    
    let roles = null
    if(token.realm_access.roles) roles = token.realm_access.roles
    
    let campusStructure = 0, campusDemandeur = 0;

    const profiles = roles.filter(role => {
        
        if(role === "procedure-user"){
            campusDemandeur = 1
            localStorage.setItem('campusDemandeur', JSON.stringify(campusDemandeur))
        }
        /*else if(role === "campus-admin-departement"){
            campusStructure = 1
            localStorage.setItem('campusStructure', JSON.stringify(campusStructure))
        }*/
        else {
            campusStructure = 1
            localStorage.setItem('campusStructure', JSON.stringify(campusStructure))
        }
        return role
    })

    useEffect(() => {

        if(campusDemandeur){
            window.location.hash = "/procedure-dgrsi/dashbord-demandeur"
        }else if(campusStructure){
            window.location.hash = "/procedure-dgrsi/dashbord"
        }
        else{
            //window.location.hash = "/lmd/dashboard"
        }

    }, [])



    const itemsStructure = [
        
        {
            label: 'Tableau de bord',
            icon: 'pi pi-fw pi-power-offs',
            command:()=>{window.location.hash = "/procedure-dgrsi/dashbord"}
        },
        {
            label: 'Demandes',
            icon: 'pi pi-fw pi-pencils',
            items: [
                
                {
                    label: 'Session',
                    icon: 'pi pi-fw pi-align-left',
                    command:()=>{window.location.hash = "/procedure-dgrsi/parametres/session"}
                },
                {
                    label: "Prix de l'excellence",
                    icon: 'pi pi-fw pi-align-left',
                    command:()=>{window.location.hash = "/procedure-dgrsi/prix-excellence"}
                },
                {
                    label: "Status",
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure-dgrsi/status"}
                } 

            ]
        },
        {
            label: 'Paramètres',
            icon: 'pi pi-fw pi-pencils',
            items: [
                {
                    label: 'Domaines',
                    icon: 'pi pi-fw pi-align-left',
                    command:()=>{window.location.hash = "/procedure-dgrsi/parametres/domaine"}

                },
                {
                    label: 'Appel à candidature',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure-dgrsi/parametres/appel-candidature"}
                },
                {
                    label: 'Prix',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure-dgrsi/parametres/prix"}
                },
                {
                    label: 'Type document',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure-dgrsi/parametres/type-document"}
                },
                {
                    label: 'Pièces-jointes',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure-dgrsi/parametres/piece-jointe"}
                },
                {
                    label: 'Equipe',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure-dgrsi/parametres/equipe"}
                },
                {
                    label: 'Membre',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure-dgrsi/parametres/membre"}
                }
            ]
        }
        
    ];




    const itemsDemandeur = [
        {
            label: 'Tableau de bord',
            icon: 'pi pi-fw pi-power-offs',
            command:()=>{window.location.hash = "/procedure-dgrsi/dashbord-demandeur"}
        },
        
        {
            label: 'Dépot de dossier',
            icon: 'pi pi-fw pi-power-offs',
            items: [
                {
                    label: "Prix de l'Excellence",
                    icon: 'pi pi-fw pi-align-left',
                    command:()=>{window.location.hash = "/procedure-dgrsi/prix-excellence"}
                },
                {
                    label: "Statuts ",
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure-dgrsi/status"}
                } 

            ]

        }
    ];

    return (
        <div className="html">
            <div className="body">
                {token ?
                <div className="grid-container">
                    <header className="header">
                        <div className="header-text">
                            <i className="ti-user"></i>
                            <span>Bienvenue <span style={{color : "green"}}>{token.name} </span>{""}</span>
                                |  
                            <a href="#" onClick={()=>{localStorage.clear(); keycloak.keycloak.logout()}}> 
                                <i className="ti-close"></i> 
                                Déconnexion
                            </a> |
                            <a href="#" className="btn" onClick={()=> {window.location.reload()}} >
                                <i className="ti-home"></i> 
                                Accueil
                            </a>
                        </div> 
                    </header>
                    <aside className="sidenav">
                        <div className="logo">
                            <img src={logoCampusFaso} alt=""/>
                            <div className="txt">
                                Plateforme intégrée de <br/>
                                l’enseignement supérieur <br/>
                                et de la recherche
                            </div>
                        </div>
                    </aside>  

                    <main className="main">
                        <div className="cards titles" >
                            {
                                campusDemandeur ?
                                <Menubar model={itemsDemandeur} />
                                :
                                <Menubar model={itemsStructure} />
                            }
                        </div>

                        <div>
                    
                            <Route path="/procedure-dgrsi/dashbord" component={Dashbord}/>
                            <Route path="/procedure-dgrsi/dashbord-demandeur" component={DashbordClient}/>
                            
                            <Route path="/procedure-dgrsi/parametres/structure" component={Structure}/> 
                            <Route path="/procedure-dgrsi/parametres/domaine" component={Domaine}/> 
                            <Route path="/procedure-dgrsi/parametres/appel-candidature" component={AppelCandidature}/>     
                            <Route path="/procedure-dgrsi/parametres/prix" component={Prix}/>
                            <Route path="/procedure-dgrsi/parametres/type-document" component={TypeDocument}/>
                            <Route path="/procedure-dgrsi/parametres/session" component={Session}/>
                            <Route path="/procedure-dgrsi/parametres/membre" component={Membre}/>
                            <Route path="/procedure-dgrsi/parametres/equipe" component={Equipe}/>
                            <Route path="/procedure-dgrsi/parametres/piece-jointe" component={PieceJointe}/>
                            <Route path="/procedure-dgrsi/prix-excellence" component={DepotPrix}/>

                        </div>  
                    </main>
                    <div className="footer">
                        <ul className="liens">
                            <li><a href="">CGU</a></li>
                            <li><a href="">Politique de confidentialité</a></li>
                            <li><a href="">FAQ</a></li>
                        </ul>
                        <p>
                            &copy; 2021 &middot; 
                            MESRSI
                            &middot;Tous droits réservés
                        </p>
                    </div>
                   
                </div>
                 :
                 ""
                 }
            </div>
        </div> 
    )
}
export default DepotPrixExcellence
