import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { ToastContainer } from "react-toastify";
import ProgrammationService from "../service/ProgrammationService";
import { SemestreService } from "../../lmd/service/SemestreService";
import { CohorteServices } from "../service/CohorteServices";
import { Spinner } from "../../components/Spinner";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { AnneeService } from "../../lmd/service/AnneeService";
import DashboardService from "../service/DashboardService";
import {IesrService} from "../../lmd/service/IesrService"

function DashboardIesr() {
    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded);

    const {control:searchControl,setValue:searchSetValue} = useForm({defaultValues:{semestre:'',parcours:'',cohorte:''}});
    const [programmation, setprogrammation] = useState({nbProgrammer:undefined,nbRealiser:undefined});
    const [listSemestreSearch, setListSemestreSearch] = useState([]);
    const [listeAnnee, setListeAnnee] = useState([]);
    const [annee, setAnnee] = useState({});
    const [departements, setDepartements] = useState([]);
    const [departement, setDepartement] = useState({});
    const [etablissements, setEtablissements] = useState([]);
    const [etablissement, setEtablissement] = useState({});
    const [loading, setloading] = useState(false);
    const [listCohorteSearch, setlistCohorteSearch] = useState([]);
    const [programmationByParcours, setprogrammationByParcours] = useState([]);
    const [programmationFilter, setprogrammationFilter] = useState([]);
    const [listParcours, setlistParcours] = useState([]);
    const [listeElementsEffectues, setListeElementsEffectues] = useState([]);
    const [enseignant, setEnseignant] = useState(null);
    const [infoVhEns, setInfoVhEns] = useState(null);
    const [ficheSuiviDialog, SetFicheSuiviDialog] = useState(false);
    const [semestre, setSemestre] = useState({});
    const [listeSemestre, setListeSetSemestre] = useState([]);
    const [cohorte, setCohorte] = useState(null);
    const [listeCohorte, setListeCohorte] = useState([]);
    const [listeActiviteDep, setListeActiviteDep] = useState([]);
    const [listeVarPie, setListeVarPie] = useState(null);
    const [isAffiche, setIsAffiche] = useState(false);
    const [listeIes, setListeIes] = useState([]);
    const [ies, setIes] = useState(null);
    const [listeEtablissement, setListeEtablissement] = useState([]);
    const [etab, setEtab] = useState(null);
    const [listeDepartement, setListeDepartement] = useState([]);
    const [listeEc, setListeEc] = useState([]);
    const [ec, setEc] = useState(null);
    const [chartData, setCharData] = useState({})
    const [lineData, setLineData] = useState({})
    const [ficheSuiviEcDialog, setFicheSuiviEcDialog] = useState(null)

    const programmationService = new ProgrammationService();
    const semestreService = new SemestreService();
    const cohorteServices = new CohorteServices();
    const spinner = new Spinner();
    const anneeService = new AnneeService();
    const dashboardService = new DashboardService();
    const iesrService = new IesrService();
    
    const lightOptionsCombo = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });
    

    useEffect(() => {
        /*setloading(true);
        programmationService.getProgrammationDepartement(code_departement).then((response=[])=>{
            let nbProgrammer = response.filter(data=>!data.valideChef && !data.valideEnseignant && !data.valideSP).length
            let nbRealiser = response.filter(data=>data.valideChef && data.valideEnseignant && data.valideSP).length;
            setprogrammation({...programmation,nbProgrammer,nbRealiser});
        })
        .finally(()=>setloading(false))*/

        //cohorteServices.getListeParcoursDepartement(code_departement).then(response=>{console.log(response);setlistParcours(response)})

        
        anneeService.getAll().then(data => {
            setListeAnnee(data);  
            
        })
        cohorteServices.getListeEtabUniv(token.code_iesr).then(data => {
            console.log(data)
            setEtablissements(data)
            
        })
        .catch((error) =>{
            console.log(error)
        })
        /*cohorteServices.getListeCohorteDepartement(token.code_departement).then(data => {
            setListeCohorte(data)    
        })
        .catch(error => {
            console.log(error)
        })*/
        semestreService.getAll().then(data => {
            setListeSetSemestre(data)
        })

    }, []);


    const handleParcoursSearch = (parcours)=>{
        setloading(true);
        searchSetValue("niveau","");
        setListSemestreSearch([]);
        setlistCohorteSearch([]);
        semestreService.get(parcours.idParcours).then(response=>{
            setListSemestreSearch(response);
        })
        programmationService.getProgrammationByParcours(token.code_departement,parcours.idParcours)
        .then((response=[])=>{
            setprogrammationByParcours(response);
            let nbProgrammer = response.filter(data=>!data.valideChef && !data.valideEnseignant && !data.valideSP).length
            let nbRealiser = response.filter(data=>data.valideChef && data.valideEnseignant && data.valideSP).length;
            setprogrammation({...programmation,nbProgrammer,nbRealiser});
        })
        .finally(()=>setloading(false));
    }


    const handleSemestreSearch=semestre=>{
        // setListCohorteSearch([]);
        let result = programmationByParcours.filter(programmation=>programmation.idSemestre === semestre.idSemestre);
        setprogrammationFilter(result);

        let nbProgrammer = result.filter(data=>!data.valideChef && !data.valideEnseignant && !data.valideSP).length
        let nbRealiser = result.filter(data=>data.valideChef && data.valideEnseignant && data.valideSP).length;
        setprogrammation({...programmation,nbProgrammer,nbRealiser});
        // programmationGet(result);
        let newListCohorte = [];
        let listCohorte =[];
        result.forEach(prog=>{
            prog.cohorte.forEach(cohor=>{
                listCohorte.push(cohor);
            })
        })
        let cohortesID=new Set();
        for(let element of listCohorte){
            cohortesID.add(element.idCohorte);
        }
        for(let index of cohortesID ){
            newListCohorte.push(listCohorte.find(cohorte=>cohorte.idCohorte === index));
        }
        setlistCohorteSearch([...newListCohorte]);
        
    }

    const onChampsChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        if (field === 'ies'){
            setIes(val)
            cohorteServices
                    .getListeEtabUniv(val.idIesr)
                    .then((data) => {
                        setListeEtablissement(data);
                        setListeDepartement([]);
                    })
                    .catch((error) => {
                        console.log(error);
                        
            });
        }
        if (field === 'etab'){
            setEtab(val)
            cohorteServices
                    .getListeDepartementEtab(val.idEtablissement)
                    .then((data) => {
                        setListeDepartement(data.departementEtabDtos);
                    })
                    .catch((error) => {
                        console.log(error);
                        
            });
        }
        if (field === 'dept'){
            setDepartement(val)
            cohorteServices
                    .getListeCohorteDepartement(val.idDepartementEtab)
                    .then((data) => {
                        setListeCohorte(data);
                    })
                    .catch((error) => {
                        console.log(error);
                        
            });
        }
        if (field === 'cohorte'){
            setCohorte(val)   
        }
        
    }
    
    const onDepartementChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        setDepartement(val)
        cohorteServices.getListeCohorteDepartement(val.idDepartementEtab).then(data => {
            setListeCohorte(data)
            //console.log(data)
        })
    }

    const onEtablissementChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        setEtablissement(val)
        cohorteServices.getListeDepartementEtab(val.idEtablissement).then(data => {
            setDepartements(data.departementEtabDtos)
            //console.log(data)
        })
    }

    const onAnneeChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        setAnnee(val)
    }

    const onSemestreChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setSemestre(val)
    }

    const onIesChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setIes(val)
        const matricule = token.matricule
        cohorteServices.getListeCohorteIesEns(val.idIesr, annee.idAnneeAcademique, matricule).then(data => {
            setListeCohorte(data);
            //console.log(data)
        })
    }

    const onEcChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setEc(val)
    }

    const listeActivite = () => {
        //console.log(annee)
        const matricule = token.matricule
        dashboardService.getInfoVhEns(matricule, annee.idAnneeAcademique).then(dataVh => {
            setInfoVhEns(dataVh)
            //console.log(dataVh)
        })
        dashboardService.getActivites(annee.idAnneeAcademique, matricule).then(data => {
            const elements = data.elementConstitutifTbDtoList
            let nbHeureRestant = null
            let listeElement = []
            let element = null
            elements.map(el => {
                nbHeureRestant = el.nombreHeureConf - el.nombreHeure
                element = {
                    dateSeanceEff: el.dateSeanceEff,
                    departement: el.departement,
                    idElementConstitutif: el.idElementConstitutif,
                    idIes: el.idIes,
                    idTypeActivite: el.idTypeActivite,
                    libelleElementConstitutif: el.libelleElementConstitutif,
                    libelleIes: el.libelleIes,
                    libelleTypeActivite: el.libelleTypeActivite,
                    libelleUniteEnseignement: el.libelleUniteEnseignement,
                    nombreHeure: el.nombreHeure,
                    nombreHeureConf: el.nombreHeureConf,
                    nombreHeureRestant : nbHeureRestant
                }
                listeElement.push(element)

            })
            setListeElementsEffectues(listeElement)
        })
    }

    const listeActDepartement = () => {
        
        const idDepartement = departement.idDepartementEtab
        
        let idCohorte = (cohorte && cohorte.idCohorte)?cohorte.idCohorte:null
        let idAnnee = (annee && annee.idAnneeAcademique)?annee.idAnneeAcademique:null
        let idSemestre = (semestre && semestre.idSemestre)? semestre.idSemestre:null


        if(idDepartement && idAnnee){

        
        dashboardService.getActivitesDepartement(idDepartement, idCohorte, idAnnee, idSemestre).then(data => {
            //console.log(data.data)
            //console.log(data.data.departementActivitesTbDtos)
            //setListeActiviteDep(data.departementActivitesTbDtos)
            setIsAffiche(true)
            const elements = data.data.departementActivitesTbDtos
            let nbHeureRestant = null
            let listeElement = []
            let listeVarPie = []
            let element = null
            let execute = 0, encours = 0, nonexecute = 0;

                elements.map(el => {
                    nbHeureRestant = el.nombreHeureConf - el.nombreHeure
                    element = {
                        codeElementConstitutif: el.codeElementConstitutif,
                        flag: el.flag,
                        idCohorte: el.idCohorte,
                        idElementConstitutif: el.idElementConstitutif,
                        idEnseignant: el.idEnseignant,
                        idParcours: el.idParcours,
                        idSemestre: el.idSemestre,
                        idTypeActivite: el.idTypeActivite,
                        lbCohorte: el.lbCohorte,
                        lbElementConstitutif : el.lbElementConstitutif,
                        lbParcours : el.lbParcours,
                        lbSemestre : el.lbSemestre,
                        lbTypeActivite : el.lbTypeActivite,
                        nomEnseignant : el.nomEnseignant,
                        nombreHeure: el.nombreHeure,
                        nombreHeureConf: el.nombreHeureConf,
                        nombreHeureRestant : nbHeureRestant
                    }
                    
                    if(el.flag === 0) nonexecute++;
                    else if(el.flag === 1) encours++;
                    else if(el.flag === 2) execute++;
                    else;
                    listeElement.push(element)

                })

     
            setListeActiviteDep(listeElement)
            let pieData = {
                labels: ['executés', 'en cours', 'non executés'],
                datasets: [
                    {
                        data: [execute, encours, nonexecute],
                        backgroundColor: [
                            "#66BB6A",
                            "#FFA726",
                            "red",
                            
                        
                        ],
                        hoverBackgroundColor: [
                            "#81C784",
                            "#FFB74D",
                            "red",
                        
                            
                        ]
                    }
                ]
            }
            setCharData(pieData)

        })

   


        dashboardService.getActiviteDepMonth(idDepartement, idAnnee).then(data => {
            //console.log(data);
            let donneeSeanceEff = [];
            let donneeSeanceProg = [];
            let mois = [];
            data.map(d => {
                    mois.push(d.lbMois)
                    donneeSeanceEff.push(d.nbSeanceEff)
                    donneeSeanceProg.push(d.nbSeanceProg)
            })
            const chartLineCombo = {
                labels: mois,
                datasets: [
                    {
                        type: 'line',
                        label: 'séances programmées',
                        borderColor: '#42A5F5',
                        borderWidth: 2,
                        fill: false,
                        tension: .4,
                        data: donneeSeanceProg  
                    }
                    ,
                    {
                    type: 'line',
                    label: 'séances effectuées',
                    borderColor: '#66BB6A',
                    borderWidth: 2,
                    fill: false,
                    tension: .4,
                    data: donneeSeanceEff
                }
                
            ]
            };
            setLineData(chartLineCombo)
            const lightOptionsCombo = {
                maintainAspectRatio: false,
                aspectRatio: .6,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            };
        })

    }

    }




    const ficheSuivi = () => {
        semestreService.getAll().then(data => {
            setListeSetSemestre(data)
        })

        SetFicheSuiviDialog(true)
    }

    const ficheSuiviEc = () => {

        iesrService.getAll().then(data => {
            setListeIes(data)
            //console.log(data)
        })

        anneeService.getAll().then(data => {
            setListeAnnee(data)
        })

        setFicheSuiviEcDialog(true)
    }

    const ficheSuiviPdf = () => {
        const matricule = token.matricule
        let fiche = {
            reportFormat: "PDF",
            reportType: "FICHE_SEANCE_EFFECTUEE",
            annee: parseInt(annee.idAnneeAcademique),
            matriculeEnseignant: matricule,
            idSemestre : semestre.idSemestre
        }
        //console.log("👌")
        //console.log(fiche)
        dashboardService.afficherFicheSuivi(fiche).then(res => {
            const matricule = token.matricule
            var blob = new Blob([res.data], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = `fiche_suivi_${matricule}`;
            link.download = fileName;
            link.click();
                  

        }).catch(error => {
            console.log(error)
        })
    }

    const ficheSuiviEcPdf = () => {
        const matricule = token.matricule
        let fiche = {
            reportFormat: "PDF",
            reportType: "FICHE_VACATION",
            annee: parseInt(annee.idAnneeAcademique),
            matriculeEnseignant: matricule,
            idCohorte : cohorte.idCohorte,
            idElementConstitutif : ec.idElementConstitutif
        }
        //console.log("👌")
        //console.log(fiche)
        dashboardService.afficherFicheSuiviEc(fiche).then(res => {
            const matricule = token.matricule
            var blob = new Blob([res.data], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = `fiche_suivi_${matricule}`;
            link.download = fileName;
            link.click();
                  

        }).catch(error => {
            console.log(error)
        })
    }

    const ficheSuiviDepartement = () => {
        
        let fiche = {
            reportFormat: "PDF",
            reportType: "FICHE_SUIVI_DEPART",
            idDepartement : token.code_departement,
            annee: (annee && annee.idAnneeAcademique)?parseInt(annee.idAnneeAcademique):null,
            idCohorte : (cohorte && cohorte.idCohorte)?parseInt(cohorte.idCohorte):null,
            idSemestre : (semestre && semestre.idSemestre)?parseInt(semestre.idSemestre):null
        }
        //console.log("👌")
        //console.log(fiche)
        dashboardService.afficherFicheSuiviDepartement(fiche).then(res => {

            var blob = new Blob([res.data], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = `fiche_suivi_${token.code_departement}`;
            link.download = fileName;
            link.click();         

        }).catch(error => {
            console.log(error)
        })
    }

    const hideFicheSuiviDialog = () => {
        SetFicheSuiviDialog(false)
    }

    const hideFicheSuiviEcDialog = () => {
        setFicheSuiviEcDialog(false)
    }

    // const handleCohorteSearch = cohorte=>{
    //     let result =[] ;
    //     programmationFilter.forEach(programmation=>{
    //         programmation.cohorte.filter(programmation=>programmation.idCohorte === cohorte.idCohorte)
    //         for(let element of programmation.cohorte){
    //             if(element.idCohorte === cohorte.idCohorte){
    //                 result.push(programmation);
    //             }
    //         }
    //     });
    //     // programmationGet(result);
    // }

    const ficheSuiviDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideFicheSuiviDialog} />
            <Button label="Affichier la fiche" icon="pi pi-times" className="p-button-text" onClick={ficheSuiviPdf} />
        </React.Fragment>
    );

    const ficheSuiviEcDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideFicheSuiviEcDialog} />
            <Button label="Affichier la fiche" icon="pi pi-times" className="p-button-text" onClick={ficheSuiviEcPdf} />
        </React.Fragment>
    );

    return(
        <React.Fragment>

            <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
            />
            <div className="card">
            {
                loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                      
                <div className="p-col-12 p-grid">
                  {/*<div className="p-col-12 p-grid ">
                    <Button  title="Affectation de cours"  className="p-col-1 mr-2" onClick={()=>window.location.hash = "/volume-horaire/Gestion-affectation"} label="Affectation" />
                    <Button  title="Programmation de cours" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/programmation-activite"} label="Programmation" />
                    <Button  title="Emplois du temps" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/emplois-du-temps"} label="Emplois du temps" />
                    <Button  title="Infrastructure" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/parametres/gestion-infrastructure/gererInfrastructures"} label="Infrastructure" />
                    <Button  title="Séances Effectuées" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/seance-effectuer"} label="Séances Effectuées" />
                    <Button  title="Fiche pédagogique" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/Gestion-fiches-pedagogique"} label="Fiche pédagogique" />  
                 </div>*/}
                 
                 <div className="p-col-12 p-grid" style={{marginTop : 20}}>
                    <div className="p-grid">
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee"  value={annee} options={listeAnnee}  placeholder="Choisir une année"/>
                        {/*<Dropdown style={{marginLeft : 10}} onChange={(e) => {onChampsChange(e, "ies")}} optionLabel="libelleIesr" value={ies} options={listeIes}  placeholder="Choisir IESR"/>*/}
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onChampsChange(e, "etab")}} optionLabel="nom" value={etab} options={etablissements}  placeholder="Choisir Etablissement"/> 
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onChampsChange(e, "dept")}} optionLabel="lbDepartementEtab" value={departement} options={listeDepartement}  placeholder="Choisir Département"/>           
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onChampsChange(e, "cohorte")}} optionLabel="libelleCohorte" optionValue={cohorte} value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                        <Dropdown   optionLabel="libelleSemestre" optionValue={semestre} value={semestre} options={listeSemestre} placeholder="Choisir un semestre" onChange={(e) => {onSemestreChange(e, "semestre")}} required autoFocus/>
                        <Button style={{marginLeft : 10}} label="Lister" onClick={listeActDepartement} disabled={!annee.idAnneeAcademique} /*{!token.code_departement || !annee}*//>
                        <Button style={{marginLeft : 30}} label="Fiche suivi" onClick={ficheSuiviDepartement} disabled={!annee.idAnneeAcademique} />
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable  value={listeActiviteDep} header="Etat d'execution des cours"  paginator={true} rows={5}
                            filterMatchMode="contains" responsive={true}  >
                            <Column field="lbElementConstitutif" header="ECUE"/>
                            <Column field="lbTypeActivite" header="Activité" />
                            <Column style={{color : "green"}} field="nombreHeure" header="Nb.heure eff." />
                            <Column field="nombreHeureConf" header="Nb.heure confié" />
                            <Column style={{color : "red"}} field="nombreHeureRestant" header="Nb.heure restant" />
                            <Column field="nomEnseignant" header="Enseignant" />
                        </DataTable>
                    </div>
                 </div>
                 <div className="p-col-12 p-grid">
                    <div className="p-col-5" >
                        <h5>Etats d'avancement des cours</h5>
                        <Chart type="line" data={lineData} options={lightOptionsCombo} />
                    </div>
                    <div className="p-col-1"></div>
                    <div className="p-col-5 flex justify-content-center">
                        {isAffiche ?
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                            :
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                        }
                        
                    </div>
                 </div>
                    
            
            </div>
            }
            </div>
            
        </React.Fragment>
    )
}

export default DashboardIesr