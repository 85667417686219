import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { StructureService } from '../../service/StructureService'
import { DemandeService } from '../../service/DemandeService'
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import { SessionService } from '../../service/SessionService'

export class DemandeEquivalence extends Component {

    constructor() {

        super();
        this.state = {
            session : null,
            loadSpin: false,
            listeSession: [],
            demandeDialog: false,
            deleteDemandeDialog: false,
            demande: {},
            listeDemande: [],
            demandesSession: [],
            submitted: false,
            loading : false,
            globalFilter: null,
            file: null,
            informationDiplome: {
                intituleDiplome: "",
                anneeObtention: "",
                etablissementObtention: "",
                scanDiplome: null,
                origineDiplome: "NATIONALE"
            },
            listeDiplomes: [],
            isDemandeur : 0,
            isStructure : 0,
            idClient: null,

            ajoutDiplomeDialog: false,
            newDiplome: {
                intituleDiplome: "",
                anneeObtention: "",
                etablissementObtention: "",
                scanDiplome: null
            },
            ajoutDiplomeSubmited: false,
            viewListeDemandeDialog: false,
            typeOperation: "RECONNAISSANCE_DIPLOME",
            loadingListeDemande: false,
            viewDetailsDemandeDialog: false,
            listeDemandeDialog : false,
            typeDocument: "Diplome"
        }

        this.spinner = new Spinner()
        this.notify = new Notify()
        this.structureService = new StructureService()
        this.demandeService = new DemandeService()
        this.sessionService = new SessionService()
    }

    componentDidMount = () => {

        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)

        console.log(token)

        this.setState({
            idClient: token.email,
            nomPrenom: token.name
        })

        const campusStructure = localStorage.getItem('campusStructure')
        const campusDemandeur = localStorage.getItem('campusDemandeur')
        const isStructure = parseInt(JSON.parse(campusStructure))
        const isDemandeur = parseInt(JSON.parse(campusDemandeur))

        const structure = localStorage.getItem('structure')
        const struct = JSON.parse(structure)
        console.log(struct)
        this.setState({structure : struct});

        let today = new Date();
        let year = today.getFullYear();

        this.setState({loading : true})
        if(isDemandeur){
            this.setState({isDemandeur : 1})
            this.sessionService.get(year,385, "NIVEAU_III", "OUVERT", this.state.typeOperation).then(data => {
                console.log(data)
                this.setState({listeSession : data})
                this.setState({loading :false})
            })
            .catch(error => {
                console.log(error)
                this.setState({loading :false})
            })

            /*this.sessionService.getListeSessionTypeDemande("RECONNAISSANCE_DIPLOME").then(data => {
                console.log(data)
                this.setState({listeSession : data, loading : false})
            })
            .catch(error => {
                console.log(error)
                this.setState({loading : false})
            })*/
        }
        else if(isStructure){
            this.setState({isStructure : 1})
            
            this.sessionService.get(year, struct.idDepartementEtab, 'NIVEAU_III', "OUVERT", this.state.typeOperation).then(donnee => {
                console.log(donnee);
                this.setState({listeSession : donnee, loading : false});
            })
            .catch(error => {
                console.log(error);
                this.notify.echec(error.response.data.message);
            })

            /*this.sessionService.getListeSessionTypeDemande("RECONNAISSANCE_DIPLOME").then(data => {
                console.log(data)
                this.setState({listeSession : data, loading : false})
            })
            .catch(error => {
                console.log(error)
                this.setState({loading : false})
            })*/
            
        }
        else {
            console.log("bug")
        }
       

    }

    openNew = (session) => {

        console.log(session)
            this.setState({
                session : session,
                demande: {
                    idDemande: null,
                    idSession: session.idSession,
                    nomPrenom: this.state.nomPrenom,
                    listeDiplomes: [],
                    etablissementPublic: false,
                    estBoursier: false,
                    justificationBoursier: null,
                    universiteSousRegion: true,
                    siteWebEtablissement: "",
                    mailEtablissement: "",
                    telephoneUniversite: "",
                    diplomeConcerne: null,
                    diplomeAcces: "",
                    nombreAnneeFormation: "",
                    typeDocumentProduit: "RAPPORT",
                    themeDocumentProduit: ""
                },
                submitted: false,
                demandeDialog: true
            })

    }

    hideDialog = () => {
        this.setState({
            submitted: false,
            demandeDialog: false,
            demande: {
                idDemande: null,
                listeDiplomes: []
            },
            listeDiplomes: []
        });
    }

    onValueChange = (e, field) => {

        const val = (e.target && e.target.value) || '';
        let demande = this.state.demande; 
        demande[`${field}`] = val;
        this.setState({demande})     
    }


    onDiplomeValueChange = (e, field) => {

        const val = (e.target && e.target.value) || '';
        let informationDiplome = this.state.informationDiplome; 
        informationDiplome[`${field}`] = val;
        this.setState({informationDiplome})     
    }


    saveDemande = () => {

        this.setState({
            submitted: true,
            loadSpin: true
        })

        let curentDemande = this.state.demande

        curentDemande['idClient'] = this.state.idClient
        curentDemande['typeDemande'] = "RECONNAISSANCE_DIPLOME"

        console.log(curentDemande)
        
        if (
                curentDemande.listeDiplomes 
                && 
                curentDemande.listeDiplomes.length>0 
                &&
                curentDemande.nomEtablissement
                &&
                curentDemande.siteWebEtablissement
                &&
                curentDemande.mailEtablissement
                && 
                curentDemande.telephoneUniversite
                &&
                curentDemande.diplomeConcerne
                &&
                curentDemande.diplomeAcces
                &&
                curentDemande.nombreAnneeFormation
                &&
                curentDemande.themeDocumentProduit
            ) {

                this.demandeService.create(curentDemande).then(data => {
                    this.notify.success()
                    this.setState({
                        loadSpin: false
                    })
                    this.hideDialog()
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    this.setState({
                        loadSpin: false
                    })
                    console.log(error);
                })

            }

        
    }


    editDemande = (demande) => {
        this.setState({
            listeDiplomes: demande.listeDiplomes,
            demande: demande,
            demandeDialog: true

        })
    }

    detailDemande = (demande) => {
        this.setState({
            listeDiplomes: demande.listeDiplomes,
            demande: demande,
            demandeDialog: true

        })
    }


    hideDeleteDemandeDialog = () => {
        this.setState({ deleteDemandeDialog: false });
    }


    deleteDemande = () => {
        let demande = this.state.demande

        this.setState({
            loadSpin: true
        })

        
        this.demandeService.delete(demande.idDemande).then(data => {
            this.notify.success()
            this.setState({
                loadingListeDemande : true,
                loadSpin: false
            })

            this.demandeService.getDemandeByTypeOperationIdClient(this.state.typeOperation, this.state.idClient, this.state.session.idSession).then(data => 
                this.setState({listeDemande:data, loadingListeDemande : false})
            ).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
                this.setState({loadingListeDemande : false})
            })
        })
        .catch(error => {
            this.notify.echec(error.response.data.message)
            this.setState({
                loadSpin: false
            })
            console.log(error);
        })
        
        this.hideDeleteDemandeDialog()
        
    }


    uploadDiplomeHandler = ({files}) => {

       let demande = this.state.demande
        const [file] = files;
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            demande['diplomeConcerne'] = e.target.result

           this.setState({ demande })

           console.log(demande)
        };
        fileReader.readAsDataURL(file);
      };


      uploadJustificationBoursierHandler = ({files}) => {

        let demande = this.state.demande
         const [file] = files;
         const fileReader = new FileReader();
         fileReader.onload = (e) => {
            demande['justificationBoursier'] = e.target.result
 
            this.setState({ demande })
            console.log(demande)
         };
         fileReader.readAsDataURL(file);
       };
 



      fileUploadHandler = ({files}) => {

       let informationDiplome = this.state.informationDiplome
        const [file] = files;
        const fileReader = new FileReader();
        fileReader.onload = (e) => {

            informationDiplome['scanDiplome'] = e.target.result
           this.setState({ informationDiplome})
        };
        fileReader.readAsDataURL(file);
      };


      viewDiplome = (diplomeInformation) => {
        this.setState({
            curentFile: diplomeInformation.scanDiplome,
            viewDiplomeDialog: true
        })
      }

      hideDiplome = () => {
        this.setState({
            curentFile: null,
            viewDiplomeDialog: false
        })
      }



      typeEtablissementChange = (value) => {
        let demande = this.state.demande


        console.log(value)
        demande['etablissementPublic'] = value

        this.setState({
            demande
        })
      } 

      statutEtudiantChange = (value) => {
        let demande = this.state.demande

        console.log(value)
        demande['estBoursier'] = value

        if(!value){
            demande['justificationBoursier'] = null
        }

        this.setState({
            demande
        })
        console.log(demande)

      }


      regionChange = (value) => {
        let demande = this.state.demande

        console.log(value)
        demande['universiteSousRegion'] = value

        this.setState({
            demande
        })

      }


      typeDocumentChange  = (value) => {
        let demande = this.state.demande

        console.log(value)
        demande['typeDocumentProduit'] = value

        this.setState({
            demande
        })

      }



      confirmDeleteDiplome = (diplomeData) => {

        let curentListeDiplome = this.state.demande.listeDiplomes
        let demande = this.state.demande

        let newListeDiplome = curentListeDiplome.filter((data)=>{
            return data.intituleDiplome !== diplomeData.intituleDiplome
        })

        demande['listeDiplomes'] = newListeDiplome

        this.setState({
            listeDiplomes: newListeDiplome
        })
      }



      hideAddDiplome = () => {

        this.setState({
            ajoutDiplomeDialog: false,
            ajoutDiplomeSubmited: false,
            newDiplome: {
                intituleDiplome: "",
                anneeObtention: "",
                etablissementObtention: "",
                scanDiplome: null
            },
            typeDocument: "Diplome"
            
        })
      }


      ajoutDiplome = () => {
        this.setState({
            ajoutDiplomeSubmited: true
        })


        if(
            this.state.informationDiplome.intituleDiplome 
            && 
            this.state.informationDiplome.intituleDiplome.trim() 
            && 
            this.state.informationDiplome.scanDiplome 
            //&&
            //this.state.informationDiplome.anneeObtention 
            //&& 
            //this.state.informationDiplome.anneeObtention.trim()
            //&&
            //this.state.informationDiplome.etablissementObtention 
            //&& 
            //this.state.informationDiplome.etablissementObtention.trim()
            ){
            
            let listeDiplomes = this.state.demande.listeDiplomes
            let demande = this.state.demande

            listeDiplomes.push(this.state.informationDiplome);

            demande['listeDiplomes'] = listeDiplomes

            this.setState({
                demande,
                informationDiplome: {
                    intituleDiplome: "",
                    anneeObtention: "",
                    etablissementObtention: "",
                    scanDiplome: null,
                    origineDiplome: "NATIONALE"
                }
            })

            this.hideAddDiplome()

        }
      }


      ajouterNouveauDiplome = () => {
        this.setState({
            ajoutDiplomeDialog: true
        })
      }

      listeDemandeSession = (session) => {
        this.setState({listeDemandeDialog : true, loading : true})
        this.demandeService.getDemandeSession(session.idSession).then(data => {
            console.log(data)
            this.setState({demandesSession : data, loading : false})
        })
        .catch(error => {
            console.log(error)
            this.setState({loading : false})
        })
      }

      viewDepot = (sessionData) => {
        console.log(sessionData)

        this.setState({
            session: sessionData
        })

        this.demandeService.getDemandeByTypeOperationIdClient(this.state.typeOperation, this.state.idClient, sessionData.idSession).then(data => {

            if(!data || data.length===0){
                alert("Vous n'avez aucun dossier valide pour l'instant! Veillez déposer d'abord votre dossier")
            }else{

                console.log(data)

                this.setState({
                    listeDemande: data,
                    viewListeDemandeDialog: true
                })
            }

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }


    hideListeDemande = () => {
        this.setState({
            listeDemande: [],
            viewListeDemandeDialog: false
        })
    }

    viewDepotDemande = (viewData) => {
       this.setState({
        viewDetailsDemandeDialog: true,
        demande: viewData
       })
    }

    confirmDeleteDemande = (demande) => {
        this.setState({
            demande,
            deleteDemandeDialog: true
        });
    }

    hideDetailDemande = () => {
        this.setState({
            demande: {},
            viewDetailsDemandeDialog: false

        })
    }

    hideListeDemandeSessionDialog = () => {
        this.setState({
            listeDemandeDialog: false,
        })
    }

    viewDocumentByUrl = (urlDocument) => {

        let documentDto = {
            id: this.state.demande.idDemande,
            urlFile: urlDocument 
        }
        console.log(documentDto)

        this.demandeService.getDocumentsDemande(documentDto).then(data => {
            console.log(data)

            this.setState({
                curentFile: data.value,
                viewDiplomeDialog: true
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

        this.setState({
            //curentFile: diplomeInformation.scanDiplome,
            //viewDiplomeDialog: true
        })
    }


    origineDiplomeChange = (optionValue) => {
        let informationDiplome = this.state.informationDiplome
        console.log(optionValue)
        informationDiplome['origineDiplome'] = optionValue

        this.setState({
            informationDiplome
        })
      } 

      visualiserDetailDemande = (demande) => {
        let tabDemande = []
        tabDemande.push(demande)
        this.setState({
            listeDemande: tabDemande,
            viewListeDemandeDialog: true
        
        })
      }


      typeDiplomeChange = (value) => {
        let infosDiplome = this.state.informationDiplome

        if(value==="Diplome"){
            infosDiplome['origineDiplome'] = "NATIONALE"
        }else{
            infosDiplome['origineDiplome'] = null
        }

        this.setState({
            typeDocument: value
        })
      }


      

    actionBodyTemplate = (rowData) => {
        return (
            this.state.isDemandeur ?
                <React.Fragment>
                    <Button type="button" label="Déposer" icon="pi pi-folder-open" className="p-button-success" onClick={() => this.openNew(rowData)} style={{marginRight: '.5em'}}/>
                    {/*<Button type="button" label="Visualiser" icon="pi pi-eye" className="p-button-success" onClick={() => this.viewDepot(rowData)} style={{marginRight: '.5em'}}/>*/}
                </React.Fragment>
                :
                <React.Fragment>
                    <Button type="button" icon="pi pi-list" className="p-button-success" onClick={() => this.listeDemandeSession(rowData)} style={{marginRight: '.5em'}}/>
                </React.Fragment>
        );
    }

    listeDemandActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-eye" className="p-button-success" onClick={() => this.viewDepotDemande(rowData)} style={{marginRight: '.5em'}}/>
                {/*<Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteDemande(rowData)}/>*/}
            </React.Fragment>
        );
    }

    diplomeActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteDiplome(rowData)}/>
            </React.Fragment>
        );
    }

    ButtonVisualisationDocument = (rowData) => {
        return ( <Button label="Visualiser" severity="success" rounded onClick={() => this.viewDocumentByUrl(rowData.scanDiplome)} 
        />)
    }

    visualisationButton = (rowData) => {
        return ( <Button label="Visualiser" severity="success" rounded onClick={() => this.viewDiplome(rowData)} 
        />)
    }
    actionBodyTemplateDetailDemande = (rowData) => {
        return ( <Button icon="pi pi-eye" className="p-button-success" onClick={() => this.visualiserDetailDemande(rowData)}
        />)
    }

    render(){

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

        const demandeDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} disabled={this.state.loadSpin}/>
                <Button label="Enregistrer" icon={this.state.loadSpin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-text" onClick={this.saveDemande} disabled={this.state.loadSpin}/>
            </React.Fragment>
        );

        const detailsDemandeDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDetailDemande} />
            </React.Fragment>
        );


        const ajoutDiplomeDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideAddDiplome} disabled={this.state.loadSpin}/>
                <Button label="Ajouter" icon={this.state.loadSpin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-text" onClick={this.ajoutDiplome}  disabled={this.state.loadSpin}/>
            </React.Fragment>
        );

        const listeDemandeDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideListeDemande} />
            </React.Fragment>
        );

        const listeDemandeSessionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideListeDemandeSessionDialog} />
            </React.Fragment>
        );

        const diplomeDialogFooter = (
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDiplome} />
        )

        const deleteDemandeDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDemandeDialog}  disabled={this.state.loadSpin}/>
                <Button label="OUI" icon={this.state.loadSpin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-text" onClick={()=> this.deleteDemande() }  disabled={this.state.loadSpin}/>
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">

                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeSession} selectionMode="single" header={"Liste des sessions ouvertes"} paginator={true} rows={10}
                            globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleSession" header="Session"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="anneeSession" header="Année"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="libelleTypeDemande" header="Type de demande"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="libelleTypeSession" header="Type de session"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '10em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>


                <Dialog visible={this.state.demandeDialog} header="Formulaire de demande d'équivalence de diplôme" modal className="p-fluid" footer={demandeDialogFooter} onHide={this.hideDialog}>

                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="nomEtablissement">Etablissement d'obtention du diplôme</label>
                            <InputText id="nomEtablissement" autoFocus value={this.state.demande.nomEtablissement}  onChange={(e) => this.onValueChange(e, 'nomEtablissement')} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Etablissement d'obtention du diplôme"/>
                            {this.state.submitted && !this.state.demande.nomEtablissement && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <hr/>

                    <div className="p-field p-col-12">
                        <label>Type d'établissement</label>
                        <hr/>
                        <div className="p-grid">
                            <div className="p-field p-col-3">
                                <RadioButton inputId="etablissementPublic" name="etablissementPublic" value={true} onChange={(e) => this.typeEtablissementChange(e.value)} checked={this.state.demande.etablissementPublic} />
                                <label htmlFor="etablissementPublic" className="ml-2">Etablissement public</label>
                            </div>
                            <div className="p-field p-col-3">
                                <RadioButton inputId="etablissementPrive" name="etablissementPublic" value={false} onChange={(e) => this.typeEtablissementChange(e.value)} checked={!this.state.demande.etablissementPublic} />
                                <label htmlFor="etablissementPrive" className="ml-2">Etablissement privé</label>
                            </div> 
                        </div>
                    </div>

                    <hr/>
                    <div className="p-field p-col-12">
                        <label>Diplome obtenu sous le statut:</label>
                        <hr/>
                        <div className="p-grid">
                            <div className="p-field p-col-3">
                                <RadioButton inputId="estBoursier" name="estBoursier" value={true} onChange={(e) => this.statutEtudiantChange(e.value)} checked={this.state.demande.estBoursier} />
                                <label htmlFor="estBoursier" className="ml-2">Boursier</label>
                            </div>
                            <div className="p-field p-col-3">
                                <RadioButton inputId="nonBoursier" name="estBoursier" value={false} onChange={(e) => this.statutEtudiantChange(e.value)} checked={!this.state.demande.estBoursier} />
                                <label htmlFor="nonBoursier" className="ml-2">Non boursier</label>
                            </div> 
                        </div>
                    </div>

                    {this.state.demande.estBoursier &&
                    
                        <div className="p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="justificationBoursier">Charger un fichier justifiant le statut de boursier</label>
                                <FileUpload id="justificationBoursier" customUpload={true} uploadHandler={this.uploadJustificationBoursierHandler} auto={true} multiple={false} accept=".pdf" maxFileSize={1500000} emptyTemplate={<p className="m-0">Glisser déposer ici pour charger.</p>} chooseLabel="Choisir le document justificatif" />
                                {this.state.submitted && !this.state.demande.diplomeConcerne && this.state.demande.estBoursier && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    }

                    <hr/>
                    <div className="p-field p-col-12">
                        <label>Diplome obtenu :</label>
                        <hr/>
                        <div className="p-grid">
                            <div className="p-field p-col-3">
                                <RadioButton inputId="sousRegion" name="universiteSousRegion" value={true} onChange={(e) => this.regionChange(e.value)} checked={this.state.demande.universiteSousRegion} />
                                <label htmlFor="sousRegion" className="ml-2">Dans la sous région</label>
                            </div>
                            <div className="p-field p-col-3">
                                <RadioButton inputId="international" name="universiteSousRegion" value={false} onChange={(e) => this.regionChange(e.value)} checked={!this.state.demande.universiteSousRegion} />
                                <label htmlFor="international" className="ml-2">A l'international</label>
                            </div> 
                        </div>
                    </div>

                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="siteWebEtablissement">Adresse du site web de l'établissement</label>
                            <InputText id="siteWebEtablissement" value={this.state.demande.siteWebEtablissement}  onChange={(e) => this.onValueChange(e, 'siteWebEtablissement')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Adresse du site web de l'établissement"/>
                            {this.state.submitted && !this.state.demande.siteWebEtablissement && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="mailEtablissement">Adresse mail de l'établissement</label>
                            <InputText id="mailEtablissement" value={this.state.demande.mailEtablissement}  onChange={(e) => this.onValueChange(e, 'mailEtablissement')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Adresse mail de l'établissement"/>
                            {this.state.submitted && !this.state.demande.mailEtablissement && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="telephoneUniversite">Téléphone de l'établissement d'obtention</label>
                            <InputText id="telephoneUniversite" value={this.state.demande.telephoneUniversite}  onChange={(e) => this.onValueChange(e, 'telephoneUniversite')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Téléphone de l'établissement"/>
                            {this.state.submitted && !this.state.demande.telephoneUniversite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="diplomeConcerne">Charger le diplome concerné</label>
                            <FileUpload id="diplomeConcerne" customUpload={true} uploadHandler={this.uploadDiplomeHandler} auto={true} multiple={false} accept=".pdf" maxFileSize={1500000} emptyTemplate={<p className="m-0">Glisser déposer ici pour charger.</p>} chooseLabel="Choisir le diplome" />
                            {this.state.submitted && !this.state.demande.diplomeConcerne && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="diplomeAcces">Diplôme d'accès à la formation du diplôme </label>
                            <InputText id="diplomeAcces" value={this.state.demande.diplomeAcces}  onChange={(e) => this.onValueChange(e, 'diplomeAcces')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Diplome d'accès à la formation"/>
                            {this.state.submitted && !this.state.demande.diplomeAcces && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>
                    <hr/>

                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="nombreAnneeFormation">Nombre d'années de la formation </label>
                            <InputText id="nombreAnneeFormation" type="number" min={1} value={this.state.demande.nombreAnneeFormation}  onChange={(e) => this.onValueChange(e, 'nombreAnneeFormation')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Nombre d'année de la formation"/>
                            {this.state.submitted && !this.state.demande.nombreAnneeFormation && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>
                    <hr/>

                    <div className="p-field p-col-12">
                        <label>Type de document produit pour l'obtention du diplôme:</label>
                        <hr/>
                        <div className="p-grid">
                            <div className="p-field p-col-3">
                                <RadioButton inputId="Rapport" name="typeDocumentProduit" value="RAPPORT" onChange={(e) => this.typeDocumentChange(e.value)} checked={this.state.demande.typeDocumentProduit === "RAPPORT"} />
                                <label htmlFor="Rapport" className="ml-2">Rapport</label>
                            </div>
                            <div className="p-field p-col-3">
                                <RadioButton inputId="Memoire" name="typeDocumentProduit" value="MEMOIRE" onChange={(e) => this.typeDocumentChange(e.value)} checked={this.state.demande.typeDocumentProduit === "MEMOIRE"} />
                                <label htmlFor="Memoire" className="ml-2">Mémoire</label>
                            </div> 
                        </div>
                    </div>

                    <hr/>

                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="themeDocumentProduit">Thème du document produit </label>
                            <InputText id="themeDocumentProduit" value={this.state.demande.themeDocumentProduit}  onChange={(e) => this.onValueChange(e, 'themeDocumentProduit')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Thème du document produit"/>
                            {this.state.submitted && !this.state.demande.themeDocumentProduit && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <hr/>
                    <div className="p-field p-col-12">

                        <DataTable  ref={(el) => this.dt = el} value={this.state.demande.listeDiplomes} header="Liste des diplomes" paginator={true} rows={10}
                            globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true}>
                            <Column field="intituleDiplome" header="Intitulé diplome"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par intitulé'/>
                            <Column field="etablissementObtention" header="Etablissement d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par lieu'/>
                            <Column field="origineDiplome" header="Lieu d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par lieu'/>
                            <Column field="anneeObtention" header="Anné d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par année'/>
                            <Column body={this.visualisationButton}  header="Aperçu du diplome"  filterMatchMode="contains" sortable={false} filter={false}/>
                        </DataTable>

                        {this.state.submitted && (!this.state.demande.listeDiplomes || this.state.demande.listeDiplomes.length===0) && <small className="p-invalid" style={{color : "red"}}>Charger au moins un diplome.</small>}
                    </div>

                    <div className="p-grid">
                        <div className="p-col-4">
                            <Button label="Ajouter un diplome ou autre document" icon="pi pi-plus" onClick={this.ajouterNouveauDiplome} className='p-col-4' />
                        </div>
                    </div>

            </Dialog>

            <Dialog visible={this.state.viewDetailsDemandeDialog} header="Détails de la demande d'équivalence de diplôme" modal className="p-fluid" footer={detailsDemandeDialogFooter} onHide={this.hideDetailDemande}>
   
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="nomEtablissement">Etablissement d'obtention du diplôme</label>
                        <InputText id="nomEtablissement" readOnly autoFocus value={this.state.demande.nomEtablissement}  onChange={(e) => this.onValueChange(e, 'nomEtablissement')} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Etablissement d'obtention du diplôme"/>
                    </div>
                </div>

                <hr/>

                <div className="p-field p-col-12">
                    <label>Type d'établissement</label>
                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-3">
                            <RadioButton inputId="etablissementPublic" name="etablissementPublic" readOnly value={true} checked={this.state.demande.etablissementPublic} />
                            <label htmlFor="etablissementPublic" className="ml-2">Etablissement public</label>
                        </div>
                        <div className="p-field p-col-3">
                            <RadioButton inputId="etablissementPrive" name="etablissementPublic" readOnly value={false}  checked={!this.state.demande.etablissementPublic} />
                            <label htmlFor="etablissementPrive" className="ml-2">Etablissement privé</label>
                        </div> 
                    </div>
                </div>

                <hr/>
                <div className="p-field p-col-12">
                    <label>Diplome obtenu sous le statut:</label>
                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-3">
                            <RadioButton inputId="estBoursier" name="estBoursier" readOnly value={true}  checked={this.state.demande.estBoursier} />
                            <label htmlFor="estBoursier" className="ml-2">Boursier</label>
                        </div>
                        <div className="p-field p-col-3">
                            <RadioButton inputId="nonBoursier" name="estBoursier" readOnly value={false} checked={!this.state.demande.estBoursier} />
                            <label htmlFor="nonBoursier" className="ml-2">Non boursier</label>
                        </div> 
                    </div>
                </div>

                {this.state.demande.estBoursier &&
                    
                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="justificationBoursier">Charger un fichier justifiant le statut de boursier</label>
                            <Button label="Visualiser le justificatif du statut boursier" severity="success" rounded onClick={() => this.viewDocumentByUrl(this.state.demande.justificationBoursier)} />
                        </div>
                    </div>
                }

                <hr/>
                <div className="p-field p-col-12">
                    <label>Diplome obtenu :</label>
                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-3">
                            <RadioButton inputId="sousRegion" name="universiteSousRegion" readOnly value={true}  checked={this.state.demande.universiteSousRegion} />
                            <label htmlFor="sousRegion" className="ml-2">Dans la sous région</label>
                        </div>
                        <div className="p-field p-col-3">
                            <RadioButton inputId="international" name="universiteSousRegion" readOnly value={false}  checked={!this.state.demande.universiteSousRegion} />
                            <label htmlFor="international" className="ml-2">A l'international</label>
                        </div> 
                    </div>
                </div>

                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="siteWebEtablissement">Adresse du site web de l'établissement</label>
                        <InputText id="siteWebEtablissement" readOnly value={this.state.demande.siteWebEtablissement}  onChange={(e) => this.onValueChange(e, 'siteWebEtablissement')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Adresse du site web de l'établissement"/>
                    </div>
                </div>

                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="mailEtablissement">Adresse mail de l'établissement</label>
                        <InputText id="mailEtablissement" readOnly value={this.state.demande.mailEtablissement}  onChange={(e) => this.onValueChange(e, 'mailEtablissement')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Adresse mail de l'établissement"/>
                    </div>
                </div>

                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="telephoneUniversite">Téléphone de l'établissement d'obtention</label>
                        <InputText id="telephoneUniversite" readOnly value={this.state.demande.telephoneUniversite}  onChange={(e) => this.onValueChange(e, 'telephoneUniversite')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Téléphone de l'établissement"/>
                    </div>
                </div>

                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="diplomeConcerne">Charger le diplome concerné</label>
                        <Button label="Visualiser le diplôme" severity="success" rounded onClick={() => this.viewDocumentByUrl(this.state.demande.diplomeConcerne)} />
                        
                    </div>
                </div>

                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="diplomeAcces">Diplôme d'accès à la formation du diplôme </label>
                        <InputText id="diplomeAcces" readOnly value={this.state.demande.diplomeAcces}  onChange={(e) => this.onValueChange(e, 'diplomeAcces')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Diplome d'accès à la formation"/>
                    </div>
                </div>
                <hr/>


                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="nombreAnneeFormation">Nombre d'années de la formation </label>
                        <InputText id="nombreAnneeFormation" readOnly type="number" min={1} value={this.state.demande.nombreAnneeFormation}  onChange={(e) => this.onValueChange(e, 'nombreAnneeFormation')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Nombre d'année de la formation"/>
                    </div>
                </div>
                <hr/>

                <div className="p-field p-col-12">
                    <label>Type de document produit pour l'obtention du diplôme:</label>
                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-3">
                            <RadioButton inputId="Rapport" readOnly name="typeDocumentProduit" value="RAPPORT" checked={this.state.demande.typeDocumentProduit === "RAPPORT"} />
                            <label htmlFor="Rapport" className="ml-2">Rapport</label>
                        </div>
                        <div className="p-field p-col-3">
                            <RadioButton inputId="Memoire" readOnly name="typeDocumentProduit" value="MEMOIRE" checked={this.state.demande.typeDocumentProduit === "MEMOIRE"} />
                            <label htmlFor="Memoire" className="ml-2">Mémoire</label>
                        </div> 
                    </div>
                </div>

                <hr/>

                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="themeDocumentProduit">Thème du document produit </label>
                        <InputText id="themeDocumentProduit" readOnly value={this.state.demande.themeDocumentProduit}  onChange={(e) => this.onValueChange(e, 'themeDocumentProduit')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Thème du document produit"/>
                        
                    </div>
                </div>

                <hr/>
                <div className="p-field p-col-12">

                    <DataTable  ref={(el) => this.dt = el} value={this.state.demande.listeDiplomes} header="Liste des diplomes" paginator={true} rows={10}
                        globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true}>
                        <Column field="intituleDiplome" header="Intitulé diplome"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par intitulé'/>
                        <Column field="etablissementObtention" header="Etablissement d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par établissement'/>
                        <Column field="origineDiplome" header="Lieu d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par lieu'/>
                        <Column field="anneeObtention" header="Anné d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par année'/>
                        <Column body={this.ButtonVisualisationDocument}  header="Aperçu du diplome"  filterMatchMode="contains" sortable={false} filter={false}/>
                    </DataTable>
                    
                </div>

            </Dialog>


                <Dialog visible={this.state.ajoutDiplomeDialog} header="Formulaire d'ajout des diplômes ou documents" modal className="p-fluid" footer={ajoutDiplomeDialogFooter} onHide={this.hideAddDiplome}>
        

                <div className='p-grid'>
                        <div className="p-field p-col-12">
                            <hr/>
                                <label>Preciser le type de document :</label>

                                <div className="p-grid">
                                    <div className="p-field p-col-6">
                                        <RadioButton inputId="diplomeSimple" name="typeDocument" value="Diplome" onChange={(e) => this.typeDiplomeChange(e.value)} checked={this.state.typeDocument === "Diplome"} />
                                        <label htmlFor="diplomeSimple" className="ml-2">Diplome</label>
                                    </div>
                                    <div className="p-field p-col-6">
                                        <RadioButton inputId="document" name="typeDocument" value="Document" onChange={(e) => this.typeDiplomeChange(e.value)} checked={this.state.typeDocument === "Document"} />
                                        <label htmlFor="document" className="ml-2">Document</label>
                                    </div> 
                                </div>
                                <hr/>
                            </div>
                    </div>



                    {this.state.typeDocument === "Diplome" ? (
                        <div className="p-grid">
                            <div className="p-field p-col-8">
                                <label htmlFor="intituleDiplome"><span style={{color:'red'}}>*</span> Preciser le diplome</label>
                                <InputText id="intituleDiplome" autoFocus value={this.state.informationDiplome.intituleDiplome}  onChange={(e) => this.onDiplomeValueChange(e, 'intituleDiplome')} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Precision diplome'/>
                                {this.state.ajoutDiplomeSubmited && !this.state.informationDiplome.intituleDiplome && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>


                            <div className="p-field p-col-4">
                                <label htmlFor="anneeObtention">Preciser l'anné d'obtention</label>
                                <InputText id="anneeObtention" value={this.state.informationDiplome.anneeObtention}  onChange={(e) => this.onDiplomeValueChange(e, 'anneeObtention')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Precision l'année d'obtention"/>
                                {this.state.ajoutDiplomeSubmited && !this.state.informationDiplome.anneeObtention && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>

                            <div className="p-field p-col-8">
                                <label htmlFor="etablissementObtention">Préciser l'établissement d'obtention</label>
                                <InputText id="etablissementObtention" value={this.state.informationDiplome.etablissementObtention}  onChange={(e) => this.onDiplomeValueChange(e, 'etablissementObtention')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Lieu obtention diplôme"/>
                                {this.state.ajoutDiplomeSubmited && !this.state.informationDiplome.etablissementObtention && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>

                            <div className="p-field p-col-4">
                                <label>Etablissement situé en territoire :</label>
                                <div className="p-grid">
                                    <div className="p-field p-col-6">
                                        <RadioButton inputId="diplomeNational" name="origineDiplome" value="NATIONALE" onChange={(e) => this.origineDiplomeChange(e.value)} checked={this.state.informationDiplome.origineDiplome === "NATIONALE"} />
                                        <label htmlFor="diplomeNational" className="ml-2">NATIONALE</label>
                                    </div>
                                    <div className="p-field p-col-6">
                                        <RadioButton inputId="diplomeInternational" name="origineDiplome" value="INTERNATIONALE" onChange={(e) => this.origineDiplomeChange(e.value)} checked={this.state.informationDiplome.origineDiplome === "INTERNATIONALE"} />
                                        <label htmlFor="diplomeInternational" className="ml-2">INTERNATIONALE</label>
                                    </div> 
                                </div>
                            </div>

                            <div className="p-field p-col-12">
                                <label htmlFor="diplome"><span style={{color:'red'}}>*</span> Charger le diplome</label>
                                <FileUpload id="diplome" customUpload={true} uploadHandler={this.fileUploadHandler} clearHandler={this.clearFile} auto={true} accept=".pdf" multiple={false} maxFileSize={1500000} emptyTemplate={<p className="m-0">Glisser déposer ici pour charger.</p>} chooseLabel="Choisir le diplome" />
                                {this.state.ajoutDiplomeSubmited && !this.state.informationDiplome.scanDiplome && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                        ):
                        (
                            <div className="p-grid">
                                <div className="p-field p-col-12">
                                    <label htmlFor="intituleDiplome"><span style={{color:'red'}}>*</span> Preciser la nature du document</label>
                                    <InputText id="intituleDiplome" autoFocus value={this.state.informationDiplome.intituleDiplome}  onChange={(e) => this.onDiplomeValueChange(e, 'intituleDiplome')} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Precision diplome'/>
                                    {this.state.ajoutDiplomeSubmited && !this.state.informationDiplome.intituleDiplome && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>

                                <div className="p-field p-col-12">
                                    <label htmlFor="diplome"><span style={{color:'red'}}>*</span> Charger le diplome</label>
                                    <FileUpload id="diplome" customUpload={true} uploadHandler={this.fileUploadHandler} clearHandler={this.clearFile} auto={true} accept=".pdf" multiple={false} maxFileSize={1500000} emptyTemplate={<p className="m-0">Glisser déposer ici pour charger.</p>} chooseLabel="Choisir le diplome" />
                                    {this.state.ajoutDiplomeSubmited && !this.state.informationDiplome.scanDiplome && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>

                    )}

                </Dialog>


                <Dialog visible={this.state.viewDiplomeDialog} header="Visualisation du diplome" modal className="p-fluid" footer={diplomeDialogFooter} onHide={this.hideDiplome}>
                    <iframe title="Visualisation de diplome" width="100%" height="700" src={this.state.curentFile}></iframe>
                </Dialog>
   
                <Dialog visible={this.state.deleteDemandeDialog} header="Confirmez" modal footer={deleteDemandeDialogFooter} onHide={this.hideDeleteDemandeDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.demande && <span>Etes-vous sûr devouloir supprimer cet élément ?</span>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.viewListeDemandeDialog} header="Liste des demandes equivalence et reconnaissance de diplôme" modal className="p-fluid" footer={listeDemandeDialogFooter} onHide={this.hideListeDemande}>

                    {this.state.loadingListeDemande ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeDemande} selectionMode="single" header={"Liste des demandes equivalence et reconnaissance de diplôme"} paginator={true} rows={10}
                        globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                        <Column field="idClient" header="Identifiant"  filterMatchMode="contains" sortable={true} filter={true} />
                        <Column field="nomEtablissement" header="Etablissement diplôme"  filterMatchMode="contains" sortable={true} filter={true} />
                        <Column field="diplomeAcces" header="Diplôme d'accès"  filterMatchMode="contains" sortable={true} filter={true} />
                        <Column field="themeDocumentProduit" header="Thème du document produit"  filterMatchMode="contains" sortable={true} filter={true} />
                        <Column header={actionHeader} body={this.listeDemandActionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                    </DataTable>
                    }

                </Dialog>
                <Dialog visible={this.state.listeDemandeDialog} header="Liste des demandes pour reconnaissance et équivalence de diplôme" modal className="p-fluid" footer={listeDemandeSessionDialogFooter} onHide={this.hideListeDemandeSessionDialog}>

                    <div className="p-field p-col-12">
                    {this.state.loading ?
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.demandesSession} header="Liste des demandes pour reconnaissance et équivalence de diplôme" paginator={true} rows={10}
                            globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true}>
                            <Column field="nomPrenom" header="Prénom et nom"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par nom'/>
                            {/*<Column field="prenom" header="Prénom"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par prénom'/>
                            <Column field="sexe" header="Sexe"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par sexe'/>
                            <Column field="dateNaissance" header="Date de naissance"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par date de naissance'/>
                            <Column field="lieuNaissance" header="Lieu de naissance"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par lieu de naissance'/>
                            <Column field="telephone" header="Téléphone"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par téléphone'/>*/}
                            <Column field="idClient" header="Email"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par email'/>
                            <Column body={this.actionBodyTemplateDetailDemande} header={actionHeader} style={{textAlign:'center', width: '8em'}}  filterMatchMode="contains" sortable={false} filter={false}/>
                        </DataTable>
                    }
                    </div>

                </Dialog>
            </div> 
        )
    }
}

export default DemandeEquivalence

